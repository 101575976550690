import axios from "axios";
import { Dispatch } from "redux";
import config from "../../config";
import { PersonalTweetParams } from "./types";
import { axiosInstance, feedAxiosInstance } from "../utility";
import { logoutReset } from "../User/action";
import plausible from "src/plausible";
import { SingleKeywordColConfig } from "src/interface/keyword";
import { SocketNames } from "src/interface/PiqState";
import { filter } from "src/interface/filter";
import { ColFilterConfig } from "src/context/IndividualFeedProvider";
import { appendFilterParams } from "src/utils/utilFunction";
import { SetStateAction } from "react";

export const loadFeedData = (payload: any, reducer: string) => {
  return {
    type: "LOAD_FEED_DATA",
    payload: payload,
    reducer: reducer,
  };
};
export const loadPublicTweetsSuccess = (payload: any) => {
  return {
    type: "GET_PUBLIC_TWEETS_SUCCESS",
    payload: payload,
  };
};

export const loadPersonalTweetsSuccess = (payload: any) => {
  return {
    type: "GET_PERSONAL_TWEETS_SUCCESS",
    payload: payload,
  };
};
export const loadArchiveSuccess = (payload: any) => {
  return {
    type: "GET_ARCHIVE_SUCCESS",
    payload: payload,
  };
};
export const selectCollection = (payload: any) => {
  return {
    type: "SET_COLLECTION",
    payload: payload,
  };
};

export const setPriaDataSuccess = (payload: any, type: string) => {
  return {
    type: "SET_PRIA_DATA",
    priaType: type,
    payload: payload,
  };
};
//prtnerfeed start
export const setCapitalSuccess = (payload: any) => {
  return {
    type: "SET_CAPITAL_DATA",
    payload: payload,
  };
};

export const setForexLiveSuccess = (payload: any) => {
  return {
    type: "SET_FOREXLIVE_DATA",
    payload: payload,
  };
};

export const setEconostreamSuccess = (payload: any) => {
  return {
    type: "SET_ECONOSTREAM_DATA",
    payload: payload,
  };
};

export const setMtsinsightsSuccess = (payload: any) => {
  return {
    type: "SET_MTSINSIGHTS_DATA",
    payload: payload,
  };
};

export const setHaksterSuccess = (payload: any) => {
  return {
    type: "SET_HARKSTER_DATA",
    payload: payload,
  };
};
export const setReutersSuccess = (payload: any) => {
  return {
    type: "SET_REUTERS_DATA_SUCCESS",
    payload,
  };
};

export const setAudiomercadosSuccess = (payload: any) => {
  return {
    type: "SET_AUDIOMERCADOS_DATA_SUCCESS",
    payload,
  };
};

export const setReutersData = (payload: any) => {
  return {
    type: "SET_REUTERS_DELAY_DATA_SUCCESS",
    payload,
  };
};
//partnerfeed end
export const loadFeedlyCollectionsSuccess = (payload: any) => {
  return {
    type: "GET_COLLECTIONS_SUCCESS",
    payload: payload,
  };
};
export const loadFeedlyTweetsSuccess = (payload: any) => {
  return {
    type: "GET_FEEDLY_TWEETS_SUCCESS",
    payload: payload,
  };
};
export const loadRamblesSuccess = (payload: any) => {
  return {
    type: "GET_RAMBLES_SUCCESS",
    payload: payload,
  };
};

export const loadTwitterListsSuccess = (payload: any) => {
  return {
    type: "GET_TWITTER_LISTS_SUCCESS",
    payload: payload,
  };
};
export const loadAllColumnSuccess = (payload: any) => {
  return {
    type: "GET_ALL_NEWS_SUCCESS",
    payload: payload,
  };
};
export const loadCreditNewsSuccess = (payload: any) => {
  return {
    type: "GET_CREDIT_NEWS_SUCCESS",
    payload: payload,
  };
};
export const loadBrexitColumnSuccess = (payload: any) => {
  return {
    type: "GET_BREXIT_SUCCESS",
    payload: payload,
  };
};
export const loadMarketColumnSuccess = (payload: any) => {
  return {
    type: "GET_MARKET_SUCCESS",
    payload: payload,
  };
};
export const loadPoliticsColumnSuccess = (payload: any) => {
  return {
    type: "GET_POLITICS_SUCCESS",
    payload: payload,
  };
};

export const loadEconomicColumnSuccess = (payload: any) => {
  return {
    type: "GET_ECONOMIC_SUCCESS",
    payload: payload,
  };
};

export const loadCryptoColumnSuccess = (payload: any) => {
  return {
    type: "GET_CRYPTO_SUCCESS",
    payload,
  };
};
export const loadChinaColumnSuccess = (payload: any) => {
  return {
    type: "GET_CHINA_NEWS_SUCCESS",
    payload,
  };
};
export const loadSportsColumnSuccess = (payload: any) => {
  return {
    type: "GET_SPORTS_SUCCESS",
    payload,
  };
};
export const loadPoliticsUsSuccess = (payload: any) => {
  return {
    type: "GET_POLITICS_US_SUCCESS",
    payload,
  };
};
export const loadPoliticsUkSuccess = (payload: any) => {
  return {
    type: "GET_POLITICS_UK_SUCCESS",
    payload,
  };
};
export const setEmergingMarketSuccess = (payload: any) => {
  return {
    type: "GET_EMERGING_MARKET_SUCCESS",
    payload,
  };
};
export const loadPoliticsEuSuccess = (payload: any) => {
  return {
    type: "GET_POLITICS_EU_SUCCESS",
    payload,
  };
};
export const loadCommoditiesSuccess = (payload: any) => {
  return {
    type: "GET_COMMODITIES_SUCCESS",
    payload,
  };
};
export const loadEnergyColumnSuccess = (payload: any) => {
  return {
    type: "GET_ENERGY_SUCCESS",
    payload,
  };
};
export const loadFXNewsColumnSuccess = (payload: any) => {
  return {
    type: "GET_FX_NEWS_SUCCESS",
    payload,
  };
};
export const loadStocksNewsSuccess = (payload: any) => {
  return {
    type: "GET_STOCKS_NEWS_SUCCESS",
    payload,
  };
};
export const loadVendorNewsSuccess = (payload: any) => {
  return {
    type: "GET_VENDOR_NEWS_SUCCESS",
    payload,
  };
};

export const loadCryptoTweetSuccess = (payload: any) => {
  return {
    type: "GET_CRYPTO_TWEETS_SUCCESS",
    payload,
  };
};
export const loadGeopoliticsTweetSuccess = (payload: any) => {
  return {
    type: "GET_GEOPOLITICS_TWEETS_SUCCESS",
    payload,
  };
};
export const loadMarketTweetSuccess = (payload: any) => {
  return {
    type: "GET_MARKET_TWEETS_SUCCESS",
    payload,
  };
};
export const loadSquawksTweetSuccess = (payload: any) => {
  return {
    type: "GET_SQUAWKS_TWEETS_SUCCESS",
    payload,
  };
};
export const loadUkpoliticsTweetSuccess = (payload: any) => {
  return {
    type: "GET_UKPOLITICS_TWEETS_SUCCESS",
    payload,
  };
};
export const loadUspoliticsTweetSuccess = (payload: any) => {
  return {
    type: "GET_USPOLITICS_TWEETS_SUCCESS",
    payload,
  };
};
export const loadWeatherTweetSuccess = (payload: any) => {
  return {
    type: "GET_WEATHER_TWEETS_SUCCESS",
    payload,
  };
};

export const postRetweet = async (params: PersonalTweetParams) => {
  return axios.post(`${config.PROD_URL}/tweet/retweet`, { ...params });
};

export const postFavourites = async (params: PersonalTweetParams) => {
  await axios.post(`${config.PROD_URL}/tweet/favourites`, { ...params });
};

export const loadEconomicFeedsSuccess = (payload: any) => {
  return {
    type: "GET_ECONOMIC_FEEDS_SUCCESS",
    payload: payload,
  };
};
export const loadEconomicFeeds = () => (dispatch: any) => {
  axiosInstance.get(`/economic/feeds`).then(
    (res) => {
      dispatch(loadEconomicFeedsSuccess(res.data));
    },
    (err) => {
      console.log(err);
    }
  );
};

export const setColumnList = (payload: any) => {
  return {
    type: "SET_PRIA_COLUMN_LIST",
    payload: payload,
  };
};

export const setArticleDetials = (payload: any) => {
  return {
    type: "SET_REUTERS_DETAILS",
    payload: payload,
  };
};

export const setWindowSize = (payload: any) => {
  return {
    type: "SET_WINDOW_SIZE",
    payload: payload,
  };
};
export const setActiveDrawer = (payload: any) => {
  return {
    type: "SET_ACTIVE_DRAWER",
    payload: payload,
  };
};

export const getPartnerFeedData = (
  url: string,
  page: number,
  pageSize: number,
  search_text: string
) => {
  return feedAxiosInstance.get(`/api/${url}?search=${search_text}`, {
    params: { page: page, limit: pageSize },
  });
};
export const getGlobaleedData = (
  page: number,
  pageSize: number,
  search_text: string
) => {
  return feedAxiosInstance.get(`globalfeed?search=${search_text}`, {
    params: { page: page, limit: pageSize },
  });
};

export const handleApiError = (err: any) => {
  return (dispatch: Dispatch) => {
    if (
      err?.response?.status === 400 &&
      err?.response?.data?.error_type === "TOKEN_EXPIRED"
    ) {
      dispatch(logoutReset() as any);
    }
  };
};

export const loadPartnerFeedToColumnFromApi = (
  api: string,
  params = {},
  reducer: string,
  merge = false,
  feedData: any = {},
  isFetching: boolean = false,
  setFetching: any = null
) => {
  if (isFetching) return;

  return (dispatch: Dispatch) => {
    axiosInstance

      .get(`/${api}`, { params })
      .then((res) => {
        let data = { ...res?.data };
        if (merge) {
          data["data"] = [...feedData, ...res?.data?.data];
        }
        dispatch(loadFeedData(data, reducer));

        if (typeof setFetching === "function") {
          setFetching(false);
        }
      })
      .catch((err) => {
        if (typeof setFetching === "function") {
          setFetching(false);
        }
        dispatch(handleApiError(err) as any);
      });
  };
};

export const getFeedDetail = (slug: string, column: any, _id?: string) => {
  return (dispatch: Dispatch) => {
    feedAxiosInstance.get(`api/${column?.slug}/${slug}`).then((res: any) => {
      const item = res?.data;
      console.log(item);
      dispatch(
        setArticleDetials({
          title: item?.title,
          // article_id: item?.article_id,
          slug: item?.slug || item?.uniqueSlug,
          full_text: item?.fullContent || item?.content || item?.full_text,
          pubDate: item?.pubDate,
          canonicalUrl: item?.canonicalUrl,
          images: [],
          feed: column?.slug,
          twitterAccount: column?.twitterAccount,
          _id: item?._id ?? item?.id,
        })
      );
      setActiveDrawer("article-details-drawer");

      plausible.trackPage(`/${column?.slug}/${slug}`);
      plausible.trackEvent(`${column?.slug}`, {
        id: `/${column?.slug}/${slug}`,
      });
    });
  };
};

export const loadTwitterOptions = () => (dispatch: Dispatch) => {
  axios
    .post(
      `${config.PROD_URL}/tweet/listTimelines`,
      {
        list_id: "1254032825826803712",
      },
      {
        headers: {
          Authorization:
            "AAAAAAAAAAAAAAAAAAAAAJFnIgEAAAAAfdRuQTRGtNHTRzojlFUf3AcdJSQ%3DfO6ULY0XJ7ekGBRGAG5bYPnmkPTvRIbEmm7jn3Iery3wLJXjie",
        },
      }
    )
    .then(
      (res: any) => {
        // dispatch(loadArchiveSuccess(res.data));
        // console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
};

export const loadICDashboardColumnFromApi = (query = {}) => {
  return axiosInstance.get(`/ci`, { params: query });
};

export const postSingleColumnKeyword = (data: SingleKeywordColConfig) => {
  const formatedData = {
    ...data,
    singleWordPhraseKeywords: data.singleWordPhraseKeywords.map((v) => v.name),
  };
  return axiosInstance.post(`/colconfig`, formatedData);
};

export const getSingleColumnKeyword = (query: any = {}) => {
  return axiosInstance.get(`/colconfig?i=${query?.i}`, query);
};

export const getNewsquawkAudio = () => {
  return feedAxiosInstance.get(`/newsquawk-audio`);
};

export const fetchPartnerFeedData = (
  setReload: any,
  PAGE_LIMIT: number,
  socket: string,
  setData: any,
  setTotalPage: any,
  setPage:React.Dispatch<SetStateAction<number>>,
  clickedOnScrollOnTop: boolean = false,
  adsCoordinates: any = [],
  column_ads: any = [],
  search_text?: string,
  filterConfig?: ColFilterConfig,
) => {
  if (!clickedOnScrollOnTop) setReload(true);
  const params: {
    page: number;
    limit: number;
    and: string;
    or: string;
    not: string;
  } = { page: 1, limit: PAGE_LIMIT, and: "", or: "", not: "" };

  if (
    filterConfig &&
    filterConfig?.is_active &&
    filterConfig?.filters &&
    filterConfig?.filters?.length > 0
  ) {
    appendFilterParams({ filterConfig, params });
  }

  feedAxiosInstance
    .get(`api/${socket}?search=${search_text}`, { params })
    .then((res) => {
      let data = res?.data?.data;
      adsCoordinates.forEach((cord: any) => {
        const nextDataIndex = data.findIndex(
          (item: any) => item?._id === cord?.next_data_id
        );
        const col_ad = column_ads?.find((item: any) => {
          return item?._id === cord?.ads_id;
        });
        if (nextDataIndex !== -1) {
          data = data.splice(nextDataIndex, 0, {
            ...col_ad,
            list_item_type: "IN_FEED_ADS",
          });
        }
      });

      setData(data);
      setPage(prev => 1);
      setTotalPage(Math.ceil(res?.data?.total / PAGE_LIMIT));
    })
    .catch((err) => {
      if (err?.response?.data === "NO_DATA_FOUND") {
        setData([]);
        setTotalPage(0);
      }
    })
    .finally(() => {
      setReload(false);
    });
};
export const fetchCustomFeedData = (
  setReload: any,
  PAGE_LIMIT: number,
  socket: string,
  setData: any,
  setTotalPage: any,
  setPage:React.Dispatch<SetStateAction<number>>,
  clickedOnScrollOnTop: boolean = false,
  adsCoordinates: any = [],
  column_ads: any = [],
  search_text?: string,
  filterConfig?: ColFilterConfig
) => {
  if (!clickedOnScrollOnTop) setReload(true);
  const params: {
    page: number;
    limit: number;
    and: string;
    or: string;
    not: string;
  } = { page: 1, limit: PAGE_LIMIT, and: "", or: "", not: "" };

  if (
    filterConfig &&
    filterConfig?.is_active &&
    filterConfig?.filters &&
    filterConfig?.filters?.length > 0
  ) {
    appendFilterParams({ filterConfig, params });
  }

  feedAxiosInstance
    .get(`${socket}?search=${search_text}`, { params })
    .then((res) => {
      let data = res?.data?.data;
      adsCoordinates.forEach((cord: any) => {
        const nextDataIndex = data?.findIndex(
          (item: any) => item?._id === cord?.next_data_id
        );

        const col_ad = column_ads?.find((item: any) => {
          return item?._id === cord?.ads_id;
        });
        if (nextDataIndex !== -1) {
          data.splice(nextDataIndex, 0, {
            ...col_ad,
            list_item_type: "IN_FEED_ADS",
          });
        }
      });

      let ads_config_in_local_storage: any = JSON.parse(
        localStorage.getItem(`piq_ads_config`) || "{}"
      );
      if (ads_config_in_local_storage[socket]) {
        const local_headline = ads_config_in_local_storage?.[socket]?.last_item;
        if (data?.[0]?.title !== local_headline?.title) {
          setData(data);
        }
      } else {
        delete ads_config_in_local_storage[socket];
        localStorage.setItem(
          "piq_ads_config",
          JSON.stringify(ads_config_in_local_storage)
        );
        setData(data);
      }
      setTotalPage(Math.ceil(res?.data?.total / PAGE_LIMIT));
      setPage(prev => 1)
    })
    .catch((err) => {
      console.log("error", err);
      if (err?.response?.data === "NO_DATA_FOUND") {
        setData([]);
        setTotalPage(0);
      }
    })
    .finally(() => {
      setReload(false);
    });
};
export const fetchTwitterFeedData = (
  setReload: any,
  PAGE_LIMIT: number,
  socket: string,
  setData: any,
  setTotalPage: any,
  setPage:React.Dispatch<SetStateAction<number>>,
  clickedOnScrollOnTop: boolean = false,
  adsCoordinates: any = [],
  column_ads: any = [],
  search_text?: string,
  filterConfig?: ColFilterConfig
) => {
  if (!clickedOnScrollOnTop) setReload(true);
  const params: {
    page: number;
    limit: number;
    and: string;
    or: string;
    not: string;
  } = { page: 1, limit: PAGE_LIMIT, and: "", or: "", not: "" };

  if (
    filterConfig &&
    filterConfig?.is_active &&
    filterConfig?.filters &&
    filterConfig?.filters?.length > 0
  ) {
    appendFilterParams({ filterConfig, params });
  }

  feedAxiosInstance
    .get(`/${socket}?search=${search_text}`, { params })
    .then((res) => {
      let data = res?.data?.data;
      adsCoordinates.forEach((cord: any) => {
        const nextDataIndex = data.findIndex(
          (item: any) => item?._id === cord?.next_data_id
        );
        const col_ad = column_ads?.find((item: any) => {
          return item?._id === cord?.ads_id;
        });
        if (nextDataIndex !== -1) {
          data = data.splice(nextDataIndex, 0, {
            ...col_ad,
            list_item_type: "IN_FEED_ADS",
          });
        }
      });
      setData(data);
      setTotalPage(Math.ceil(res?.data?.total / PAGE_LIMIT));
      setPage(prev => 1);
    })
    .catch((err) => {
      if (err?.response?.data === "NO_DATA_FOUND") {
        setData([]);
        setTotalPage(0);
      }
    })
    .finally(() => {
      setReload(false);
    });
};
export const onLoadSocketData = async (
  newData: any,
  dataColRef: any,
  colConfig: any,
  checkIfDivHasHighlight: any,
  context: any,
  play: any,
  olddata: any,
  PAGE_LIMIT: number,
  socket: SocketNames,
  setData: any,
  isTwitterList: boolean = false,
  setIsSocketLoading: any
) => {
  if (!newData) return;
  if (!dataColRef || !newData) return;
  if (!dataColRef || !olddata) return;
  if (colConfig?.audioAlert) {
    for (let i = 0; i < newData?.data?.length; i++) {
      const hasHighlights = checkIfDivHasHighlight(
        newData?.data[i]?.title,
        colConfig
      );
      if (hasHighlights && context?.isSignedIn) {
        play();
      }
    }
  }

  let sliced_data = sliceAndSortData(
    newData,
    olddata,
    PAGE_LIMIT,
    socket,
    isTwitterList
  );

  // let ads_config_in_local_storage=JSON.parse(localStorage.getItem("piq_ads_config")||"")

  // if( ads_config_in_local_storage){
  //   const ads_local_config={
  //     ads_count:0,
  //     last_feed_ads:sliced_data[0]
  // }
  // }else{
  //   const ads_local_config={
  //     ads_count:0,
  //     last_feed_ads:sliced_data[0]
  // }
  //   ads_config_in_local_storage[socket]=ads_local_config

  // }

  setData(sliced_data);

  setIsSocketLoading(false);
};

const sliceAndSortData = (
  newData: any,
  data: any,
  PAGE_LIMIT: number,
  socket: SocketNames,
  isTwitterList: boolean = false
) => {
  // console.log(data);
  let slicedData = [...newData?.data, ...data].slice(0, PAGE_LIMIT);

  // // console.log(slicedData);

  // // if (socket === "newsquawk" || socket === "newsquawk-delay") {
  // if (!isTwitterList) {
  //   slicedData = slicedData.reduce((first: any[], second: any) => {
  //     if (!first.find((item: any) => item.title === second.title)) {
  //       //uniqueSlug
  //       first.push(second);
  //     }
  //     return first;
  //   }, []);
  // }

  slicedData = slicedData.sort(function (a: any, b: any) {
    const dateA: any = new Date(isTwitterList ? a?.createdAt : a?.pubDate);
    const dateB: any = new Date(isTwitterList ? b?.createdAt : b?.pubDate);
    return dateB - dateA;
  });

  return slicedData;
};

export const fetchFeeddata = (socket: SocketNames) => {
  const params = { page: 1, limit: 50 };
  const data = feedAxiosInstance
    .get(`/api/${socket}?search=""`, { params })
    .then((res) => {
      let data = res?.data?.data;
    });
  return data;
};
