import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParams = () => {
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  const setQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const deleteQueryParam = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const getParamFromKey = (key: string) => {
    return searchParams.get(key);
  };

  const resetQueryParams = () => {
    navigate(location.pathname, { replace: true });
  };

  return {
    getQueryParams,
    setQueryParams,
    deleteQueryParam,
    resetQueryParams,
    getParamFromKey,
  };
}
