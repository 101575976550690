import React, { lazy, useEffect, useState, useCallback, Suspense } from "react";
import { useSelector } from "react-redux";
import { Outlet, Route, useNavigate } from "react-router-dom";
import { useUser } from "src/Hooks/useUser";
import { pushAdsToBody } from "src/utils/common";
import PagesRoute from "./PagesRoute";
import { LoaderComponent } from "react-fullscreen-loader";
import HeaderNav from "../HeaderNav/HeaderNav";
import { useAuthContext } from "src/context/AuthProvider";
import FeedSharingModal from "../FeedSharing/FeedSharingModal";
import { useLayoutContext } from "src/context/LayoutProvider";

const lazyComponents = {
  SignIn: lazy(() => import("src/development/Component/Drawers/Auth")),
  LogoutModal: lazy(
    () => import("src/development/Container/Web/Components/SideBar/LogoutModal")
  ),
  PopupAds: lazy(() => import("src/development/Component/PopupAds")),
  AddCardDialog: lazy(() => import("src/development/Component/AppSelector")),
  LimitExceedModal: lazy(() => import("src/development/Component/modal/LimitExceedModal/LimitExceedModal")),
  SideBar: lazy(() => import("src/development/Container/Web/Components/SideBar")),
  UserProfile: lazy(() => import("src/development/Component/Drawers/UserProfile/UserProfile")),
  Settings: lazy(() => import("src/development/Component/Drawers/Setting")),
  TypeForm: lazy(() => import("src/development/Component/Drawers/TypeForm")),
  Payment: lazy(() => import("src/development/Component/StripePayment/Payment")),
  AddFeatures: lazy(() => import("src/development/Component/Drawers/AddFeatures")),
  ArticleDrawr: lazy(() => import("src/development/Component/Drawers/Articles/index")),
  PremiumAppCheckout: lazy(() => import("src/development/Component/AppSelector/PremiumAppCheckout")),
  PricingModal : lazy(() => import("src/development/Component/modal/PricingModal/PricingModal"))
};

const TCComponent = lazy(() => import("../pages/TC"));
const PPComponent = lazy(() => import("../pages/PP"));
const CCPA = lazy(() => import("../pages/ccpa"));
const YesCookies = lazy(() => import("../pages/yesCookies"));
const WebDashboard = lazy(
  () => import("src/development/Container/Web/Dashboard")
);

const AppLayout: React.FC = (children) => {
  const navigate = useNavigate();
  const { SHOW_ADS } = useUser();
  const [layoutIsEmpty, setLayoutIsEmpty] = useState<boolean>(true);
  const { darkMode, windowSize, dashboardloading } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
    windowSize: state?.PIQReducer?.windowSize,
    dashboardloading: state?.loading?.dashboardloading,
  }));
  const { piqSuiteLayout, activeDrawerState } = useLayoutContext();

  const updateLayoutEmpty = useCallback(() => {
    if (piqSuiteLayout?.length) {
      setLayoutIsEmpty(false);
    } else {
      setLayoutIsEmpty(true);
      const time = setTimeout(() => setLayoutIsEmpty(false), 1000);
      return () => clearTimeout(time);
    }
  }, [piqSuiteLayout?.length]);

  const updateDocumentTitle = useCallback(() => {
    const titles: { [key: string]: string } = {
      "app-selector-drawer": "PiQ | App Selector",
      "article-list-drawer": "PiQ | Articles",
      "account-settings-drawer": "PiQ | Account Settings",
      "global-settings-drawer": "PiQ | Global Settings",
      "upgrade-plan": "PiQ | Payment",
      "type-form": "Form",
    };
    document.title = titles[activeDrawerState] || "PiQSuite.Com";
    // if (activeDrawerState in titles) navigate("/");
  }, [activeDrawerState, navigate]);

  useEffect(updateLayoutEmpty, [updateLayoutEmpty]);
  useEffect(updateDocumentTitle, [updateDocumentTitle]);

  useEffect(() => {
    if (windowSize?.[0]) pushAdsToBody(windowSize[0]);
  }, [windowSize]);

  useEffect(() => {
    if (!SHOW_ADS) {
      const existingDev = document.getElementById("ic_d_div_OOP_1");
      if (existingDev) existingDev.remove();
    }
  }, [SHOW_ADS]);

  const isMobile = windowSize?.[0] <= 700;

  return (
    <>
      <HeaderNav />
      <LoaderComponent loading={dashboardloading || layoutIsEmpty} />
      <Suspense fallback={<div>Loading...</div>}>
        <lazyComponents.ArticleDrawr />
        <lazyComponents.Settings />
        <lazyComponents.TypeForm />
        <lazyComponents.Payment />
        <FeedSharingModal />
        {!isMobile && <lazyComponents.PopupAds />}
        <lazyComponents.PremiumAppCheckout />
        <lazyComponents.UserProfile />
        <lazyComponents.SignIn />
        <lazyComponents.LogoutModal />
        <lazyComponents.AddCardDialog />
        <lazyComponents.LimitExceedModal />
        <lazyComponents.AddFeatures />
        <lazyComponents.PricingModal />
      </Suspense>
      <div className={`v2-authenticated-page ${darkMode ? "dark-mode" : ""}`}>
        {!isMobile && <Suspense fallback={<div>Loading...</div>}><lazyComponents.SideBar /></Suspense>}
        <div className={`main-panel overflow-y-auto ${darkMode ? "dark-mode" : ""}`} style={{ height: "100%" }}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AppLayout;
