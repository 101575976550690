import { message } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  defaultFilter,
  firstDefaultFilter,
  tierPrivilege,
} from "src/constants/filter";
import { trackEvents } from "src/constants/tracker";
import { useAuthContext } from "src/context/AuthProvider";
import { useLayoutContext } from "src/context/LayoutProvider";
import { useTrackerContext } from "src/context/TrackerProvider";
import { filter, useFilterProps } from "src/interface/filter";

const useFilter = ({
  filters,
  sendFilterConfig,
  data,
  messageApi,
  socket,
}: useFilterProps) => {
  const { isSignedIn } = useAuthContext();
  const subscriptionData = useSelector((state: any) => state.subscription);
  const { sendEvent } = useTrackerContext();
  const navigate = useNavigate();

  const { setActiveDrawer } = useLayoutContext();
  const subscriptionTier = useMemo(
    () =>
      !isSignedIn ? "low" : subscriptionData?.data?.active ? "high" : "mid",
    [subscriptionData, isSignedIn]
  );

  const notAllowed = () => {
    if (filters?.length < tierPrivilege[subscriptionTier]) return false;
    return true;
  };

  const addFilterHandler = async () => {
    if ([...filters]?.pop()?.keyword == "") return;

    if (notAllowed()) {
      messageApi.open({
        type: "error",
        content:
          subscriptionTier === "low"
            ? "Total filter limit reached for this feed. Please login for more"
            : subscriptionTier === "mid"
            ? "Total filter limit reached for this feed. Please upgrade for more."
            : "Total filters limit reached for this feed",
      });
      if (subscriptionTier === "low") {
        navigate("?authModal=login&step=1&internalStep=1");
        message.warning("You need to login to add more filters.");
      }
      return;
    }
    const newFilters = [
      ...filters,
      filters?.length === 0 ? firstDefaultFilter : defaultFilter,
    ];
    const newData = {
      ...data,
      filters: newFilters,
    };

    try {
      await sendFilterConfig(newData, false);
    } catch (err) {
      console.log(err);
    }
  };

  const changeHandler = async (
    name: string,
    indexToChange: number,
    value: string
  ) => {
    if (value === "") return;

    const newFilters = filters?.map((item: filter, idx: number) =>
      idx === indexToChange
        ? {
            ...item,
            [name]: value,
          }
        : item
    );

    const newData = {
      ...data,
      filters: newFilters,
    };
    try {
      await sendFilterConfig(newData);
    } catch (err) {
      console.log(err);
    }
  };

  const removeFilterHandler = async (indexToRemove: number) => {
    const newFilters = filters
      ?.filter((it: filter, idx: number) => idx !== indexToRemove)
      .map((it: filter, idx: number) => {
        if (idx !== 0) return it;

        if (it?.boolean === "AND" || it?.boolean === "OR") it.boolean = "ONLY";

        return it;
      });
    const newData = {
      ...data,
      filters: newFilters,
    };

    try {
      await sendFilterConfig(newData);
    } catch (err) {
      console.log(err);
    }
  };

  const clearFilterHandler = async () => {
    const newData = {
      ...data,
      filters: [],
    };

    try {
      await sendFilterConfig(newData);
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilterHandler = async (applied: boolean) => {
    const newData = {
      ...data,
      is_active: applied,
    };
    sendEvent({
      eventName: trackEvents.FEED_FILTER_APPLY,
      payload: {
        status: applied,
        feed_name: socket,
      },
    });
    try {
      await sendFilterConfig(newData);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    addFilterHandler,
    changeHandler,
    removeFilterHandler,
    clearFilterHandler,
    applyFilterHandler,
  };
};

export default useFilter;
