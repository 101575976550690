import React from "react";
import { PartnerFeedComponent } from "src/development/Container/Web/Components/Feeds/PartnerFeeds";
import { TwitterListComponent } from "src/development/Container/Web/Components/Feeds/TwitterListColumn";
import { TradingViewComponent } from "src/development/Container/Web/Components/Feeds/TradingView/TradingView";
import { VideoPlayer } from "src/development/Container/Web/Components/Feeds/VideoPlayer";
import { SocketNames } from "src/interface/PiqState";
import { InvestingEconomicCalenderComponent } from "src/development/Container/Web/Components/Feeds/InvestingEconomicCalender";
import { CompleteIntelComponent } from "src/development/Container/Web/Components/Feeds/CompleteIntel";
import { TelegramComponent } from "src/development/Container/Web/Components/Feeds/Telegram";
import { IndividualFeedProvider } from "src/context/IndividualFeedProvider";
import Podcast from "src/development/Container/Web/Components/Feeds/Podcast";
import { CustomFeedComponent } from "src/development/Container/Web/Components/Feeds/CustomFeedComponent";
import { CmeGroupApp } from "src/development/Container/Web/Components/Feeds/CmeGroupApp/CmeGroupApp";
import { customFeedsSockets } from "./columnConfiguration";
import PolymarketWidget from "src/development/Container/Web/Components/Feeds/PolymarketWidget";
import CryptoCompareCryptoNews from "src/development/Container/Web/Components/Feeds/CryptoCompareCryptoNews/CryptoCompareCryptoNews";
import CryptoCompareAdvancedChart from "src/development/Container/Web/Components/Feeds/CryptoCompareAdvancedChart/CryptoCompareAdvancedChart";
import CryptoComparePrices from "src/development/Container/Web/Components/Feeds/CryptoComparePrices/CryptoComparePrices";
import TradingViewGeneric from "src/development/Container/Web/Components/Feeds/TradingViewGeneric/TradingViewGeneric";
import MyFxBookCalendar from "src/development/Container/Web/Components/Feeds/MyFxBookCalendar/MyFxBookCalendar";
import FinancialJuiceGeneric from "src/development/Container/Web/Components/Feeds/FinancialJuiceEconomicCalendar/FinancialJuiceGeneric";
import { PiQCustomFeedComponent } from "src/development/Container/Web/Components/Feeds/PiQCustomFeedComponent";

const getChartComponet = (socket: SocketNames) => {
  switch (socket) {
    case "tradingview":
      return <TradingViewComponent socket={socket} />;
    case "investing-economic-calender":
      return <InvestingEconomicCalenderComponent socket={socket} />;
    case "complete-intel":
      return <CompleteIntelComponent socket={socket} />;

    case "marketoverview":
    case "stockscreener":
    case "forexcrossrates":
    case "economiccalendarwidget":
    case "marketdatawidget":
    case "stockheatmap":
    case "tickertaperegular":
    case "forexheatmap":
    case "news":
    case "singleticker":
    case "yeartodatestockheatmap":
    case "watchlist":
    case "tickertapecompact":
      return <TradingViewGeneric socket={socket} />;

    case "cryptocomparecryptonews":
      return <CryptoCompareCryptoNews socket={socket} />;
    case "cryptocompareadvancedchart":
      return <CryptoCompareAdvancedChart socket={socket} />;
    case "cryptocompareprices":
      return <CryptoComparePrices socket={socket} />;

    case "fmoccut50bps":
    case "fmoccut75bps":
    case "fmoccut25bps":
    case "fmocunchanged":
    case "fmochike25bps":
      return <PolymarketWidget socket={socket} />;

    case "financialjuiceeconomiccalendar":
    case "financialjuiceheadlines":
    case "financialjuicetickstrikesquawk":
      return (
        <FinancialJuiceGeneric
          socket={socket}
          height={socket === "financialjuicetickstrikesquawk" ? "97%" : "100%"}
        />
      );

    case "myfxbookcalendar":
      return <MyFxBookCalendar socket={socket} />;
  }
};

const getAppComponet = (socket: SocketNames) => {
  switch (socket) {
    case "telegram":
      return <TelegramComponent socket={socket} />;

    case "cmegroup-app":
      return <CmeGroupApp socket={socket} />;
  }
};

export const getWebFeedComponent = (colString: SocketNames, c: any) => {
  if (customFeedsSockets.includes(colString)) {
    return {
      socket: colString,
      component: (
        <IndividualFeedProvider feedId={colString} socket={colString}>
          <CustomFeedComponent
            socket={colString}
            feedData={c || { slug: colString }}
          />
        </IndividualFeedProvider>
      ),
    };
  }

  if (
    c?.componentType === "NEWS_FEED_COMPONENT" ||
    c?.componentType === "PREMIUM_FEED_COMPONENT"
  ) {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id} socket={c?.slug}>
          <PartnerFeedComponent socket={c?.slug} />
        </IndividualFeedProvider>
      ),
    };
  }

  if (c?.componentType === "TWITTER_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id} socket={c?.slug}>
          <TwitterListComponent socket={c.slug} />
        </IndividualFeedProvider>
      ),
    };
  }
  if (c?.componentType === "CUSTOM_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id} socket={c?.slug}>
          <CustomFeedComponent socket={c.slug} feedData={c} />
        </IndividualFeedProvider>
      ),
    };
  }
  if (c?.componentType === "PIQ_CUSTOM_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id} socket={c?.slug}>
          <PiQCustomFeedComponent socket={c.slug} feedData={c} />
        </IndividualFeedProvider>
      ),
    };
  }
  if (c?.componentType === "SOCIAL_APP_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getAppComponet(c.slug),
    };
  }

  if (c?.componentType === "CHART_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getChartComponet(c.slug),
    };
  }
  if (c?.componentType === "APP_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getAppComponet(c.slug),
    };
  }
  if (c?.componentType === "PIQ_PODCAST_COMPONENT") {
    return {
      socket: c.slug,
      component: <Podcast socket={c.slug} />,
    };
  }

  if (c?.componentType === "TUTORIAL_COMPONENT") {
    return {
      socket: c.slug,
      component: getAppComponet(c.slug),
    };
  }

  if (c?.componentType === "VIDEO_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: <VideoPlayer socket={c.slug} />,
    };
  } else {
    return {
      socket: colString,
      component: <VideoPlayer socket={colString} />,
    };
  }
};
