import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { feedAxiosInstance } from "../utility";

export interface RssFeedState {
  error: string;
  loading: boolean;
  rssFeed: any[];
  searchResults: any[];
  page: number;
  limit: number;
  total: number;
  hasMore: boolean;
  categories: any[];
  activeCategoryId: string | null;
  filters: any[];
}

const initialState: RssFeedState = {
  error: "",
  loading: false,
  rssFeed: [],
  searchResults: [],
  page: 1,
  limit: 50,
  total: 0,
  hasMore: true,
  categories: [],
  activeCategoryId: null,
  filters: [],
};

export const fetchRssFeed = createAsyncThunk(
  "rssFeed",
  async (_, { getState }) => {
    const state = getState() as { rssFeed: RssFeedState };
    let categories = state.rssFeed.categories;
    let activeCategoryId = state.rssFeed.activeCategoryId;

    const categoryRes = await feedAxiosInstance.get(
      `piq-columns/w/category?appSelectorTypes=ALL_FEED`
    );
    categories = categoryRes.data;

    if (!activeCategoryId) {
      activeCategoryId = categoryRes.data[0]._id;
    }

    const res = await feedAxiosInstance.get(
      `piq-columns/w?appSelectorTypes=ALL_FEED&page=1&limit=50&categoryId=${activeCategoryId}`
    );
    return {
      ...res.data,
      categories,
      activeCategoryId,
    };
  }
);

export const fetchNextRssFeed = createAsyncThunk(
  "rssFeed/next",
  async (_, { getState }) => {
    const state = getState() as { rssFeed: RssFeedState };
    const { page, limit, hasMore, activeCategoryId } = state.rssFeed;

    if (!hasMore) return null;

    const nextPage = page + 1;
    const res = await feedAxiosInstance.get(
      `piq-columns/w?appSelectorTypes=ALL_FEED&page=${nextPage}&limit=${limit}&categoryId=${activeCategoryId}`
    );
    return res.data;
  }
);

export const searchRssFeed = createAsyncThunk(
  "rssFeed/search",
  async (
    { query, filters }: { query: string; filters: any[] },
    { getState }
  ) => {
    const state = getState() as { rssFeed: RssFeedState };
    const activeCategoryId = state.rssFeed.activeCategoryId;

    const filterValues = filters.map((f) => f.value).join(",");
    const encodedFilters = encodeURIComponent(filterValues);
    const encodedQuery = encodeURIComponent(query);

    // Build URL with filters and always include active category
    const url = `piq-columns/w/search?appSelectorTypes=ALL_FEED${
      query ? `&query=${encodedQuery}` : ""
    }&filters=${encodedFilters}&categoryId=${activeCategoryId}`;

    const data = await feedAxiosInstance.get(url);
    return {
      data,
      query,
      filters,
    };
  }
);

export const rssFeedSlice = createSlice({
  name: "rssFeed",
  initialState,
  reducers: {
    setActiveCategory: (state, action) => {
      state.activeCategoryId = action.payload;
      state.page = 1;
      state.limit = 50;
      state.total = 0;
      state.rssFeed = [];
      state.hasMore = true;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    clearFilters: (state) => {
      state.filters = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRssFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRssFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.rssFeed = action.payload.data;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.total = action.payload.total;
      state.hasMore = state.rssFeed.length < action.payload.total;
      // state.searchQuery = "";
      state.categories = action.payload.categories;
      state.activeCategoryId = action.payload.activeCategoryId;
    });
    builder.addCase(fetchRssFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message ?? "";
      state.rssFeed = [];
      state.hasMore = false;
      state.page = 1;
      state.limit = 50;
      state.total = 0;
    });
    builder.addCase(searchRssFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchRssFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.searchResults = action.payload.data.data;
    });
    builder.addCase(searchRssFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message ?? "";
      state.searchResults = [];
    });
    builder.addCase(fetchNextRssFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNextRssFeed.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.loading = false;
      state.error = "";
      state.rssFeed = [...state.rssFeed, ...action.payload.data];
      state.page = action.payload.page;
      state.hasMore = state.rssFeed.length < action.payload.total;
    });
    builder.addCase(fetchNextRssFeed.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message ?? "";
      state.hasMore = false;
    });
  },
});

export const { setActiveCategory, setFilters, clearFilters } =
  rssFeedSlice.actions;
export default rssFeedSlice.reducer;
