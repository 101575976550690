import React, { useState } from "react";
import "./index.scss";
import TermsDialog from "../../Drawers/TermsDialog";
import PrivacyDialog from "../../PrivacyDialog";
import DMCADialog from "src/development/Container/Web/Components/DMCAPolicy";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";

export function TermsCondition() {
  const [showTermsDialog, setShowTermsDialog] = useState(false);
  const [showPrivacyPolicyDialog, setShowPrivacyPolicyDialog] = useState(false);
  const [showDMCADialog, setShowDMCADialog] = useState(false);

  const { sendEvent } = useTrackerContext();
  return (
    <>
      <div className="auth-modal-terms-condition">
        <a
          href="https://piqsuite.com/terms-and-condition"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            sendEvent({
              eventName: trackEvents.TERMS_AND_CONDITION_LINK,
              payload: {
                page: "login",
              },
            });
          }}
        >
          T&C's
        </a>
        <span>|</span>
        <a
          href="https://piqsuite.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            sendEvent({
              eventName: trackEvents.PRIVACY_POLICY_LINK,
              payload: {
                page: "login",
              },
            });
          }}
        >
          Privacy Policy
        </a>
        <span>|</span>
        <a
          href="https://www.piqsuite.com/dmca-policy"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            sendEvent({
              eventName: trackEvents.DMCA_LINK,
              payload: {
                page: "login",
              },
            });
          }}
        >
          DMCA
        </a>
      </div>
      <TermsDialog
        openTerms={showTermsDialog}
        setOpenTerms={() => setShowTermsDialog(false)}
      />
      <PrivacyDialog
        openPrivacyPolicy={showPrivacyPolicyDialog}
        setOpenPrivacyPolicy={() => setShowPrivacyPolicyDialog(false)}
      />
      <DMCADialog
        openDMCAPolicy={showDMCADialog}
        setOpenDMCAPolicy={() => setShowDMCADialog(false)}
      />
    </>
  );
}
