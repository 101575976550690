/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//   "aws_project_region": ,
//   "aws_cognito_region": "us-east-1",
//   "aws_user_pools_id": "us-east-1_vkOWuAmkw",
//   "aws_user_pools_web_client_id": "3ect8nm52r1l6fh1nv0s4bdshu",
//   "oauth": {
//       "domain": "hugh.auth.us-east-1.amazoncognito.com",
//       "scope": [
//           "aws.cognito.signin.user.admin",
//           "email",
//           "openid",
//           "phone",
//           "profile"
//       ],
//       "redirectSignIn": "http://localhost:3000/",
//       "redirectSignOut": "http://localhost:3000/",
//       "responseType": "code"
//   },
//   "federationTarget": "COGNITO_USER_POOLS",
//   "aws_cognito_username_attributes": [],
//   "aws_cognito_social_providers": [
//       "GOOGLE"
//   ],
//   "aws_cognito_signup_attributes": [
//       "EMAIL"
//   ],
//   "aws_cognito_mfa_configuration": "OFF",
//   "aws_cognito_mfa_types": [],
//   "aws_cognito_password_protection_settings": {
//       "passwordPolicyMinLength": 8,
//       "passwordPolicyCharacters": [
//           "REQUIRES_LOWERCASE",
//           "REQUIRES_UPPERCASE",
//           "REQUIRES_NUMBERS",
//           "REQUIRES_SYMBOLS"
//       ]
//   },
//   "aws_cognito_verification_mechanisms": [
//       "EMAIL"
//   ]
// };

// export default awsmobile;

// const awsmobile = {
//   Auth: {
//     region: "us-east-1",
//     userPoolId: "us-east-1_pS4QolMOn",
//     userPoolWebClientId: "eo9lbpjisnim7l99ds8dov6nl",
//   },
//   aws_project_region: "us-east-1",
//   aws_cognito_region: "us-east-1",
//   aws_user_pools_id: "us-east-1_pS4QolMOn",
//   aws_user_pools_web_client_id: "eo9lbpjisnim7l99ds8dov6nl",
//   oauth: {
//     domain: "piq-prod.auth.us-east-1.amazoncognito.com",
//     scope: [
//       "phone",
//       "aws.cognito.signin.user.admin",
//       "email",
//       "openid",
//       "profile",
//     ],
//     redirectSignIn: "https://89d1697de324e4d41ea4b33ffea165ef.piqsuite.com/",
//     redirectSignOut: "https://89d1697de324e4d41ea4b33ffea165ef.piqsuite.com/",
//     responseType: "code",
//   },
//   federationTarget: "COGNITO_USER_POOLS",
//   aws_cognito_username_attributes: [],
//   aws_cognito_social_providers: ["GOOGLE"],
//   aws_cognito_signup_attributes: ["EMAIL"],
//   aws_cognito_mfa_configuration: "OFF",
//   aws_cognito_mfa_types: [],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 8,
//     passwordPolicyCharacters: [
//       "REQUIRES_LOWERCASE",
//       "REQUIRES_UPPERCASE",
//       "REQUIRES_NUMBERS",
//       "REQUIRES_SYMBOLS",
//     ],
//   },
//   aws_cognito_verification_mechanisms: ["EMAIL"],
// };

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_pS4QolMOn",
  aws_user_pools_web_client_id: "eo9lbpjisnim7l99ds8dov6nl",
  oauth: {
    domain: "piq-prod.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://89d1697de324e4d41ea4b33ffea165ef.piqsuite.com/",
    redirectSignOut: "https://89d1697de324e4d41ea4b33ffea165ef.piqsuite.com/",
    responseType: "code",
  },
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
