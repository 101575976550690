import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import TextInput from "../../TextInput/TextInput";
import { UseFormReturn } from "react-hook-form";
import { TLoginSchema } from "src/schema/auth/auth.schema";
import Button from "../../Button/Button";
import { useStepper } from "src/context/StepperProvider";
import { EMAIL_REGEX } from "src/constants/auth";
import { axiosInstance } from "src/store/utility";
import axios from "axios";
import { TermsConditionPrivacyPolicy } from "../Terms&Conditions";
import BottomAction from "../BottomAction/BottomAction";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import BackButton from "../BackButton";
import { AxiosRequestConfig } from "axios";
import { message } from "antd";
import { Auth } from "aws-amplify";

interface LoginFormProps {
  form: UseFormReturn<TLoginSchema>;
  showPasswordFields?: boolean;
  onSubmit: any;
  emailError?: string;
  promoCode: string;
  setPromoCode: (value: string) => void;
  promoCodeError: string;
  setPromoCodeError: (value: string) => void;
}

const LoginForm = ({
  form,
  showPasswordFields = false,
  onSubmit,
  emailError = "",
  promoCode,
  setPromoCode,
  promoCodeError,
  setPromoCodeError,
}: LoginFormProps) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const { setShowStepper } = useStepper();
  const [hasEmailValidated, setEmailValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const email = watch("email")?.toLowerCase();
  const isEmailValid = email?.match(EMAIL_REGEX);
  const isPasswordValid = watch("password")?.length! > 0;

  const { sendEvent } = useTrackerContext();

  const disabled = showPasswordFields
    ? !isEmailValid || !isPasswordValid || !hasEmailValidated
    : !isEmailValid || !hasEmailValidated;

  const handleGoogleSignin = async () => {
    try {
      // add to local storage to redirect to checkoutpage after login success
      const withCheckout = searchParams.get("with") === "checkout";
      localStorage.setItem("googleAuthTrack", "signin");

      if (withCheckout) {
        const subscriptionType = searchParams.get("subscriptionType");
        localStorage.setItem("googleAuth", "signin");
        localStorage.setItem("subscriptionType", subscriptionType!);
      }
      await Auth.federatedSignIn({
        provider: "Google" as any,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = async (emailToValidate: string) => {
    setLoading(true);
    setEmailValidated(false);
    try {
      await axiosInstance.post("/auth/check-email-verification", {
        email: emailToValidate,
      });
      setError("");
      setEmailValidated(true);
    } catch (err) {
      const isAxiosError = axios.isAxiosError(err);

      if (isAxiosError) {
        const status = err.response?.status;
        switch (status) {
          case 404:
            setError("signup");
            break;
          case 403:
            setError("");
            setEmailValidated(true);
            break;
          default:
            setError("An unexpected error occurred. Please try again.");
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputTracking = (e: any) => {
    if (e?.target?.value === "") return;

    sendEvent({
      eventName: trackEvents.INPUT_FIELD_COMPLETE,
      payload: {
        fieldName: e?.target?.name,
        type: "login",
      },
    });
  };

  const debouncedValidateEmail = useCallback(
    debounce((email: string) => validateEmail(email), 300),
    []
  );

  useEffect(() => {
    if (!email) {
      setError("");
      setLoading(false);
      setEmailValidated(false);
      return;
    }

    if (isEmailValid) {
      debouncedValidateEmail(email);
    }

    return () => {
      debouncedValidateEmail.cancel();
    };
  }, [email, debouncedValidateEmail]);

  const validatePromoCode = async (promoCode: string) => {
    if (!promoCode) {
      setPromoCodeError("");
      return;
    }

    if ((window as any).promoCodeController) {
      (window as any).promoCodeController.abort();
    }

    const companyLicense = JSON.parse(
      localStorage.getItem("companyLicense") || "{}"
    );
    const customUrl = companyLicense?.company || "";

    (window as any).promoCodeController = new AbortController();

    try {
      const config = {
        signal: (window as any).promoCodeController.signal,
      } as AxiosRequestConfig;

      const response = await axiosInstance.get(
        `/enterprise-license/validate/${promoCode}/${customUrl}`,
        config
      );

      if (response.data.success) {
        message.success(response.data.message);
        setPromoCodeError("");
      }
    } catch (error: any) {
      if (error.name === "AbortError") return;
      setPromoCodeError(
        error.response?.data?.message || "Error validating promo code"
      );
    }
  };

  const debouncedValidatePromoCode = useCallback(
    debounce(validatePromoCode, 500),
    []
  );

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPromoCode(value);

    if (!value) {
      setPromoCodeError("");
      return;
    } else {
      debouncedValidatePromoCode(value);
    }
  };

  const companyLicense = JSON.parse(
    localStorage.getItem("companyLicense") || "{}"
  );
  const showPromoCode = companyLicense?.valid;

  // Add cleanup effect
  // useEffect(() => {
  //   return () => {
  //     form.reset();
  //     setEmailValidated(false);
  //     setError("");
  //     setLoading(false);
  //   };
  // }, []);

  // Add effect to reset form when location changes
  useEffect(() => {
    // form.reset();
    setEmailValidated(false);
    setError("");
    setLoading(false);
    localStorage.removeItem("loginEmail");
  }, [location.pathname]);

  return (
    <>
      <h1 className="login-page-title">Sign In</h1>
      {/* {!showPromoCode && (
        <>
          <button className="sign-up-with-google-btn" onClick={handleGoogleSignin}>
            <img src="/auth/google-logo.svg" alt="Google Logo" />
            Sign In with Google
          </button>

          <div className="or" style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            Or
            <div className="line" />
          </div>

          <h1 className="login-page-subtitle">Sign In with Email</h1>
        </>
      )} */}
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <TextInput
          disabled={showPasswordFields}
          placeholder="example@gmail.com"
          padding="11px 16px"
          radius="md"
          onFocus={() => setShowStepper(true)}
          error={errors?.email?.message || emailError || error}
          iconString={
            hasEmailValidated
              ? "tabler:circle-check-filled"
              : "uis:times-circle"
          }
          showIcon={Boolean(isEmailValid)}
          {...register("email")}
          loading={loading}
          handleError={() => form.setValue("email", "")}
          onBlur={handleInputTracking}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && !disabled) {
              handleInputTracking(e);
            }
          }}
          value={watch("email") || ""}
        />
        {error === "signup" && (
          <span className="error">
            We don't recognise this email address. Try another or{" "}
            <Link
              to="/register"
              style={{
                fontWeight: "600",
              }}
            >
              Sign up
            </Link>{" "}
            to register.
          </span>
        )}

        {showPasswordFields && (
          <>
            <TextInput
              type="password"
              placeholder="Password"
              padding="11px 16px"
              radius="md"
              error={errors?.password?.message}
              {...register("password")}
              value={watch("password") || ""}
              handleError={() => form.setValue("password", "")}
              autoFocus={true}
              onBlur={handleInputTracking}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && form.getValues("password") !== "") {
                  handleInputTracking(e);
                }
              }}
            />
            <Link
              onClick={() => {
                sendEvent({
                  eventName: trackEvents.FORGOT_PASSWORD_BTN,
                });
                setShowStepper(false);
                localStorage.setItem(
                  "forgotPasswordEmail",
                  form.getValues("email") || ""
                );
              }}
              to="/forgot-password"
              style={{ marginLeft: "auto", color: "#4F48E2", fontWeight: 500 }}
            >
              Forgot Password?
            </Link>
          </>
        )}

        {showPromoCode && showPasswordFields && (
          <TextInput
            placeholder="Promo Code (Optional)"
            padding="11px 16px"
            radius="md"
            value={promoCode}
            onChange={handlePromoCodeChange}
            error={promoCodeError}
            handleError={() => {
              setPromoCode("");
              setPromoCodeError("");
            }}
          />
        )}

        <div style={{ display: "flex", gap: 8 }}>
          {showPasswordFields && <BackButton disabled={isSubmitting} />}
          <Button
            disabled={disabled}
            loading={isSubmitting}
            className="submit-button"
            text="Continue"
            type="primary"
            height="38px"
            width="102px"
            weight="bold"
            radius="md"
          />
        </div>
        <TermsConditionPrivacyPolicy />
      </form>
      <BottomAction />
    </>
  );
};

export default LoginForm;
