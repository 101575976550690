import "./index.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import { TermsCondition } from "../TermsCondition";
import { useQueryParams } from "../utils";
import { EMAIL_REGEX } from "src/constants/auth";
import { axiosInstance, updateAxiosInstance } from "src/store/utility";
import axios, { AxiosRequestConfig } from "axios";
import { debounce } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { popMessage } from "../../message/message";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";
import moment from "moment";
import { setActiveDrawer } from "src/store/PIQ/action";
import { useDispatch } from "react-redux";
import { updateLoading } from "src/store/Loading";
import { message } from "antd";

import { fetchSubscriptionData } from "src/store/Subscriptions/subscriptionSlice";
import useAuthCommon from "src/Hooks/auth/useAuthCommon";

const LoginForm = ({
  resetErrors,
  setResetErrors,
}: {
  resetErrors: boolean;
  setResetErrors: (value: boolean) => void;
}) => {
  const { setQueryParams, deleteQueryParam, getParamFromKey } =
    useQueryParams();
  const [promoCode, setPromoCode] = useState<string>("");
  const [email, setEmail] = useState(localStorage.getItem("loginEmail") || "");
  const [emailLoading, setEmailLoading] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState<string>("");
  const [error, setError] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const emailInputRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState("");
  const [hasEmailValidated, setEmailValidated] = useState(false);
  const [rerenderTrigger, setRerenderTrigger] = useState(false);
  const isEmailValid = email?.match(EMAIL_REGEX);
  const { sendEvent } = useTrackerContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const withCheckout = getParamFromKey("with") === "checkout";

  const { handleAutoLogin } = useAuthCommon({
    email: localStorage?.getItem("loginEmail") || "",
  });

  useEffect(() => {
    if (resetErrors) {
      setError("");
      setEmail("");
      setPassword("");
      setResetErrors(false);
    }
  }, [resetErrors]);

  useEffect(() => {
    if (email) localStorage.setItem("loginEmail", email);
  }, [email]);

  const handleInputTracking = (e: any) => {
    if (e?.target?.value === "") return;

    sendEvent({
      eventName: trackEvents.INPUT_FIELD_COMPLETE,
      payload: {
        fieldName: e?.target?.name,
        type: "login",
      },
    });
  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (passwordError) {
  //       setPasswordError("");
  //     }
  //     if (error) {
  //       setError("");
  //     }
  //   }, 10000);
  // useEffect(() => {
  //   if (password) localStorage.setItem("tempPassword", password);
  // }, [password]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (passwordError) {
  //       setPasswordError("");
  //     }
  //     if (error) {
  //       setError("");
  //     }
  //   }, 10000);

  //   return () => clearTimeout(timeout);
  // }, [password, email, passwordError, error]);
  const validatePromoCode = async (promoCode: string) => {
    if (!promoCode) {
      setPromoCodeError("");
      setPromoCode("");
      return;
    }

    sendEvent({
      eventName: trackEvents.BULK_LICENSE_PROMO_CODE_WRITE,
      payload: {
        promoCode: promoCode,
      },
    });

    // Cancel any existing request
    if ((window as any).promoCodeController) {
      (window as any).promoCodeController.abort();
    }

    const companyLicense = JSON.parse(
      localStorage.getItem("companyLicense") || "{}"
    );
    const customUrl = companyLicense?.company || "";

    // Create new AbortController for this request
    (window as any).promoCodeController = new AbortController();

    try {
      const config = {
        signal: (window as any).promoCodeController.signal,
      } as AxiosRequestConfig;

      const response = await axiosInstance.get(
        `/enterprise-license/validate/${promoCode}/${customUrl}`,
        config
      );

      if (response.data.success) {
        message.success(response.data.message);
        setPromoCodeError("");
        sendEvent({
          eventName: trackEvents.BULK_LICENSE_PROMO_CODE_SUCCESS,
          payload: {
            promoCode: promoCode,
          },
        });
      }
    } catch (error: any) {
      if (error.name === "AbortError") {
        // Request was aborted, do nothing
        return;
      }
      setPromoCodeError(
        error.response?.data?.message || "Error validating promo code"
      );
      sendEvent({
        eventName: trackEvents.BULK_LICENSE_PROMO_CODE_FAILURE,
        payload: {
          promoCode: promoCode,
        },
      });
    }
  };

  // Use debounce to avoid multiple rapid API calls
  const debouncedValidatePromoCode = useMemo(
    () => debounce(validatePromoCode, 500),
    []
  );

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPromoCode(value);

    // If empty value, clear immediately
    if (!value) {
      setPromoCodeError("");
      setPromoCode("");
      return;
    } else {
      debouncedValidatePromoCode(value);
    }
  };

  const validateEmail = async (emailToValidate: string) => {
    setEmailLoading(true);
    setEmailValidated(false);

    try {
      await axiosInstance.post("/auth/check-email-verification", {
        email: emailToValidate.toLowerCase(),
      });
      sendEvent({
        eventName: trackEvents.EMAIL_VALIDATION_SUCCESS,
        payload: {
          email: emailToValidate.toLowerCase(),
        },
      });
      setError("");
      setEmailValidated(true);
    } catch (err) {
      const isAxiosError = axios.isAxiosError(err);

      if (isAxiosError) {
        const status = err.response?.status;
        switch (status) {
          case 404:
            setError("signup");
            sendEvent({
              eventName: trackEvents.EMAIL_VALIDATION_FAILURE,
              payload: {
                email: emailToValidate.toLowerCase(),
                error: "Email is not registered.",
              },
            });
            break;
          case 403:
            setError("");
            setEmailValidated(true);
            // setQueryParams({ step: "2", authModal: "login" });
            break;
          default:
            setError("An unexpected error occurred. Please try again.");
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setEmailLoading(false);
    }
  };
  const debouncedValidateEmail = useCallback(
    debounce((email: string) => validateEmail(email), 300),
    []
  );

  useEffect(() => {
    if (!email) {
      setError("");
      setEmailLoading(false);
      setEmailValidated(false);
      return;
    }

    if (isEmailValid) {
      debouncedValidateEmail(email);
    }

    return () => {
      debouncedValidateEmail.cancel();
    };
  }, [email, debouncedValidateEmail]);

  const initiateSignIn = async () => {
    try {
      const response = await axiosInstance.post(
        "/auth/check-email-verification",
        {
          email: email?.toLowerCase(),
        }
      );
      // for logging in user in case user closes modal
      localStorage.setItem("tempPassword", password);

      if (withCheckout) localStorage.setItem("passwordResetInitiated", "true");

      // await Auth.signIn(email?.toLowerCase(), password);

      try {
        const user = await Auth.signIn(email?.toLowerCase(), password);
        const authToken = user.signInUserSession.accessToken.jwtToken;

        updateAxiosInstance(authToken);
        localStorage.setItem(
          "tokenExpirationDate",
          moment().add(1, "days").toISOString()
        );

        setActiveDrawer("");
        dispatch(
          updateLoading({ key: "dashboardloading", value: true }) as any
        );
        localStorage.removeItem("companyLicense");
        localStorage.setItem("loggedIn", "true");

        // if (promoCode) {
        //   try {
        //     const response = await axiosInstance.post(
        //       `/enterprise-license/use/${promoCode}`
        //     );
        //     if (!response.data.success) {
        //       popMessage.errorMessage(response.data.message);
        //     } else {
        //       // Remove subscription and checkout params if promo code is valid
        //       const url = new URL(window.location.href);
        //       url.searchParams.delete("subscriptionType");
        //       url.searchParams.delete("with");
        //       window.history.replaceState({}, "", url.toString());
        //     }
        //   } catch (error: any) {
        //     if (error.response) {
        //       popMessage.errorMessage(error.response.data.message);
        //     } else {
        //       popMessage.errorMessage("Error applying promo code");
        //     }
        //   }
        // }
        // localStorage.removeItem("companyLicense");
        // navigate("/");
        // localStorage.removeItem("loginEmail");
        if (!withCheckout) {
          deleteQueryParam("authModal");
          deleteQueryParam("step");
          deleteQueryParam("internalStep");
          setEmail("");
          setPassword("");
          localStorage.removeItem("tempPassword");
        }
      } catch (err: any) {
        sendEvent({
          eventName: trackEvents.LOGIN_FAIL,
        });
        throw err;
      } finally {
        dispatch(
          updateLoading({ key: "dashboardloading", value: false }) as any
        );
      }
    } catch (error: any) {
      console.log("error check= ", error);
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        localStorage.setItem("passwordResetInitiated", "true");
        // for logging in user in case user closes modal
        localStorage.setItem("tempPassword", password);
        const user = await Auth.signIn(email?.toLowerCase(), password);
        const authToken = user.signInUserSession.accessToken.jwtToken;

        updateAxiosInstance(authToken);
        localStorage.setItem(
          "tokenExpirationDate",
          moment().add(1, "days").toISOString()
        );

        setQueryParams({ step: "2", authModal: "login" });
      } else {
        const message = error.message || "An unexpected error occurred";
        setPasswordError(message.replace("username", "email"));
      }
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    sendEvent({
      eventName: trackEvents.SIGN_IN_SUBMIT,
    });
    localStorage.setItem("authFlow", "login");
    try {
      await initiateSignIn();

      const subscriptionData = await dispatch(fetchSubscriptionData()).unwrap();
      console.log("subscriptionData", subscriptionData);
      if (withCheckout && !subscriptionData?.active) {
        setQueryParams({ step: "5", authModal: "login" });
      } else if (!withCheckout || (withCheckout && subscriptionData?.active)) {
        // navigate("/");
        handleAutoLogin();
        deleteQueryParam("authModal");
        deleteQueryParam("step");
        setEmail("");
        setPassword("");

        localStorage.removeItem("loginEmail");
        localStorage.setItem("loggedIn", "true");
        popMessage.successMessage("Logged in successfully");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const companyLicense = JSON.parse(
    localStorage.getItem("companyLicense") || "{}"
  );
  const showPromoCode = companyLicense?.valid;
  const companyName = companyLicense?.company;

  return (
    <div className="auth-modal-login-form-wrapper">
      <h1 className="auth-modal-login-form-title">Sign In with Email</h1>
      <form onSubmit={handleSubmit} className="auth-modal-login-form">
        <TextInput
          // autoFocus
          style={{ height: "40px" }}
          padding="11px 16px"
          placeholder="example@gmail.com"
          key={rerenderTrigger ? 1 : 0}
          value={email}
          readOnly
          onFocus={(e) => {
            setEmailFocus(!emailFocus);
            e.target.removeAttribute("readOnly");
          }}
          name="email"
          onBlur={(e) => {
            setEmailFocus(!emailFocus);
            handleInputTracking(e);
          }}
          onChange={(e) => {
            setEmail(e.target.value);
            if (!EMAIL_REGEX.test(e.target.value)) {
              setError("Please enter a valid email address");
              setEmailLoading(false);
              return;
            }
          }}
          error={error}
          // key={emailInputRef?.current?.value === "" ? 0 : 1}
          // ref={emailInputRe          key={emailInputRef?.current?.value === "" ? 0 : 1}f}
          iconString={
            hasEmailValidated
              ? "tabler:circle-check-filled"
              : "uis:times-circle"
          }
          showIcon={Boolean(isEmailValid)}
          loading={emailLoading}
          handleError={() => {
            setError("");
            setEmail("");
            setPassword("");
            setRerenderTrigger((prev) => !prev);
          }}
          disabled={Boolean(isEmailValid) && hasEmailValidated && !emailFocus}
          errorMargin="6px 0 0 0"
        />
        {error === "signup" && (
          <p className="auth-modal-login-form-error">
            We don't recognise this email address. Try another or{" "}
            <Link
              to="?authModal=register&step=1"
              tabIndex={-1}
              style={{
                fontWeight: "600",
                color: "#FF644E",
              }}
            >
              Sign Up
            </Link>{" "}
            to register.
          </p>
        )}
        {/* {showPromoCode && (
          <div style={{ marginTop: "12px" }}>
            <TextInput
              placeholder="Promo Code"
              padding="11px 16px"
              radius="md"
              value={promoCode}
              onChange={handlePromoCodeChange}
              error={promoCodeError}
              handleError={() => {
                setPromoCode("");
                setPromoCodeError("");
              }}
              style={{ height: "40px" }}
            />
          </div>
        )} */}

        <div style={{ marginTop: "12px" }}>
          <TextInput
            style={{ height: "45.33px" }}
            placeholder="Password"
            type="password"
            key={rerenderTrigger ? 1 : 0}
            value={password}
            readOnly
            onFocus={(e) => {
              e.target.removeAttribute("readOnly");
            }}
            name="password"
            onBlur={(e) => {
              handleInputTracking(e);
            }}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError}
            errorMargin="6px 0 0 0"
          />
        </div>

        <button
          onClick={() => {
            if (!hasEmailValidated) {
              return setError("Please enter a valid email address");
            } else {
              localStorage.setItem("forgotPasswordEmail", email);
              setQueryParams({ step: "3" });
            }
          }}
          type="button"
          className="auth-modal-login-form-forgot-password"
        >
          Forgot Password?
        </button>

        <div className="auth-modal-login-form-button-container">
          {hasEmailValidated && (
            <Button
              text="Back"
              type="outline"
              height="38px"
              width="72px"
              weight="bold"
              radius="md"
              buttonType="button"
              onClick={() => {
                localStorage.removeItem("loginEmail");
                localStorage.removeItem("forgotPasswordEmail");
                setEmail("");
                setPassword("");
                setPasswordError("");
                setRerenderTrigger((prev) => !prev);
              }}
            />
          )}
          <Button
            disabled={
              isLoading ||
              password.length === 0 ||
              !hasEmailValidated ||
              !isEmailValid
            }
            text="Continue"
            type="primary"
            height="38px"
            width="102px"
            weight="bold"
            radius="md"
            loading={isLoading}
            onClick={() => {
              sendEvent({
                eventName: trackEvents.CONTINUE_BTN,
                payload: {
                  page: "signin",
                  type: "login",
                },
              });
            }}
          />
        </div>
        <TermsCondition />
      </form>

      <div className="auth-modal-login-form-footer">
        Not yet Registered?{" "}
        <span>
          {" "}
          <Link
            to="?authModal=register&step=1"
            className="link"
            onClick={() => {
              sendEvent({
                eventName: trackEvents.TAKE_ME_TO_BTN,
                payload: {
                  from: "login",
                  to: "signup",
                },
              });
            }}
          >
            Take me to Sign Up
          </Link>
        </span>
      </div>
    </div>
  );
};

export default LoginForm;
