import React from "react";
import styles from "./Button.module.scss";
import { LoadingOutlined } from "@ant-design/icons";

interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> {
  className?: string;
  text: string;
  type?:
    | "primary"
    | "secondary"
    | "ghost"
    | "outline"
    | "button"
    | "reset"
    | "submit";
  padding?: string;
  height?: string;
  width?: string;
  radius?: "sm" | "md" | "lg";
  fontSize?: string;
  weight?: "medium" | "bold" | "semibold";
  disabled?: boolean;
  color?: string;
  icon?: React.ReactNode;
  iconPlacement?: "left" | "right";
  gap?: string;
  loading?: boolean;
  background?: string;
  borderColor?: string;
  buttonType?: "submit" | "button";
}

const Button: React.FC<ButtonProps> = ({
  className,
  text,
  type = "primary",
  padding = "10px",
  height = "40px",
  width = "100%",
  radius = "sm",
  fontSize = "14px",
  weight = "medium",
  disabled = false,
  color,
  icon,
  iconPlacement,
  gap = "12px",
  loading,
  background,
  borderColor,
  buttonType = "submit",
  ...rest
}) => {
  return (
    <button
      className={`${styles.button} ${type ? styles[type] : ""} ${
        radius ? styles[`rounded-${radius}`] : ""
      } ${weight ? styles[`fw-${weight}`] : ""} ${
        disabled ? styles.disabled : ""
      } ${className || ""}`}
      style={{
        padding: padding,
        height: height,
        width: width,
        fontSize: fontSize,
        color: color ? color : type === "outline" ? "#4F48E2" : "",
        gap: gap,
        background: background,
        borderColor: borderColor,
      }}
      disabled={disabled || loading}
      type={buttonType}
      {...rest}
    >
      {loading ? (
        <LoadingOutlined style={{ fontSize: "16px" }} />
      ) : (
        <>
          {icon && iconPlacement === "left" && icon}
          {text}
          {icon && iconPlacement === "right" && icon}
        </>
      )}
    </button>
  );
};

export default Button;
