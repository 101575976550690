import React, { forwardRef, useState } from "react";
import "./TextInput.scss";
import { Icon } from "@iconify/react";

// Extending native input props using React.InputHTMLAttributes
// interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  padding?: string;
  disabled?: boolean;
  error?: string;
  height?: string;
  radius?: "sm" | "md" | "lg";
  errorIcon?: React.ReactNode;
  successIcon?: React.ReactNode;
  iconString?: string; // string icon for iconify react
  icon?: React.ReactNode; // any react Node that is to be placed inplace of icon
  iconPlacement?: "left" | "right";
  loading?: boolean;
  showIcon?: boolean;
  errorMargin?: string;
  handleError?: () => void;
  value: string;
}



// Use forwardRef to allow the parent to access the input ref
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      padding = "4px",
      disabled = false,
      error = false,
      radius = "md",
      type = "text",
      height,
      iconPlacement = "right",
      value,
      iconString,
      icon,
      loading,
      showIcon = false,
      errorMargin = "2px 0 0 0",
      handleError,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    // Toggle the password visibility
    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };

    // Determine input type based on state
    const inputType = type === "password" && showPassword ? "text" : type;

    return (
      <div>
        <div className={`text-input-container ${!!error ? "error" : ""}`}>
          <input
            type={inputType}
            style={{ padding, height }}
            className={`radius-${radius}`}
            disabled={disabled}
            value={value}
            ref={ref} // Attach the forwarded ref to the input element
            {...rest} // Spread additional props onto the input
          />

          {type === "password" ? (
            <>
              {
                <span className="icon">
                  <img
                    width={20}
                    height={20}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer", width: 21 }}
                    src={showPassword ? "/icons/eye.svg" : "/icons/eye-off.svg"}
                    alt=""
                  />
                </span>
              }
            </>
          ) : (
            <>
              {loading ? (
                <span className="icon">
                  <Icon icon="eos-icons:loading" />
                </span>
              ) : (
                <>
                  {iconString &&
                    showIcon &&
                    iconPlacement &&
                    iconPlacement === "right" && (
                      <div
                        onClick={() => {
                          if (handleError && error) {
                            handleError();
                          }
                        }}
                        className="custom-icon right"
                        style={{ cursor: error ? "pointer" : "" }}
                      >
                        <Icon icon={iconString} />
                      </div>
                    )}
                  {icon && <div className="custom-icon">{icon}</div>}
                </>
              )}
            </>
          )}
        </div>
        {!!error &&
          error !== " " &&
          error !== "signup" &&
          error !== "AlreadyExists" && (
            <span
              className="error"
              style={{ fontSize: 14, margin: errorMargin, display: "block" }}
            >
              {error}
            </span>
          )}
      </div>
    );
  }
);

export default TextInput;
