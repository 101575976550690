export const pricingData = [
  // {
  //   title: "Unregistered",
  //   annualPrice: "0.00",
  //   monthlyPrice: "0.00",
  //   highlight: false,
  //   description:
  //     "Dictum aliquet arcu egestas massa sed tellus sed arcu velit tincidunt orci.",
  //   services: [
  //     {
  //       title: "Reuters Feed",
  //       checked: true,
  //     },
  //     {
  //       title: "5 Articles Per Month",
  //       checked: true,
  //     },
  //     {
  //       title: "6 News Feeds",
  //       checked: true,
  //     },
  //     {
  //       title: "1 PiQ X Lists",
  //       checked: true,
  //     },
  //     {
  //       title: "1 PiQ TV Player",
  //       checked: true,
  //     },
  //     {
  //       title: "1 Charts & Data",
  //       checked: true,
  //     },

  //     {
  //       title: "Telegram (beta)",
  //       checked: true,
  //     },
  //     {
  //       title: "Dark Mode",
  //       checked: true,
  //     },
  //     {
  //       title: "Custom Feed",
  //       checked: false,
  //     },
  //     {
  //       title: "Bookmarks",
  //       checked: false,
  //     },

  //     {
  //       title: "Keyword Highlights",
  //       checked: false,
  //     },

  //     {
  //       title: "Save Layout",
  //       checked: false,
  //     },
  //     {
  //       title: "Reduced Ads",
  //       checked: false,
  //     },
  //   ],
  //   buttonText: "Continue Unregistered",
  //   redirectLink: "/",
  // },
  {
    title: "Free-IQ",
    annualPrice: "0.00",
    monthlyPrice: "0.00",
    highlight: false,
    description:
      "Dictum aliquet arcu egestas massa sed tellus sed arcu velit tincidunt orci.",
    services: [
      {
        title: "Reuters Feed",
        checked: true,
      },
      {
        title: "Unlimited Articles Per Month",
        checked: true,
      },
      {
        title: "10 News Feeds",
        checked: true,
      },
      {
        title: "3 PiQ X Lists",
        checked: true,
      },
      {
        title: "2 PiQ TV Player",
        checked: true,
      },
      {
        title: "1 Charts & Data",
        checked: true,
      },

      {
        title: "Telegram (beta)",
        checked: true,
      },
      {
        title: "Dark Mode",
        checked: true,
      },
      {
        title: "1 Custom Feed",
        checked: true,
      },
      {
        title: "5 Bookmarks",
        checked: true,
      },

      {
        title: "3 Keyword Highlights",
        checked: true,
      },

      {
        title: "Save Layout",
        checked: true,
      },
      {
        title: "Reduced Ads",
        checked: false,
      },
    ],
    buttonText: "Register for Free-IQ",
    redirectLink: "/register",
  },
  {
    title: "Hi-IQ",
    annualPrice: "300",
    saving: "(Saving £60!)",
    monthlyPrice: "30",
    highlight: true,
    description:
      "Dictum aliquet arcu egestas massa sed tellus sed arcu velit tincidunt orci.",
    services: [
      {
        title: "Reuters Feed",
        checked: true,
      },
      {
        title: "Unlimited Articles Per Month",
        checked: true,
      },
      {
        title: "20 News Feeds",
        checked: true,
      },
      {
        title: "7 PiQ X Lists",
        checked: true,
      },
      {
        title: "4 PiQ TV Player",
        checked: true,
      },
      {
        title: "10 Charts & Data",
        checked: true,
      },

      {
        title: "Telegram (beta)",
        checked: true,
      },
      {
        title: "Dark Mode",
        checked: true,
      },
      {
        title: "5 Custom Feed",
        checked: true,
      },
      {
        title: "50 Bookmarks",
        checked: true,
      },

      {
        title: "25 Keyword Highlights",
        checked: true,
      },

      {
        title: "Save Layout",
        checked: true,
      },
      {
        title: "Reduced Ads",
        checked: true,
      },
    ],
    buttonText: "Register for Hi-IQ",
    redirectLink: "/register",
  },
];

export const monthlyHighIqPrice = 25;
export const monthlySalesTax = 0.2 * monthlyHighIqPrice;
export const annuallyHighIqPrice = 250;
export const annualSalesTax = 0.2 * annuallyHighIqPrice;
