import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  forgotPasswordSchema,
  TForgotPasswordSchema,
} from "src/schema/auth/auth.schema";
import { Auth } from "aws-amplify";
import { popMessage } from "src/development/Component/message/message";
import { handleError } from "./usePasswordVerification";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { useQueryParams } from "src/development/Component/Auth-Modal/utils";

export const useChangePassword = () => {
  const form = useForm<TForgotPasswordSchema>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const { sendEvent } = useTrackerContext();

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isSubmitting },
    setValue,
  } = form;
  const { setQueryParams } = useQueryParams();

  const onSubmit = async (values: TForgotPasswordSchema) => {
    const defaultPassword = "DefaultPassword123#";
    sendEvent({
      eventName: trackEvents.CHANGE_PASSWORD_SUBMIT,
    });
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, defaultPassword, values.password);
      sendEvent({
        eventName: trackEvents.CHANGE_PASSWORD_SUCCESS,
      });
      await Auth.signOut();
      localStorage.removeItem("passwordResetInitiated");
      popMessage.successMessage("Password changed successfully!");
      setQueryParams({ authModal: "login", step: "1" });
    } catch (err: any) {
      console.error("Error changing password:", err);
      sendEvent({
        eventName: trackEvents.CHANGE_PASSWORD_FAILURE,
      });
      const message = handleError(
        err,
        "Error while changing password. Please try again."
      );
      popMessage.errorMessage(message);
    }
  };
  return {
    handleSubmit,
    onSubmit,
    watch,
    register,
    errors,
    isSubmitting,
    setValue,
  };
};
