import React, { useEffect, useState } from "react";
import ColumnLoading from "../Loading";
import { SocketNames } from "src/interface/PiqState";
import { SingleKeywordColConfig } from "src/interface/keyword";
import { checkIfDivHasHighlight } from "src/utils/utilFunction";
import { useAuthContext } from "src/context/AuthProvider";
import { useSelector } from "react-redux";
import { InFeedAds } from "../InFeedAds";
import FeedSearchNotFound from "../Feeds/FeedSearchNotFound";
import useFeeds from "src/Hooks/useFeeds";
import { CustomFeedItem } from "./CustomFeedItem";
import NotFoundCustom from "../Feeds/NotFoundCustom";

const CustomNewsFeedList: React.FC<{
  list: any[];
  socket: SocketNames;
  twitterAccount?: string;
  colConfig: SingleKeywordColConfig;
  isSearchLoading: boolean;
  searchedText: string;
  filterActive: boolean;
  isWidth2: boolean;
}> = ({
  list,
  socket,
  twitterAccount,
  colConfig,
  isSearchLoading,
  searchedText,
  filterActive,
  isWidth2,
}) => {
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  const { divHasHighligts } = useFeeds();

  const pepperStoneAd = list?.find(
    (item) => item?.isAd && item?.type === "PEPPERSTONE"
  );

  return (
    <>
      {list?.length ? (
        list?.map((item: any, index: number) => {
          return (
            <div key={index}>
              <div key={item?._id}>
                {item?.isAd && (
                  <InFeedAds
                    item={isWidth2 ? pepperStoneAd : item}
                    isWidth2={isWidth2}
                  />
                )}
                <div
                  hidden={item?.isAd}
                  style={{
                    padding: "5px 10px 8px 15px",
                    border:
                      isUserLoggedIn &&
                      colConfig?.highlightEntireCard &&
                      divHasHighligts(item?.title, colConfig)
                        ? `${`2px solid ${divHasHighligts(
                            item?.title,
                            colConfig
                          )}`}`
                        : "",
                    backgroundColor: darkMode
                      ? isUserLoggedIn &&
                        colConfig?.highlightEntireCard &&
                        checkIfDivHasHighlight(item?.title, colConfig)
                        ? "#515A67"
                        : "#384251"
                      : "white",
                    color:
                      isUserLoggedIn &&
                      checkIfDivHasHighlight(item?.title, colConfig)
                        ? "white"
                        : "none",
                  }}
                  className={`v2-suite-column-card-item bla ${
                    item.sourceName === "reuters" && item?.version === 1
                      ? "-reuters"
                      : ""
                  }`}
                >
                  <CustomFeedItem
                    item={item}
                    socket={item.socketName}
                    sourceName={item.sourceName}
                    twitterAccount={twitterAccount}
                    colConfig={colConfig}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : isSearchLoading ? (
        <ColumnLoading />
      ) : filterActive ? (
        <FeedSearchNotFound searchedText={searchedText} />
      ) : (
        <NotFoundCustom />
      )}
    </>
  );
};
export default CustomNewsFeedList;
