import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getScriptInnerHTML } from "src/constants/financialJuice";
import { SocketNames } from "src/interface/PiqState";

type Props = {
    setFetching: Dispatch<SetStateAction<boolean>>;
    setReload: Dispatch<SetStateAction<boolean>>;
    reload: boolean;
    socket: SocketNames;
    height: string;
};

const FinancialJuiceGenericWidget = ({
    setFetching,
    setReload,
    reload,
    socket,
    height
}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    useEffect(() => {
        const createIframe = () => {
            if (containerRef.current) {
                const iframe = document.createElement('iframe');
                iframe.style.width = '100%';
                iframe.style.height = '600px';
                iframe.style.border = 'none';

                containerRef.current.appendChild(iframe);

                const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
                if (iframeDoc) {
                    iframeDoc.open();
                    iframeDoc.write(`
                        <html>
                        <head>
                            <style>
                                body { 
                                    margin: 0;
                                    overflow: hidden;
                                    display:flex;
                                    background : ${darkMode ? "#242D38" : "#ffffff"};
                                    height:100%;
                                }
                                #financialjuice-eco-widget-container{
                                    display:flex;
                                    margin-inline:auto;
                                    margin-top:1em;
                                }

                                #financialjuice-news-widget-container{
                                    width:100%;
                                }

                                iframe{
                                    margin-inline:auto;
                                    height:100%;
                                    width:100% !important;
                                }

                                #financialjuice-ts-widget-container{
                                    margin-inline:auto;
                                    width:95% !important;
                                }

                                .ts-widget{
                                    width:100% !important;
                                    height:100% !important;
                                }
                            </style>
                        </head>
                        <body>
                            ${getScriptInnerHTML(socket, darkMode)}
                        </body>
                        </html>
                    `);
                    iframeDoc.close();
                }

                iframe.onload = () => setFetching(false);
            }
        };

        // Remove existing iframe if any
        if (containerRef.current) {
            containerRef.current.innerHTML = '';
        }

        // Create new iframe
        createIframe();

        return () => {
            if (containerRef.current) {
                containerRef.current.innerHTML = '';
                setReload(false);
                setFetching(false)
            }
        };
    }, [darkMode, reload]);

    return (
        <div style={{
            height
        }} ref={containerRef}></div>
    );
};

export default FinancialJuiceGenericWidget;
