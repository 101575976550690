import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { popMessage } from "src/development/Component/message/message";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { useQueryParams } from "src/development/Component/Auth-Modal/utils";

// Utility function to handle errors
export const handleError = (err: any, defaultMessage: string) => {
  switch (err.code) {
    case "LimitExceededException":
      return "Too many attempts. Please wait a few minutes before requesting another code.";
    case "UserNotFoundException":
      return "We couldn't find an account with this email address.";
    case "InvalidParameterException":
      return "Please ensure you've entered a valid email address.";
    case "UserNotConfirmedException":
      return "Your account is not verified. Please check your email for verification instructions.";
    case "NetworkError":
      return "Network error. Please check your internet connection and try again.";
    case "ThrottlingException":
      return "You've requested too many codes. Please wait a while before trying again.";
    case "InvalidEmailRoleAccessPolicyException":
      return "We're currently unable to send emails. Please try again later or contact support.";
    case "CodeMismatchException":
      return "The code you entered is incorrect. Please try again.";
    case "ExpiredCodeException":
      return "This verification code has expired. Please request a new code.";
    case "InvalidPasswordException":
      return "There was an issue with the temporary password. Please try requesting a new code.";
    default:
      return err.message || defaultMessage;
  }
};

export const usePasswordResetVerification = (
  setStep: (step: number) => void
) => {
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { sendEvent } = useTrackerContext();
  const { setQueryParams } = useQueryParams();

  // Replace URL param with localStorage
  // Replace URL param with localStorage
  useEffect(() => {
    const storedEmail = localStorage.getItem("forgotPasswordEmail");

    if (!storedEmail) {
      setQueryParams({ authModal: "login", step: "1" });
      return;
    }

    // // Send forgot password code on initial load
    if (storedEmail?.trim()) {
      Auth.forgotPassword(storedEmail.trim());
    }
  }, []);

  const handleResendCode = async () => {
    const email = localStorage.getItem("forgotPasswordEmail");
    if (!email?.trim()) {
      popMessage.errorMessage("Email not found. Please go back and try again.");
      return;
    }
    setError("");
    try {
      await Auth.forgotPassword(email.trim());
      popMessage.successMessage(
        "A new verification code has been sent to your email."
      );
      setOtp("");
    } catch (err: any) {
      console.error("Error resending code:", err);
      const errorMessage = handleError(
        err,
        "Failed to send verification code. Please try again later."
      );
      popMessage.errorMessage(errorMessage);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const email = localStorage.getItem("forgotPasswordEmail");

    if (!email?.trim()) {
      setError("Email is required");
      return;
    }

    if (!otp?.trim()) {
      setError("Please enter the verification code");
      return;
    }

    if (!/^\d{6}$/.test(otp)) {
      setError("Code must be exactly 6 digits");
      return;
    }

    sendEvent({
      eventName: trackEvents.PWD_RESET_VERIFICATION_CODE_SUBMIT,
      payload: {
        code: otp,
      },
    });
    setLoading(true);
    try {
      localStorage.setItem("passwordResetInitiated", "true");
      await Auth.forgotPasswordSubmit(email.trim(), otp, "DefaultPassword123#");
      await Auth.signIn(email.trim(), "DefaultPassword123#");
      sendEvent({
        eventName: trackEvents.PWD_RESET_VERIFICATION_SUCCESS,
      });
      localStorage.removeItem("forgotPasswordEmail"); // Clean up
      // setStep(2);
      setQueryParams({ authModal: "login", step: "4" });
    } catch (err: any) {
      console.error("Password reset verification failed:", err);
      const errorMessage = handleError(
        err,
        "Unable to verify code. Please try again or request a new code."
      );
      setError(errorMessage);
      sendEvent({
        eventName: trackEvents.PWD_RESET_VERIFICATION_FAILURE,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    otp,
    setOtp,
    error,
    setError,
    loading,
    handleSubmit,
    handleResendCode,
    email: localStorage.getItem("forgotPasswordEmail"),
  };
};
