import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  axiosInstance,
  remove_auth_headers,
  updateAxiosInstance,
} from "src/store/utility";
import jwt from "jsonwebtoken";
import { useDispatch } from "react-redux";
import {
  getSuiteConfig,
  logoutReset,
  setUserDetails,
} from "src/store/User/action";
import { updateLoading } from "src/store/Loading";
import { loginSocketEmit } from "src/Socket";
import plausible from "src/plausible";
import { fetchSubscriptionData } from "src/store/Subscriptions/subscriptionSlice";
import { popMessage } from "src/development/Component/message/message";
import { limitExceedTracker } from "src/utils/limitExceed";
import {
  check_if_token_is_expired,
  update_user_details,
} from "src/store/userStore";
import { useLayoutContext } from "src/context/LayoutProvider";
import { useTrackerContext } from "src/context/TrackerProvider";
import { defaultColumnCoordinates } from "src/SuiteConfig/columnConfiguration";
import { fetchCustomFeedUserData } from "src/store/PiQColumns";
import { trackEvents } from "src/constants/tracker";

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const { sendEvent, trackLoginSuccess, resetMixpanel, trackSignUpSuccess } =
    useTrackerContext();
  const { setPiqSuiteLayout } = useLayoutContext();
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    checkUser();
    const interval = setInterval(() => {
      checkUser();
    }, 36e5);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch_user_suite = async () => {
    await getSuiteConfig()
      .then((res: any) => {
        setPiqSuiteLayout(res?.data?.layout);
        dispatch({
          type: "USER_TOGGLE_DARK_MODE",
          mode: res?.data?.darkMode,
        });
        // Mark as fetched
        setDataFetched(true);
      })
      .catch((err) => {
        dispatch(check_if_token_is_expired(err.response));
        setPiqSuiteLayout(defaultColumnCoordinates);
      })
      .finally(() => {
        dispatch(
          updateLoading({ key: "dashboardloading", value: false }) as any
        );
      });
  };

  useEffect(() => {
    Hub.listen("auth", async (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        // This is because when changeing password user needs to be logged in for short period and
        // will be logged out after password is changed.
        const initiation =
          localStorage.getItem("passwordResetInitiated") === "true";
        if (initiation) return;

        sign_in_user();
        checkUser();
      }
      if (payload.event === "cognitoHostedUI") {
        checkGmailSignIn();
        checkUser();
      }
      if (payload.event === "signOut") {
        setUser(null);
        setIsSignedIn(false);
        // resetMixpanel();
      }

      if (payload.event === "configured") {
        checkUser();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sign_in_user = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);

      const authToken = user.signInUserSession.accessToken.jwtToken;
      updateAxiosInstance(authToken);

      const countryCode = localStorage.getItem("countryCode");
      const response = await axiosInstance.post(`/auth/saveUser`, {
        countryCode,
        login: true,
      });

      const { email, _id, given_name, family_name, newUser } =
        response.data.data;

      const authFlow = localStorage.getItem("authFlow");

      // Track login/signup regardless of authFlow to ensure tracking on subsequent logins
      // if (authFlow === "login" || (!authFlow && !newUser)) {
      //   console.log("tracking login");
        if(authFlow === "login"){
          trackLoginSuccess({
            email: email,
            user_id: _id,
            first_name: given_name,
            last_name: family_name,
          });
        }else if(authFlow === "register"){
          trackSignUpSuccess({
            email: email,
            user_id: _id,
            first_name: given_name,
            last_name: family_name,
          });
        }
      //   localStorage.removeItem("authFlow");
      // } else if (authFlow === "register" || (!authFlow && newUser)) {
      //   console.log("tracking registration");
      //   trackSignUpSuccess({
      //     email: email,
      //     user_id: _id,
      //     first_name: given_name,
      //     last_name: family_name,
      //   });
      //   localStorage.removeItem("authFlow");
      // }


      // if (!newUser)
      //   trackLoginSuccess({
      //     email: email,
      //     user_id: _id,
      //     first_name: given_name,
      //     last_name: family_name,
      //   });

      // if (newUser){
      //   localStorage.setItem("newUser","true");
      //   trackSignUpSuccess({
      //     email: email,
      //     user_id: _id,
      //     first_name: given_name,
      //     last_name: family_name,
      //   });
      // }

      // const googleAuthTrack = localStorage.getItem("googleAuthTrack");
      // const googleAuth = localStorage.getItem("googleAuth");

      // if (
      //   // !googleAuth &&
      //   window.location.pathname !== "/forgot-password" &&
      //   window.location.pathname !== "?authModal=login&step=1&internalStep=1" &&
      //   window.location.pathname !== "/register"
      // ) {
      //   if (newUser) {
      //     localStorage.setItem("newUser", "true");
      //     // if (googleAuthTrack) {
      //     //   popMessage.successMessage("Thanks for registering");
      //     //   localStorage.removeItem("newUser");
      //     //   localStorage.removeItem("googleAuthTrack");
      //     //   localStorage.removeItem("loginWithCheckout");
      //     // }
      //   } else {
      //     // if (googleAuthTrack) {
      //     //   // popMessage.successMessage("Welcome Back useAuth")
      //     //   localStorage.removeItem("googleAuthTrack");
      //     //   localStorage.setItem("loginWithCheckout", "true");
      //     // }
      //   }
      // }

      // if (googleAuth) {
      //   if (newUser) {
      //     localStorage.setItem("newUser", "true");
      //   }
      // }

      // to prevent login success tracking when password reset
      // to be change when the implementation of password reset changes
      // if (window.location.pathname !== "/forgot-password" 
      //   // && 
      //   // !!!googleAuthTrack
      // )
      //   trackLoginSuccess({
      //     email,
      //     user_id: _id,
      //     first_name: given_name,
      //     last_name: family_name,
      //   });

      dispatch(fetchCustomFeedUserData());
      // await fetch_user_suite();
      dispatch(setUserDetails(response.data.data));
      dispatch(update_user_details(response.data.data));
      dispatch(fetchSubscriptionData());
      setIsSignedIn(true);
      loginSocketEmit(authToken);

        
    } catch (error: any) {
      const errorResponse = error.response;
      if (errorResponse?.data?.msg) {
        popMessage.errorMessage(errorResponse.data.msg);
      }
      remove_auth_headers();
      setIsSignedIn(false);
    } finally {
      dispatch(updateLoading({ key: "dashboardloading", value: false }));
    }
  };

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await getToken();
      setUser(user);
      setIsSignedIn(true);
    } catch (err) {
      limitExceedTracker();
      setIsSignedIn(false);
      updateAxiosInstance("");
    }
  };

  const checkGmailSignIn = async () => {
    try {
      dispatch(updateLoading({ key: "dashboardloading", value: true }));
      if (!Auth || typeof Auth.signIn !== "function") {
        throw new Error(
          "No Auth module found, please ensure @aws-amplify/auth is imported"
        );
      }

      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
      const authToken = user.signInUserSession.accessToken.jwtToken;

      updateAxiosInstance(authToken);

      await fetch_user_suite();
      plausible.trackEvent("Login");

      const queryParamValue = localStorage.getItem("utm_source");
      if (queryParamValue) {
        plausible.trackEvent(`${queryParamValue}Signin`, {
          utm_source: queryParamValue,
        });
        localStorage.removeItem("utm_source");
      }

      const countryCode = localStorage.getItem("countryCode");
      const response = await axiosInstance.post(`/auth/saveUser`, {
        countryCode,
      });
      localStorage.setItem("loggedIn", "true");

      dispatch(setUserDetails(response.data.data));
      dispatch(update_user_details(response.data.data));
      dispatch(fetchSubscriptionData());
      setIsSignedIn(true);
      loginSocketEmit(authToken);
      sendEvent({
        eventName: trackEvents.GOOGLE_AUTH,
      });
    } catch (err: any) {
      console.error("Gmail sign-in error:", err);

      if (err.response?.data?.msg) {
        popMessage.errorMessage(err.response.data.msg);
      } else {
        popMessage.errorMessage(
          "An error occurred during sign-in. Please try again."
        );
      }

      // Uncomment and adjust these lines if needed for specific error handling
      // if (err.code === "UserNotConfirmedException") {
      //   setEmailAddress(username);
      //   await Auth.resendSignUp(username);
      //   localStorage.setItem("piq-user-emailAddress", username);
      //   setActiveDrawer("confirm-signup-drawer");
      // } else if (err.code === "PasswordResetRequiredException") {
      //   await Auth.resendSignUp(username);
      //   setActiveDrawer("forgot-password-drawer");
      // }
    } finally {
      dispatch(updateLoading({ key: "dashboardloading", value: false }));
    }
  };

  const getToken = async () => {
    try {
      const currentSession: any = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken().getJwtToken();
      setToken(accessToken);

      const decodedPayload: any = jwt.decode(accessToken);
      const expirationTime = decodedPayload?.exp;
      const currentTime = Math.floor(Date.now() / 1000);

      if (currentTime <= expirationTime) {
      } else {
        const time = setTimeout(async () => {
          dispatch(logoutReset() as any);
        }, 5000);
        return () => clearTimeout(time);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return { user, token, isSignedIn, checkGmailSignIn };
};
