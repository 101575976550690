import React from "react";
import LoginModal from "../Auth-Modal/Login";

const DevelopmentPage = () => {
  return (
    <div>
      <LoginModal />
    </div>
  );
};

export default DevelopmentPage;
