import React from 'react'
import reload from "src/style/images/feedsharingreloadbtn.svg";
import menu from "src/style/images/feedsharingmenuicon.svg";
import topicline from "src/style/images/feedsharingtopiclinevideo.svg";
import IPTVPLayer from '../IPTVPlayer/IPTVPlayer';
import { useAuthContext } from 'src/context/AuthProvider';
import { useSelector } from 'react-redux';
import { getYoutubeId } from 'src/utils/common';

function VideoFeedShare({ sharedFeedData }: any) {
    const { isSignedIn: isUserLoggedIn } = useAuthContext();
    const { darkMode } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));

    return (
        <div className="video-feed-container">
            <div className="topic">
                <div className="topic-left">
                    <div className="topic-name">{sharedFeedData?.title}</div>
                    <div className="reload-btn"><img src={reload}></img></div>
                </div>

                <div className="topic-right">
                    <img src={menu}></img>
                </div>
            </div>
            <img src={topicline} className="topic-line"></img>
            <div className="video-items">
                {/* <IPTVPLayer
                    autoplay={false}
                    controls={true}
                    sources={"https://liveprodusphoenixeast.akamaized.net/USPhx-HD/Channel-TX-USPhx-AWS-virginia-1/Source-USPhx-16k-1-s6lk2-BP-07-02-81ykIWnsMsg_live.m3u8"}
                /> */}
                <>
                    {(
                        sharedFeedData?.type?.toLowerCase() === "iptv" ? (
                            // isUserLoggedIn ?
                            <div
                                className="v2-video-contatiner"
                                key={isUserLoggedIn ? 1 : 2}
                                style={{ background: darkMode ? "#384251" : "#fff" }}
                            >
                                <IPTVPLayer
                                    autoplay={false}
                                    controls={true}
                                    sources={sharedFeedData?.url}
                                />
                            </div>
                        ) : // : <></>
                            sharedFeedData?.type?.toLowerCase() === "youtube" ? (
                                <>
                                    <div
                                        className={`hytPlayerWrap v2-video-contatiner 
                      ${!isUserLoggedIn ? "blur-effect" : ""}
                      `}
                                        style={{ background: darkMode ? "#384251" : "#fff" }}
                                        key={isUserLoggedIn ? 1 : 2}
                                    >
                                        <iframe
                                            style={{ paddingBottom: 20 }}
                                            className="hytPlayerWrap"
                                            src={`https://www.youtube.com/embed/${getYoutubeId(
                                                sharedFeedData?.url
                                            )}`}
                                            title={sharedFeedData?.title}
                                            // frameborder={"0"}
                                            allowFullScreen
                                            height={"100%"}
                                            width={"100%"}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={`hytPlayerWrap v2-video-contatiner ${!isUserLoggedIn ? "blur-effect" : ""
                                            }`}
                                        style={{ background: darkMode ? "#384251" : "#fff" }}
                                        key={isUserLoggedIn ? 1 : 2}
                                    >
                                        <iframe
                                            style={{ paddingBottom: 20 }}
                                            className="hytPlayerWrap"
                                            src={sharedFeedData?.url}
                                            title={sharedFeedData?.title}
                                            // frameborder={"0"}
                                            allowFullScreen
                                            height={"100%"}
                                            width={"100%"}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        ></iframe>
                                    </div>
                                </>
                            )
                    )}
                </>
            </div>
        </div>
    )
}

export default VideoFeedShare