import React, { useEffect, useState, useMemo } from "react";
import { Checkbox } from "antd";
import { useSelector } from "react-redux";

const CustomFeedAdd: React.FC<{
  socket: string;
  handleAddCustomFeed: (socket: string, slugs: string[]) => void;
  setGenerateKey: React.Dispatch<React.SetStateAction<number>>;
  generateKey: number;
  sortFeeds: boolean;
  setSortFeeds: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  socket,
  handleAddCustomFeed,
  setGenerateKey,
  generateKey,
  sortFeeds,
  setSortFeeds,
}) => {
  const [slugs, setSlugs] = useState<string[]>([]);
  const { hasNewsQuawkSubscription, hasHammerstoneSubscription }: any =
    useSelector((state: any) => state.premiumapps);

  const { feeds, darkMode, columns } = useSelector((state: any) => ({
    feeds: state.piqcolumns.customFeeds,
    darkMode: state.user.darkMode,
    columns: state.piqcolumns.columns,
  }));

  const [sortedFeeds, setSortedFeeds] = useState<any[]>([]);

  const excludedSlugs = useMemo(
    () => [
      "tradingview",
      "investing-economic-calender",
      "piqpodcast",
      "telegram",
      ...(hasNewsQuawkSubscription ? [] : ["newsquawk"]),
      ...(hasHammerstoneSubscription ? [] : ["hammerstone"]),
    ],
    [hasNewsQuawkSubscription, hasHammerstoneSubscription]
  );

  const excludedFeedsAppSelectorTypes = useMemo(
    () => [
      // "PIQ_FEED",
      "TUTORIAL_FEED",
      "TWITTER_FEED",
      "PREDICTION_MARKETS",
      "CHART_FEED",
      "CALENDAR",
      "SOCIAL_APP_FEED",
    ],
    []
  );

  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (!Array.isArray(sortedFeeds)) return;

    let filteredOptions = sortedFeeds
      .filter((item: any) => !excludedSlugs.includes(item?.slug))
      .filter(
        (item: any) =>
          item?.appSelectorTypes &&
          !item.appSelectorTypes.some((appSelectorType: string) =>
            excludedFeedsAppSelectorTypes?.includes(appSelectorType)
          )
      );
    if (sortFeeds) {
      // First sort by whether the slug is included, then alphabetically by title
      filteredOptions = filteredOptions.sort((a: any, b: any) => {
        const aIncluded = slugs.includes(a.slug);
        const bIncluded = slugs.includes(b.slug);

        if (aIncluded && !bIncluded) return -1;
        if (!aIncluded && bIncluded) return 1;

        return a.title.localeCompare(b.title);
      });
    }
    setSortedFeeds(filteredOptions);
    console.log(filteredOptions);
    let newFilteredOptions = filteredOptions.map((data: any) => ({
      label: data.title,
      value: data.slug,
    }));

    setOptions(newFilteredOptions);
  }, [slugs, columns, excludedSlugs, excludedFeedsAppSelectorTypes, sortFeeds]);

  useEffect(() => {
    if (Array.isArray(columns)) {
      setSortedFeeds(columns);
    }
  }, [columns]);

  useEffect(() => {
    const feedToEdit = feeds?.find((feed: any) => feed.slug === socket);
    if (feedToEdit) {
      setSlugs(feedToEdit.slugs || feedToEdit.feeds || []);
    }
  }, [feeds, socket]);

  useEffect(() => {
    setGenerateKey(Math.random());
  }, []);

  const onChange = (checkedValues: string[]) => {
    setSortFeeds(false);
    setSlugs(checkedValues);
    handleAddCustomFeed(socket, checkedValues);
  };

  return (
    <div
      className={
        darkMode
          ? "dark-custom-add-feed-main-container"
          : "custom-add-feed-main-container"
      }
    >
      {/* to be removed before migrating this code to uat */}
      {/* <i>Total feeds : {options?.length}</i> */}
      <div>{slugs.length} Selected</div>
      <div className="feed-lists">
        <Checkbox.Group
          options={options}
          value={slugs}
          onChange={(event) => onChange(event as string[])}
          key={generateKey}
        />
      </div>
    </div>
  );
};

export default React.memo(CustomFeedAdd);
