import { Stripe } from "@stripe/stripe-js/types/stripe-js";
import { Dispatch, FormEvent, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { annuallyHighIqPrice, annualSalesTax, monthlyHighIqPrice, monthlySalesTax } from "src/constants/pricing";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { fetchSubscriptionData } from "src/store/Subscriptions/subscriptionSlice";
import { check_if_token_is_expired } from "src/store/userStore";
import { axiosInstance } from "src/store/utility";
import { getUrlParam } from "src/utils/utilFunction";

const API_URL = process.env.REACT_APP_API_URL;

interface UseCheckoutProps {
    elements: any;
    lm_data: any;
    stripe: any;
    userData: any;
    setPaymentError: (error: boolean) => void;
    afterPaymentStepHandler: () => void;
    isMonthly: boolean;
}

const promoCodeErrorTxt = "The promocode you entered is invalid. Try another valid code."

export const useCheckout = ({
    elements,
    lm_data,
    stripe,
    userData,
    setPaymentError,
    afterPaymentStepHandler,
    isMonthly
}: UseCheckoutProps) => {

    const promoCodeRef = useRef<HTMLInputElement>(null);
    const [promoCodeSubmitting, setPromoCodeSubmitting] = useState<boolean>(false);
    const [discountAmount, setDiscountAmount] = useState<number>();
    const [discountPercent, setDiscountPercent] = useState<number>();
    const [promoCode, setPromoCode] = useState<string>("");

    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

    const [promoCodeError, setPromoCodeError] = useState<string>("");
    const [tc, setTc] = useState<boolean>(false);

    const monthlyPrice = monthlyHighIqPrice;
    const yearlyPrice = annuallyHighIqPrice;

    const [originalDiscountPercent, setOriginalDiscountPercent] = useState<number>(0);

    const location = useLocation();
    const dispatch = useDispatch();

    console.log("isMonthly", isMonthly)

    const salesTax = useMemo(() => isMonthly ? monthlySalesTax : annualSalesTax, [isMonthly]);

    const subscriptionTypeFromParams = useMemo(() => {
        return getUrlParam("subscriptionType")
    }, [location?.search])

    const { sendEvent } = useTrackerContext();

    // useEffect(() => {
    //     if (subscriptionTypeFromParams) {
    //         if (subscriptionTypeFromParams === "monthly") setIsMonthly(true);
    //         else if (subscriptionTypeFromParams === "annually") setIsMonthly(false);
    //     }
    // }, [subscriptionTypeFromParams])

    const handlePromoCode = async () => {
        const promoCode = promoCodeRef?.current?.value;
        if (promoCode?.trim() === "") return;
        try {
            const response: any = await axiosInstance.post(
                `${API_URL}/subscriptions/coupon/validation`,
                {
                    promoCode: promoCode?.trim(),
                    totalAmt: isMonthly
                        ? Number((monthlyPrice + salesTax).toFixed(2))
                        : Number((yearlyPrice + salesTax).toFixed(2)),
                }
            );

            if (response?.data?.status) {
                const totalAmt: number = isMonthly
                    ? Number((monthlyPrice + salesTax).toFixed(2))
                    : Number((yearlyPrice + salesTax).toFixed(2));

                if (response?.data?.data?.percent_off) {
                    const discountAmt = totalAmt * (response?.data?.data?.percent_off / 100);
                    setDiscountAmount(discountAmt);
                    setDiscountPercent(response?.data?.data?.percent_off);
                    setOriginalDiscountPercent(response?.data?.data?.percent_off);
                } else if (response?.data?.data?.amount_off) {
                    const discountAmt = response?.data?.data?.amount_off / 100;
                    const totalBeforeDiscount = isMonthly
                        ? monthlyPrice + salesTax
                        : yearlyPrice + salesTax;
                    const discountPercentage = ((discountAmt / totalBeforeDiscount) * 100).toFixed(2);
                    setDiscountAmount(discountAmt);
                    setDiscountPercent(Number(discountPercentage));
                    setOriginalDiscountPercent(Number(discountPercentage));
                }
            } else {
                setPromoCodeError(promoCodeErrorTxt);
                throw "wrong promo code";
            }
        } catch (error) {
            throw error;
        }
    };

    const promoCodeSubmitHandler = async (e: FormEvent) => {
        e.preventDefault();
        console.log("promo code submitting")
        setPromoCodeSubmitting(true);
        try {
            sendEvent({
                eventName: trackEvents.HIGH_IQ_PROMO_CODE_APPLY,
                payload: {
                    code: promoCodeRef?.current?.value
                }
            })
            e.stopPropagation();
            e.preventDefault();
            await handlePromoCode();
            setPromoCodeSubmitting(false);
            sendEvent({
                eventName: trackEvents.HIGH_IQ_PRMO_CODE_SUCCESS,
                payload: {
                    code: promoCodeRef?.current?.value
                }
            })
        } catch (err) {
            console.log("error = ", err)
            setPromoCodeSubmitting(false);

            sendEvent({
                eventName: trackEvents.HIGH_IQ_PRMO_CODE_FAILURE,
                payload: {
                    code: promoCodeRef?.current?.value
                }
            })
            if (promoCodeRef.current) {
                promoCodeRef.current.value = ""
            }
        }
    }

    const handlePay = async (promoCode: string) => {
        // const promoCode = promoCodeRef?.current?.value;
        if (!tc) {
            return;
        }
        setPaymentLoading(true);
        try {
            const { error: submitError }: any = await elements?.submit();
            if (submitError) {
                setPaymentError(submitError.message);
                setPaymentLoading(false);
                throw submitError;
            }

            const totalAmount = isMonthly
                ? Number((monthlyHighIqPrice + monthlySalesTax - (discountAmount || 0)).toFixed(2))
                : Number((annuallyHighIqPrice + annualSalesTax - (discountAmount || 0)).toFixed(2));

            const payload = {
                type: isMonthly ? "Monthly" : "Yearly",
                promoCode: promoCode,
                totalAmt: totalAmount,
                lm_data,
            }

            const res = await axiosInstance.post(
                `${API_URL}/subscriptions/create/sub`,
                payload
            );

            if (res?.data?.message) {
                setPaymentError(res?.data?.message);
                toast.error(res?.data?.message);
                setPaymentLoading(false);
                throw res?.data?.message;
            }
            const { clientSecret } = res?.data;

            if (res?.data?.off && stripe) {
                try {
                    stripe
                        ?.confirmSetup({
                            elements,
                            clientSecret,
                            confirmParams: {
                                return_url:
                                    "https://89d1697de324e4d41ea4b33ffea165ef.piqsuite.com",
                            },
                            redirect: "if_required",
                        })
                        .then(function (result: any) {
                            if (result.error) {
                                toast.error(result.error.message);
                                setPaymentLoading(false);
                            } else {
                                setPaymentLoading(false);
                                (window as any).fpr(
                                    "referral",
                                    { email: userData?.email },
                                    function () {
                                        (window as any).fpr("click", { cookie_ref_id: "xxx" });
                                    }
                                );
                                toast.success("Payment Successful", {
                                    duration: 2000,
                                });
                            }
                        });

                    setTimeout(() => {
                        dispatch(fetchSubscriptionData());
                    }, 5000);
                } catch (err: any) {
                    setPaymentLoading(false);
                    console.log(err);
                    throw err;
                } finally {
                    setPaymentLoading(false);
                }
                return;
            }
            const { error } = stripe ? await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: "https://89d1697de324e4d41ea4b33ffea165ef.piqsuite.com",
                },
                redirect: "if_required",
            }) : { error: null };

            if (error) {
                setPaymentError(true);
                setPromoCodeError("")
                setPaymentLoading(false);
                throw error?.message;
            } else {
                setPaymentLoading(false);

                (window as any).fpr(
                    "referral",
                    { email: userData?.email },
                    function () {
                        (window as any).fpr("click", { cookie_ref_id: "xxx" });
                    }
                );
                setTimeout(() => {
                    dispatch(fetchSubscriptionData());
                }, 5000);
            }
        } catch (error: any) {
            dispatch(check_if_token_is_expired(error?.response))
            throw error;
        }
    };

    const paymentSubmitHandler = async (e: FormEvent, promoCode: string) => {
        e.preventDefault();
        setPaymentError(false);
        sendEvent({
            eventName: trackEvents.HIGH_IQ_SUBMIT,
        })
        try {

            await handlePay(promoCode);
            afterPaymentStepHandler();
            localStorage.setItem("checkoutComplete", "true");
            sendEvent({
                eventName: trackEvents.HIGH_IQ_PAYMENT_SUCCESS,
            })
        } catch (err) {
            setPaymentLoading(false);
            console.log("error = ", err)
            sendEvent({
                eventName: trackEvents.HIGH_IQ_PAYMENT_FAIL,
                payload: {
                    reason: err

                }
            })
        }
    }

    useEffect(() => {
        if (promoCode && originalDiscountPercent > 0) {
            const basePrice = isMonthly ? monthlyHighIqPrice : annuallyHighIqPrice;
            const salesTax = isMonthly ? monthlySalesTax : annualSalesTax;
            const totalBeforeDiscount = basePrice + salesTax;
            const newDiscountAmount = (totalBeforeDiscount * originalDiscountPercent) / 100;
            setDiscountAmount(newDiscountAmount);
            setDiscountPercent(originalDiscountPercent);
        }
    }, [isMonthly, promoCode, originalDiscountPercent]);

    return {
        promoCodeSubmitHandler,
        promoCodeRef,
        promoCodeSubmitting,
        promoCodeError,
        promoCode,
        setPromoCode,
        handlePay,
        tc,
        setTc,
        paymentLoading,
        setPaymentLoading,
        paymentSubmitHandler,
        discountAmount,
        discountPercent,
        originalDiscountPercent,
        setOriginalDiscountPercent,
        isMonthly,
        setDiscountAmount
    }
};

export default useCheckout;
