import { SocketNames } from "src/interface/PiqState";

const getContent = ({
  socket,
  darkMode,
}: {
  socket: SocketNames;
  darkMode: string;
}) => {
  switch (socket) {
    case "financialjuiceeconomiccalendar":
      return getFinancialJuiceEconomicCalendarContent(darkMode);
    case "financialjuiceheadlines":
      return getFinancialJuiceHeadlines(darkMode);
    case "financialjuicetickstrikesquawk":
      return getFinancialTickStrikeSquawk(darkMode);
  }
};

export const getScriptInnerHTML = (socket: SocketNames, darkMode: string) => {
  return getContent({
    darkMode,
    socket,
  });
};

const getFinancialJuiceEconomicCalendarContent = (darkMode: string) => {
  return `
    <div id="financialjuice-eco-widget-container"  ></div>
    <script type="text/javascript">
        var jo = document.createElement("script");
        jo.type = "text/javascript";
        jo.id= "FJ-Widgets";
        var r = Math.floor(Math.random() * (9999 - 0 + 1) + 0);
        jo.src = "https://feed.financialjuice.com/widgets/widgets.js?r=" + r + "";
        jo.onload = function(){ 
        var options = {};
        options.container = "financialjuice-eco-widget-container";
        options.mode = "standard";
        options.width= "340px";
        options.height= "600px";
        options.backColor="${darkMode ? "242D38" : "ffffff"}";
        options.fontColor="${darkMode ? "b2b5be" : "1e222d"}";
        options.widgetType= "ECOCAL";
        new window.FJWidgets.createWidget(options);
            } 
        document.getElementsByTagName("head")[0].appendChild(jo);
    </script>

`;
};

const getFinancialTickStrikeSquawk = (darkMode: string) => `
        <div id="financialjuice-ts-widget-container" ></div>
        <script type="text/javascript">
            var jo = document.createElement("script");
            jo.type = "text/javascript";
            jo.id= "FJ-Widgets";
            var r = Math.floor(Math.random() * (9999 - 0 + 1) + 0);
            jo.src = "https://www.financialjuice.com/widgets/widgets.js?r=" + r + "";
            jo.onload = function(){ 
            var options = {};
            options.container = "financialjuice-ts-widget-container";
            options.mode = "undefined";
            // options.width= "215px";
            options.width="100%";
            options.height= "undefinedpx";
            options.backColor= "";
            options.fontColor= "";
            options.widgetType= "TS";
        new window.FJWidgets.createWidget(options);
                } 
            document.getElementsByTagName("head")[0].appendChild(jo);
        </script>
`;

const getFinancialJuiceHeadlines = (darkMode: string) => `
    <div id="financialjuice-news-widget-container"  ></div>
    <script type="text/javascript">
        var jo = document.createElement("script");
        jo.type = "text/javascript";
        jo.id= "FJ-Widgets";
        var r = Math.floor(Math.random() * (9999 - 0 + 1) + 0);
        jo.src = "https://feed.financialjuice.com/widgets/widgets.js?r=" + r + "";
        jo.onload = function(){ 
        var options = {};
        options.container = "financialjuice-news-widget-container";
        options.mode = "${darkMode ? "Dark" : "Light"}";
        // options.width= "500px";
        options.width= "100%";

        options.height= "1500px";
        options.backColor= "1e222d";
        options.fontColor= "b2b5be";
        options.widgetType= "NEWS";
    new window.FJWidgets.createWidget(options);
            } 
        document.getElementsByTagName("head")[0].appendChild(jo);
    </script>
`;
