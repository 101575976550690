import React, { FormEvent } from "react";
import "./index.scss";
import OtpInput from "../../OtpInput/OtpInput";
import Button from "../../Button/Button";
import BackButton from "../../Auth/BackButton";
import { usePasswordResetVerification } from "src/Hooks/auth/usePasswordVerification";
import { Link } from "react-router-dom";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";

type Props = {
  handleNextStep: () => void;
};

const PasswordResetVerification = (props: Props) => {
  const {
    otp,
    setOtp,
    error,
    setError,
    loading,
    handleSubmit,
    handleResendCode,
    email,
  } = usePasswordResetVerification(props.handleNextStep);
  const { sendEvent } = useTrackerContext();

  return (
    <div className="auth-modal-email-verification-container">
      <h1>Password Reset</h1>
      <p>
        We’ve sent a password reset code on <strong>{email}</strong>. Kindly
        check your email and enter the code below to change your password.
      </p>

      <form onSubmit={handleSubmit}>
        <h3>Enter Verification Code</h3>
        <div className="otp-input">
          <OtpInput
            onComplete={(otpval: string) => {
              setOtp(otpval);
            }}
            size="52px"
            error={!!error}
            changeHandler={(val: string) => {
              setError("");
              setOtp(val);
            }}
          />
        </div>
        {error && (
          <p className="error-text">
            Incorrect code entered. Please try again or click the link below to
            request a new code.
          </p>
        )}

        <div style={{ display: "flex", gap: 8 }}>
          <BackButton disabled={loading} />

          <Button
            text="Verify Code"
            width="119px"
            height="38px"
            weight="semibold"
            radius="md"
            disabled={otp?.length !== 6 || loading}
            loading={loading}
          />
        </div>
        {(error || otp.length !== 6) && (
          <div className="email-verification-footer">
            <span>
              Didn’t get the code?{" "}
              <button type="button" tabIndex={0} onClick={()=>handleResendCode()}>
                <strong>Resend Code</strong>
              </button>
            </span>

            <p
              style={{
                color: "#1D1D4E",
                fontFeatureSettings: '"liga" off, "clig" off',
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "150%",
                marginTop: 16,
              }}
            >
              Check your 'Spam' folder in case you can't see it. <br />
              If you are having trouble receiving your code, please{" "}
              <a
                href="mailto:support@piqsuite.com"
                style={{
                  color: "#1D1D4E",
                  fontFeatureSettings: '"liga" off, "clig" off',
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "150%",
                  textDecorationLine: "underline",
                  textDecorationStyle: "solid",
                  textDecorationSkipInk: "none",
                  textDecorationThickness: "auto",
                  textUnderlineOffset: "auto",
                  textUnderlinePosition: "from-font",
                }}
              >
                Contact support
              </a>
            </p>
          </div>
        )}
      </form>

      <div className="auth-modal-password-reset-footer">
        Remembered your password?{" "}
        <strong>
          <Link
            to="?authModal=login"
            className="link"
            onClick={() => {
              sendEvent({
                eventName: trackEvents.TAKE_ME_TO_BTN,
                payload: {
                  from: "signup",
                  to: "login",
                },
              });
            }}
          >
            Take me to Sign In
          </Link>
        </strong>
      </div>
    </div>
  );
};

export default PasswordResetVerification;
