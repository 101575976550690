import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TextInput from "../../TextInput/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useStepper } from "src/context/StepperProvider";
import PasswordValidityInfo from "../../PasswordValidityInfo/PasswordValidityInfo";
import { Link } from "react-router-dom";
import BottomAction from "../BottomAction/BottomAction";
import Button from "../../Button/Button";
import { useRegister } from "src/Hooks/auth/useNewRegister";
import ReCAPTCHA from "react-google-recaptcha";
import { addUrlParam, isValidEmailComprehensive } from "src/utils/utilFunction";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { TermsConditionPrivacyPolicy } from "../Terms&Conditions";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";
import BackButton from "../BackButton";
import { axiosInstance } from "src/store/utility";
import { message } from "antd";
import type { AxiosRequestConfig } from "axios";
import { debounce } from "lodash";
import { Auth } from "aws-amplify";

type Props = {
  setStep: SetStateAction<Dispatch<number>>;
  withCheckout: boolean;
  nextStep: () => void;
};

const RegisterForm = ({ withCheckout, nextStep }: Props) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<string>("");
  const { setShowStepper } = useStepper();
  const [promoCodeError, setPromoCodeError] = useState<string>("");
  const [promoCode, setPromoCode] = useState<string>("");
  const [isValidating, setIsValidating] = useState<boolean>(false);

  const {
    register,
    registerFormSubmitHandler,
    errors,
    registerFormData,
    submitting,
    emailExistError,
    emailChangeHandler,
    passwordChangeHandler,
    disableEmail,
    checkingEmail,
    setValue,
    setEmailExistError,
  } = useRegister({
    emailInputRef,
    withCheckout,
    promoCode,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const internalStep = parseInt(searchParams.get("internalStep") || "0", 10);

  const { sendEvent } = useTrackerContext();

  useEffect(() => {
    const storedEmail = localStorage.getItem("registerEmail");
    if (storedEmail) {
      setValue("email", storedEmail);
    }
  }, []);

  const validatePromoCode = async (promoCode: string) => {
    if (!promoCode) {
      setPromoCodeError("");
      setValue("promoCode", "");
      return;
    }

    // Cancel any existing request
    if ((window as any).promoCodeController) {
      (window as any).promoCodeController.abort();
    }

    const companyLicense = JSON.parse(
      localStorage.getItem("companyLicense") || "{}"
    );
    const customUrl = companyLicense?.company || "";

    // Create new AbortController for this request
    (window as any).promoCodeController = new AbortController();

    try {
      const config = {
        signal: (window as any).promoCodeController.signal,
      } as AxiosRequestConfig;

      const response = await axiosInstance.get(
        `/enterprise-license/validate/${promoCode}/${customUrl}`,
        config
      );

      if (response.data.success) {
        message.success(response.data.message);
        setPromoCodeError("");
        setValue("promoCode", promoCode);
      }
    } catch (error: any) {
      if (error.name === "AbortError") {
        // Request was aborted, do nothing
        return;
      }
      setPromoCodeError(
        error.response?.data?.message || "Error validating promo code"
      );
    }
  };

  // Use debounce to avoid multiple rapid API calls
  const debouncedValidatePromoCode = useMemo(
    () => debounce(validatePromoCode, 500),
    []
  );

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPromoCode(value);

    // If empty value, clear immediately
    if (!value) {
      setPromoCodeError("");
      setValue("promoCode", "");
      return;
    } else {
      debouncedValidatePromoCode(value);
    }
  };
  const handleGoogleSignup = async () => {
    try {
      // add to local storage to redirect to checkoutpage after login success
      const withCheckout = searchParams.get("with") === "checkout";
      localStorage.setItem("googleAuthTrack", "signup");

      if (withCheckout) {
        const subscriptionType = searchParams.get("subscriptionType");
        localStorage.setItem("googleAuth", "signup");
        localStorage.setItem("subscriptionType", subscriptionType!);
      }
      const data = await Auth.federatedSignIn({
        provider: "Google" as any,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const disableFormSubmission = useMemo(
    () =>
      showPasswordField
        ? recaptcha === "" ||
          submitting ||
          !!errors?.password ||
          registerFormData?.password === "" ||
          !registerFormData?.tc ||
          !registerFormData?.firstName ||
          !registerFormData?.lastName ||
          !!promoCodeError ||
          isValidating
        : !!emailExistError ||
          !!errors?.email ||
          registerFormData?.email === "" ||
          !registerFormData?.email ||
          disableEmail,
    [
      showPasswordField,
      registerFormData,
      errors,
      recaptcha,
      submitting,
      emailExistError,
      disableEmail,
    ]
  );

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    if (disableFormSubmission) return;
    if (showPasswordField) {
      sendEvent({
        eventName: trackEvents.CONTINUE_BTN,
        payload: {
          page: "signup",
          type: "register",
        },
      });
      registerFormSubmitHandler(e).then((res) => {
        const searchParams = addUrlParam("step", "2");
        navigate(`${location.pathname}?${searchParams}`);
      });
    } else {
      setShowPasswordField(true);
      const searchParams = new URLSearchParams(location.search);
      sendEvent({
        eventName: trackEvents.CONTINUE_BTN,
        payload: {
          page: "signup",
          type: "email",
        },
      });
      searchParams.set("internalStep", "1");
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  const handleInputTracking = (e: any) => {
    if (e?.target?.value === "") return;

    sendEvent({
      eventName: trackEvents.INPUT_FIELD_COMPLETE,
      payload: {
        fieldName: e?.target?.name,
        type: "register",
      },
    });
  };

  useEffect(() => {
    if (emailInputRef?.current?.value !== "") {
      if (internalStep !== 0) setShowPasswordField(true);
      else setShowPasswordField(false);
    }
  }, [internalStep, emailInputRef?.current]);

  const companyLicense = JSON.parse(
    localStorage.getItem("companyLicense") || "{}"
  );
  const showPromoCode = companyLicense?.valid;

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (emailInputRef.current) {
        emailInputRef.current.value = "";
      }
    };
  }, []);

  // Add effect to reset form when navigating
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.value = "";
    }
    setShowPasswordField(false);
    // setPromoCode("");
    setPromoCodeError("");
  }, [location.pathname]);

  return (
    <div className="register-page-container">
      <h1 className="register-page-title">Sign Up</h1>
      {/* {!showPromoCode && (
            <>
                <button className='sign-up-with-google-btn' onClick={handleGoogleSignup}>
                    <img src="/auth/google-logo.svg" alt="" />
                    Sign Up with Google</button>

                <div className='or' style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    Or
                    <div className='line' />
                </div>

                <h3 className='register-page-subtitle'>
                    Sign Up with Email
                </h3>
            </>
        )} */}

      <form action="#" className="form" onSubmit={submitHandler}>
        <TextInput
          placeholder="example@gmail.com"
          padding="11px 16px"
          radius="md"
          onFocus={() => setShowStepper(true)}
          value={registerFormData?.email}
          disabled={(showPasswordField && internalStep !== 0) || submitting}
          {...register("email")}
          onChange={emailChangeHandler}
          error={emailExistError || (errors?.email?.message as string)}
          iconString={
            errors?.email || !!emailExistError
              ? "uis:times-circle"
              : isValidEmailComprehensive(registerFormData?.email)
              ? "tabler:circle-check-filled"
              : ""
          }
          ref={emailInputRef}
          showIcon={registerFormData?.email !== ""}
          loading={checkingEmail}
          handleError={() => {
            setValue("email", "", { shouldValidate: false });
            setEmailExistError("");
            emailInputRef?.current?.focus();
          }}
          onBlur={handleInputTracking}
          onKeyDown={(e) => {
            if (
              e.keyCode === 13 &&
              !!!emailExistError &&
              !!!errors?.email?.message &&
              !checkingEmail
            ) {
              handleInputTracking(e);
            }
          }}
        />
        {emailExistError === "AlreadyExists" && (
          <span className="error">
            This email already exists. Try another email or{" "}
            <Link
              to="?authModal=login&step=1&internalStep=1"
              style={{
                fontWeight: "600",
                color: "#FF644E",
              }}
            >
              Sign In
            </Link>{" "}
            to login with this email.
          </span>
        )}
        {showPasswordField && internalStep !== 0 && (
          <>
            <div className="personal-info-container">
              <TextInput
                padding="11px 16px"
                radius="md"
                placeholder="First Name"
                value={registerFormData?.firstName}
                {...register("firstName")}
                autoFocus
                onBlur={handleInputTracking}
                error={errors?.firstName?.message as string}
              />
              <TextInput
                padding="11px 16px"
                radius="md"
                placeholder="Last Name"
                value={registerFormData?.lastName}
                {...register("lastName")}
                onBlur={handleInputTracking}
                error={errors?.lastName?.message as string}
              />
            </div>

            <TextInput
              type="password"
              value={registerFormData?.password}
              placeholder="Password"
              {...register("password")}
              padding="11px 16px"
              radius="md"
              name="password"
              onChange={passwordChangeHandler}
              handleError={() => setValue("password", "")}
              onBlur={handleInputTracking}
            />

            {showPromoCode && (
              <TextInput
                placeholder="Promo Code (Optional)"
                padding="11px 16px"
                radius="md"
                value={promoCode}
                onChange={handlePromoCodeChange}
                error={promoCodeError}
                handleError={() => {
                  setPromoCode("");
                  setPromoCodeError("");
                  setValue("promoCode", "");
                }}
              />
            )}

            <PasswordValidityInfo password={registerFormData?.password} />
            <div className="toc-container">
              <ul>
                <li>
                  <Checkbox
                    style={{ position: "relative", top: "-3px" }}
                    id="privacy-policy"
                    {...register("tc")}
                    onChange={(e: CheckboxChangeEvent) => {
                      sendEvent({
                        eventName: trackEvents.SIGNUP_TC,
                        payload: {
                          checked: e.target.checked,
                        },
                      });
                      setValue("tc", e.target.checked);
                    }}
                  />
                  <label htmlFor="privacy-policy">
                    <span>I've read the</span>
                    <a
                      href="https://piqsuite.com/terms-and-condition"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        sendEvent({
                          eventName: trackEvents.TERMS_AND_CONDITION_LINK,
                          payload: {
                            page: "signup",
                          },
                        });
                      }}
                    >
                      T&C's
                    </a>
                    &
                    <a
                      href="https://piqsuite.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        sendEvent({
                          eventName: trackEvents.PRIVACY_POLICY_LINK,
                          payload: {
                            page: "signup",
                          },
                        });
                      }}
                    >
                      Privacy Policy
                    </a>
                  </label>
                </li>
                <li>
                  <Checkbox
                    id="email-me"
                    {...register("piqNu")}
                    onChange={(e: CheckboxChangeEvent) => {
                      sendEvent({
                        eventName: trackEvents.SIGNUP_EMAIL_UPDATES,
                        payload: {
                          checked: e.target.checked,
                        },
                      });
                      setValue("piqNu", e.target.checked);
                    }}
                    style={{ position: "relative", top: "-3px" }}
                  />
                  <label htmlFor="email-me">Email me PiQ news & updates</label>
                </li>
              </ul>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdBRpgqAAAAACFs-iHTpr-3-7xoW6602cT-kb11"
              onChange={(val) => {
                sendEvent({
                  eventName: trackEvents.SIGN_UP_CAPTCHA,
                  payload: {
                    success: val ? true : false,
                  },
                });
                setRecaptcha(val!);
              }}
            />
          </>
        )}

        <div style={{ display: "flex", gap: 8 }}>
          {showPasswordField && <BackButton disabled={submitting} />}
          <Button
            text="Continue"
            type="primary"
            disabled={disableFormSubmission}
            loading={submitting}
            height="38px"
            width="102px"
            weight="bold"
            radius="md"
          />
        </div>
      </form>
      <BottomAction />
    </div>
  );
};

export default RegisterForm;
