import React from "react";
import styles from "./SuccessComponent.module.scss";
import Button from "../../Button/Button";
import BackButton from "../BackButton";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";

type Props = {
  nextHandler: () => void;
};

const EmailVerified = ({ nextHandler }: Props) => {

  const { sendEvent } = useTrackerContext();

  const continueHandler = () => {

    sendEvent({
      eventName: trackEvents.CONTINUE_BTN,
      payload: {
        page: "email verified"
      }
    })
    nextHandler();
  }


  return (
    <div className={styles.successfulComponentWrapper}>
      <div className={styles.image}>
        <img src="/images/confetti.svg" alt="" />
      </div>
      <div className={styles.main}>
        <h1>Email Verified!</h1>
        <p>
          Wohoo! Your email has been successfully verified. Hit{" "}
          <strong>‘Continue’ </strong> to sign in to your PiQ account.
        </p>
        <div
          style={{
            width: "fit-content",
            marginInline: "auto",
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          {/* <BackButton /> */}
          <Button
            onClick={continueHandler}
            text="Continue"
            type="primary"
            width="102px"
            weight="semibold"
            height="38px"
            padding="8.5px 18px"
          />
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
