import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import "./index.scss";
import OtpInput from "../../OtpInput/OtpInput";
import Button from "../../Button/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import BackButton from "../../Auth/BackButton";
import { useQueryParams } from "../utils";
import { Auth } from "aws-amplify";
import { updateAxiosInstance } from "src/store/utility";
import moment from "moment";
import { useDispatch } from "react-redux";
import useAuthCommon from "src/Hooks/auth/useAuthCommon";

type Props = {
  nextStepHandler: (otp: string) => void;
  resendOtpHandler: () => void;
  submitting: boolean;
  error?: boolean;
  setError?: Dispatch<SetStateAction<boolean>>;
  email: string;
  sendOtpByDefault?: boolean;
  onUnmount?: any;
  setSubmitting?: any;
};

const EmailVerification = ({
  nextStepHandler,
  resendOtpHandler,
  submitting,
  error,
  setError,
  email,
  sendOtpByDefault,
  onUnmount,
  setSubmitting,
}: Props) => {
  const { setQueryParams, deleteQueryParam } = useQueryParams();
  const [otp, setOtp] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const withCheckout = searchParams.get("with") === "checkout";

  // const handleAutoLogin = async () => {
  //   try {
  //     setSubmitting(true);
  //     const password = localStorage.getItem("tempPassword"); // Store password temporarily during verification
  //     if (!password) return;

  //     const user = await Auth.signIn(email?.toLowerCase(), password);
  //     const authToken = user.signInUserSession.accessToken.jwtToken;

  //     updateAxiosInstance(authToken);
  //     localStorage.setItem(
  //       "tokenExpirationDate",
  //       moment().add(1, "days").toISOString()
  //     );
  //     setSubmitting(false);
  //     // Clear temporary data
  //     localStorage.removeItem("tempPassword");
  //     localStorage.removeItem("loginEmail");

  //     // Navigate to home
  //     localStorage.setItem("loggedIn", "true");
  //     deleteQueryParam("authModal");
  //     deleteQueryParam("step");
  //     navigate("/");
  //   } catch (error) {
  //     console.error("Auto login failed:", error);
  //   }
  // };

  const { handleAutoLogin } = useAuthCommon({
    email,
    logging: submitting,
    setLogging: setSubmitting,
  });

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const response = await nextStepHandler(otp);
      setSubmitting(false);
      return response;
    } catch (error) {
      console.error("Verification or auto-login failed:", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (email && sendOtpByDefault) {
      resendOtpHandler();
    }
  }, [email]);

  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, []);

  const { sendEvent } = useTrackerContext();
  const isRegisterPage = location.pathname === "/register";

  return (
    <div className="auth-modal-email-verification-container">
      <h1>Email Verification</h1>
      <p>
        You're almost there! We have sent a verification code to <br />
        <strong>{email}</strong>
      </p>

      <form onSubmit={submitHandler}>
        <h3>Enter Verification Code</h3>
        <div className="otp-input">
          <OtpInput
            onComplete={(otpval: string) => {
              // setOtp(otpval)
              sendEvent({
                eventName: isRegisterPage
                  ? trackEvents.SIGNUP_EMAIL_VERIFICATION_CODE
                  : trackEvents.SIGNIN_EMAIL_VERIFICATION_CODE,
                payload: {
                  code: otpval,
                },
              });
            }}
            size="52px"
            error={error}
            setError={setError}
            changeHandler={(otpval: string) => {
              if (setError) setError(false);
              setOtp(otpval);
            }}
          />
        </div>
        {error && (
          <p className="error-text">
            Incorrect code entered. Please try again or click the link below to
            request a new code.
          </p>
        )}

        <div style={{ display: "flex", gap: 8 }}>
          <BackButton disabled={submitting} />

          <Button
            text="Verify Code"
            width="119px"
            height="38px"
            weight="semibold"
            disabled={otp?.length !== 6 || submitting}
            loading={submitting}
          />
        </div>
        {(error || otp.length !== 6) && (
          <div className="email-verification-footer">
            <span>
              Didn't get the code?{" "}
              <strong
                onClick={() => {
                  sendEvent({
                    eventName: isRegisterPage
                      ? trackEvents.SIGNUP_EMAIL_VERIFICATION_RESEND_BTN
                      : trackEvents.SIGNIN_EMAIL_VERIFICATION_RESEND_BTN,
                  });
                  resendOtpHandler();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default action
                    resendOtpHandler(); // Trigger the click event logic
                    sendEvent({
                      eventName: isRegisterPage
                        ? trackEvents.SIGNUP_EMAIL_VERIFICATION_RESEND_BTN
                        : trackEvents.SIGNIN_EMAIL_VERIFICATION_RESEND_BTN,
                    });
                  }
                }}
                tabIndex={0}
              >
                Resend Code
              </strong>
            </span>

            <p
              style={{
                color: "#1D1D4E",
                fontFeatureSettings: '"liga" off, "clig" off',
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "150%",
                marginTop: 16,
              }}
            >
              Check your 'Spam' folder in case you can't see it.
              <br />
              If you are having trouble receiving your code, please{" "}
              <a
                href="mailto:support@piqsuite.com"
                style={{
                  color: "#1D1D4E",
                  fontFeatureSettings: '"liga" off, "clig" off',
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "150%",
                  textDecorationLine: "underline",
                  textDecorationStyle: "solid",
                  textDecorationSkipInk: "none",
                  textDecorationThickness: "auto",
                  textUnderlineOffset: "auto",
                  textUnderlinePosition: "from-font",
                }}
              >
                Contact support
              </a>
            </p>
          </div>
        )}
      </form>
    </div>
  );
};

export default EmailVerification;
