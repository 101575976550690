import React from "react";
import './ChangePassword.scss';
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import PasswordValidityInfo from "../../PasswordValidityInfo/PasswordValidityInfo";
import { useChangePassword } from "src/Hooks/auth/useNewForgotPassword";
import BackButton from "../BackButton";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";

type Props = {
    onBack?: () => void;
};

export const ChangePassword = (props: Props) => {
    const { handleSubmit, onSubmit, watch, register, errors, isSubmitting, setValue } = useChangePassword();


    const { sendEvent } = useTrackerContext();

    return (
        <div className="change-password-container">
            <h1>Change Password</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    type="password"
                    placeholder="New Password"
                    height="45px"
                    padding="11px 16px"
                    value={watch("password")}
                    {...register("password")}
                    error={errors.password?.message}
                    handleError={() => setValue("password", '')}
                    onBlur={() => {
                        sendEvent({
                            eventName: trackEvents.CHANGE_PASSWORD_NEW_PWD
                        })
                    }}
                />
                <TextInput
                    type="password"
                    placeholder="Confirm Password"
                    height="45px"
                    padding="11px 16px"
                    value={watch("confirmPassword")}
                    {...register("confirmPassword")}
                    error={errors.confirmPassword?.message}
                    handleError={() => setValue("confirmPassword", '')}
                    onBlur={() => {
                        sendEvent({
                            eventName: trackEvents.CHANGE_PASSWORD_CONFIRM_PWD
                        })
                    }}

                />
                <PasswordValidityInfo password={watch("password")} />
                <div style={{ display: "flex", gap: 8 }}>
                    <BackButton onBack={props.onBack} disabled={isSubmitting} />
                    <Button
                        text={isSubmitting ? "Changing..." : "Continue"}
                        type="primary"
                        width="102px"
                        height="38px"
                        weight="semibold"
                        disabled={isSubmitting}
                    />
                </div>
            </form>
        </div>
    );
};
