import React, { useEffect, useState } from 'react'
import Checkout from '../../Auth/Checkout/Checkout';
import PaymentSuccessful from '../../Auth/SuccessComponent/PaymentSuccessful';
import { useStepper } from 'src/context/StepperProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { popMessage } from '../../message/message';

type Props = {}

const GoogleAuthCheckoutPage = (props: Props) => {

    const [checkoutSuccess, setCheckoutSuccess] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const { setShowStepper, setSteps } = useStepper();

    const navigate = useNavigate();

    useEffect(() => {
        const googleAuth = localStorage.getItem("googleAuth");
        if (googleAuth === "signup") {
            setSteps(["Register", "Verify Email", "Checkout", "Launch Suite"]);
            searchParams.set('step', '3');
        }

        if (googleAuth === "signin") {
            setSteps(["Sign in", "Checkout", "Launch Suite"]);
            searchParams.set('step', '2');
        }
        setShowStepper(true);
        setSearchParams(searchParams);
    }, []);

    return (
        <div>
            {
                !checkoutSuccess ? <Checkout
                    nextStepHandler={() => {
                        setCheckoutSuccess(true);
                        searchParams.set('step', '4');
                        setSearchParams(searchParams);
                        popMessage.successMessage("Thanks for registering")
                    }}
                /> : <PaymentSuccessful
                />
            }
        </div>
    )
}

export default GoogleAuthCheckoutPage;