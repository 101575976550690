export const disabledAppForLoggedOutUser = [
  "fmoccut75bps",
  "fmoccut50bps",
  "fmoccut25bps",
  "fmocunchanged",
  "fmochike25bps",
];

export const disabledComponentTypeForLoggedOutUser = [
  "PIQ_CUSTOM_FEED_COMPONENT",
];
