import React from "react";
import "./index.scss";

export default function Modal({
  children,
  className,
  isOpen,
  onClose,
}: {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <>
      <div className={`auth-modal-overlay ${isOpen ? "opened" : "closed"}`} />
      <div
        className={`auth-modal-container ${className || ""} ${
          isOpen ? "opened" : "closed"
        }`}
      >
        <img
          src="/images/auth-modal.webp"
          alt="auth-modal"
          className="auth-modal-image"
        />

        <div>
          <button className="auth-modal-close-button" onClick={onClose}>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30 18L18 30"
                stroke="#17181C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 18L30 30"
                stroke="#17181C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </>
  );
}
