import React from "react";
import { decodeTitle } from "src/utils/utilFunction";

import useFeeds from "../../../Hooks/useFeeds";
import { SocketNames } from "src/interface/PiqState";

const ContentComponent: React.FC<{
  item: any;
  socket: SocketNames;
}> = ({ item, socket }) => {
  const { handleShowDetials } = useFeeds();
  const number =
    socket === "newsquawk" || socket === "newsquawk-delay" ? 250 : 150;
  const decodedContent: string = decodeTitle(
    decodeTitle(item?.content || item?.full_content)
  );

  return (
    <>
      {item?.content && (
        <div
          className="news-feeds-description"
          onClick={() => {
            if (socket === "newsquawk" || socket === "newsquawk-delay") {
              if (decodedContent?.length > number) {
                handleShowDetials(item, socket);
              } else {
                return;
              }
            } else {
              handleShowDetials(item, socket);
            }
          }}
        >
          {decodeTitle(decodeTitle(item?.content)).slice(0, number) +
            (decodeTitle(decodeTitle(item?.content))?.length > number
              ? "..."
              : "")}
        </div>
      )}
    </>
  );
};

export default ContentComponent;
