import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import shareIcon from "src/style/images/x_icon.svg";
import copyIcon from "src/style/images/copy_icon.svg";
import bookmarkFilledDark from 'src/style/images/bookmark_filled_dark.svg'
import bookmarkOutline from 'src/style/images/bookmark_outline.svg'
import bookmarkDarkOutline from 'src/style/images/bookmark_dark_outline.svg'


import { TwitterShareButton } from "react-share";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useBookmarkContext } from "src/context/BookmarkProvider";
import CustomTwitterShareButton from "../CustomTwitterShareButton/CustomTwitterShareButton";

interface SocialMediaShareProps {
  bookmarkClickHandler: (e: any) => void;
  data: any;
  showShareIcon: boolean;
  url: string;
  onCopyLink: () => void;
  article_id: string;
}

export const SocialMediaShare = (props: SocialMediaShareProps) => {

  const { bookmarkClickHandler } = props;
  const { isBookmarked, bookmarks } = useBookmarkContext()
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));

  const {
    windowSize,
  } = useSelector((state: any) => ({
    windowSize: state?.PIQReducer?.windowSize,

  }));

  const bookmarked = useMemo(() => isBookmarked(props?.article_id), [bookmarks, props?.article_id])

  return (
    <Fragment>
      <div
        // hidden={!props?.showShareIcon}
        className={`social_share_container ${darkMode ? "dark_social_share_container" : ""}`}
        style={{
          visibility: !props?.showShareIcon && !(windowSize?.[0] <= 700) ? "hidden" : "visible",
          // display: windowSize?.[0] <= 700 ? "inline" : "flex",
        }}
      >
        <Tooltip title="Share on X" placement="top" color={"#66dbc4"}>
          <CustomTwitterShareButton
            url={props.url}
            title={props.data}
          >
            <img src={shareIcon} alt="PiQSuite.Com"
              style={{ cursor: "pointer", filter: darkMode ? "invert(1)" : "invert(0)" }}
            />
          </CustomTwitterShareButton>
        </Tooltip>
        <Tooltip title="Copy Link " placement="top" color={"#66dbc4"}>
          <span
            onClick={() => {
              props?.onCopyLink();
            }}
          >
            <img
              src={copyIcon}
              style={{ cursor: "pointer", filter: darkMode ? "invert(1)" : "invert(0)" }}

              alt="PiQSuite.Com"
            />
          </span>
        </Tooltip>
        <Tooltip title="Bookmark" placement="top" color={"#66dbc4"}>
          <span
            className="social_bookmark_container"
            onClick={bookmarkClickHandler}
          >
            {
              bookmarked ? <img
                src={darkMode ? bookmarkFilledDark : bookmarkFilledDark}
                alt="PiQSuite.Com"
              /> : <img
                src={darkMode ? bookmarkDarkOutline : bookmarkOutline}
                alt="PiQSuite.Com"
              />
            }
          </span>
        </Tooltip>

      </div>
    </Fragment >
  );
};
