import { SocketNames } from "../interface/PiqState";
import { useDispatch } from "react-redux";
import { useAuthContext } from "src/context/AuthProvider";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchFeeddata } from "src/store/PIQ/action";
import { feedAxiosInstance } from "src/store/utility";
import { useTrackerContext } from "src/context/TrackerProvider";
import { message } from "antd";
import {
  defaultColumnLayoutConfigs,
  generateCoordinates,
} from "src/SuiteConfig/columnConfiguration";
import { updateLocalStorageSuiteLayout } from "src/utils/common";
import { getpiqvideofeedBySlug } from "src/store/piqtv/action";
import { premiumDataDetails } from "src/development/Component/AppSelector/premiumData";
import { updatePremiumAppData } from "src/store/PremiumApps/premiumAppsSlice";
import { useLayoutContext } from "src/context/LayoutProvider";
import { useNavigate } from "react-router-dom";
import {
  disabledAppForLoggedOutUser,
  disabledComponentTypeForLoggedOutUser,
} from "src/constants/common";

const getFeedSharingFetchApi = (feed: any) => {
  switch (feed?.componentType) {
    case "TWITTER_FEED_COMPONENT":
      return "tweet";

    case "PIQ_CUSTOM_FEED_COMPONENT":
      return "piq-custom-feeds";

    default:
      return "api";
  }
};

const useFeedSharing = () => {
  const dispatch = useDispatch();
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const [sharedFeedData, setSharedFeedData] = useState<any>({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [isVideoShare, setIsVideoShare] = useState(false);
  const [sharedData, setSharedData] = useState([]);
  const { setActiveDrawer, activeDrawerState } = useLayoutContext();
  const { hasSubscriptionForAdsRemoval } = useSelector((state: any) => ({
    hasSubscriptionForAdsRemoval: state.subscription?.data?.active || false,
  }));
  const { hasNewsQuawkSubscription, hasHammerstoneSubscription }: any =
    useSelector((state: any) => state.premiumapps);
  const navigate = useNavigate();

  const subscriptionData = useSelector((state: any) => state.subscription);
  const { user_data } = useSelector((state: any) => {
    return {
      user_data: state?.user_data?.data,
    };
  });
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  const Columns = useSelector((state: any) => {
    return state?.piqcolumns?.columns;
  });
  const { piqSuiteLayout, setPiqSuiteLayout } = useLayoutContext();

  useEffect(() => {
    const fetchFeedShare = async () => {
      if (window.location.href.split("/")[3]) {
        const feed = window.location.href.split("/")[3];
        if (feed === "feed") {
          const feedData = window.location.href.split("/")[4];
          if (feedData) {
            const sharedData = Columns.find(
              (item: any) => item.slug === feedData
            );
            const api = getFeedSharingFetchApi(sharedData);
            // sharedData?.appSelectorTypes === "TWITTER_FEED" ? "tweet" : "api";
            if (sharedData) {
              setSharedFeedData(sharedData);
              setDeleteModal(true);
              if (sharedData?.appSelectorTypes === "TUTORIAL_FEED") return;
              const params = { page: 1, limit: 50 };
              feedAxiosInstance
                .get(`/${api}/${sharedData?.slug}?search=&page=1&limit=50`, {
                  params,
                })
                .then((res) => {
                  let data = res?.data?.data?.filter(
                    (item: any) => !item?.isAd
                  );
                  setSharedData(data);
                  setIsVideoShare(false);
                })
                .catch((err) => console.log(err));
            } else {
              getpiqvideofeedBySlug(feedData)
                .then((res) => {
                  //   setData(res.data);
                  setDeleteModal(true);
                  setSharedFeedData({
                    ...res.data,
                    componentType: "VIDEO_FEED_COMPONENT",
                  });
                  setIsVideoShare(true);
                })
                .catch((err: any) => {
                  setIsVideoShare(false);
                });
            }
          }
        }
      } else {
      }
    };
    if (Columns.length !== 0) {
      fetchFeedShare();
    }
  }, [activeDrawerState, Columns]);

  const handleDismiss = () => {
    setDeleteModal(false);
    setIsVideoShare(false);
    // window.history.replaceState(null, "", "/");
    navigate("/");
  };

  const handleAddDashboard = (col: string, colType: string) => {
    if (col === "") return;
    const videoFeed = piqSuiteLayout?.filter(
      (item: any) => item?.type === "VIDEO_FEED_COMPONENT"
    );
    const feedData = piqSuiteLayout?.find((item: any) => {
      return item?.i === col;
    });

    if (feedData) {
      if (feedData.type === "VIDEO_FEED_COMPONENT") {
        message.success("TV player already added to the Suite!");
        setDeleteModal(false);
        setIsVideoShare(false);
        // window.history.replaceState(null, "", "/");
        navigate("/");
        return;
      }
    }

    if (!isUserLoggedIn) {
      // User is not logged in
      if (isVideoShare || disabledAppForLoggedOutUser.includes(col)) {
        setDeleteModal(false);
        setIsVideoShare(false);
        let generatedCoordinates = generateCoordinates(
          col,
          colType,
          piqSuiteLayout
        );
        var colConfigs = defaultColumnLayoutConfigs.find(
          (x: any) => x.i === col
        );
        generatedCoordinates = {
          ...generatedCoordinates,
          w: colConfigs?.w,
          h: colConfigs?.h,
        };
        localStorage.setItem("feedToAdd", JSON.stringify(generatedCoordinates));
        // window.history.replaceState(null, "", "/");
        navigate("/?authModal=login&step=1&internalStep=1");
        return;
      } else if (
        col === "newsquawk" ||
        col === "hammerstone" ||
        disabledComponentTypeForLoggedOutUser.includes(colType)
      ) {
        setDeleteModal(false);
        setIsVideoShare(false);
        // window.history.replaceState(null, "", "/");
        navigate("/?authModal=login&step=1&internalStep=1");
        return;
      }
    } else {
      if (col === "newsquawk" && !hasNewsQuawkSubscription) {
        const updatedPremiumData: any = premiumDataDetails.map((data: any) =>
          data.title === "Newsquawk" ? { ...data, checked: true } : data
        );
        dispatch(updatePremiumAppData(updatedPremiumData));
        setActiveDrawer("upgrade-premium-app");
        setDeleteModal(false);
        setIsVideoShare(false);
        // window.history.replaceState(null, "", "/");
        navigate("/");
        return;
      }
      if (col === "hammerstone" && !hasHammerstoneSubscription) {
        const updatedPremiumData: any = premiumDataDetails.map((data: any) =>
          data.title === "Hammerstone" ? { ...data, checked: true } : data
        );
        dispatch(updatePremiumAppData(updatedPremiumData));
        setActiveDrawer("upgrade-premium-app");
        setDeleteModal(false);
        setIsVideoShare(false);
        // window.history.replaceState(null, "", "/");
        navigate("/");
        return;
      }
    }
    const podcastFeed = piqSuiteLayout?.filter(
      (item: any) => item?.type === "PODCAST_COMPONENT"
    );

    const newsFeed = piqSuiteLayout?.filter((item: any) => {
      return (
        item?.type === "NEWS_FEED_COMPONENT" ||
        item?.type === "PREMIUM_FEED_COMPONENT"
      );
    });

    const twitterFeed = piqSuiteLayout?.filter((item: any) => {
      return item?.type === "TWITTER_FEED_COMPONENT";
    });

    const tutorialFeed = piqSuiteLayout?.filter((item: any) => {
      return item?.type === "TUTORIAL_FEED";
    });

    const chartFeed = piqSuiteLayout?.filter((item: any) => {
      return item?.type === "CHART_FEED_COMPONENT";
    });

    if (colType === "TWITTER_FEED_COMPONENT") {
      // if (!isUserLoggedIn) {
      //   message.warning("Feed limit reached");
      //   setActiveDrawer("upgrade-panel-drawer");
      //   return;
      // }
      if (!isUserLoggedIn && twitterFeed?.length >= 1) {
        message.warning("Feed limit reached");
        navigate("?authModal=login&step=1&internalStep=1");
        return;
      }
      if (
        (hasSubscriptionForAdsRemoval || subscriptionData?.data?.active) &&
        twitterFeed?.length >= 7
      ) {
        message.warning("Maximum feed limit reached");
        return;
      }
      if (
        (!hasSubscriptionForAdsRemoval || !subscriptionData?.data?.active) &&
        twitterFeed?.length >= 3
      ) {
        message.warning("X Feed limit reached. Upgrade to add more", 5);
        setActiveDrawer("account-settings-drawer");
        return;
      }
    }

    if (
      colType === "NEWS_FEED_COMPONENT" ||
      colType === "PREMIUM_FEED_COMPONENT"
    ) {
      if (!isUserLoggedIn && newsFeed?.length >= 6) {
        navigate("?authModal=login&step=1&internalStep=1");
        message.warning("Feed limit reached");
        return;
      } else {
        if (
          (hasSubscriptionForAdsRemoval || subscriptionData?.data?.active) &&
          newsFeed?.length >= 20
        ) {
          message.warning("Maximum feed limit reached");
          return;
        }
        if (
          (!hasSubscriptionForAdsRemoval || !subscriptionData?.data?.active) &&
          newsFeed?.length >= 10
        ) {
          message.warning("Feed limit reached. Upgrade to add more", 5);
          setActiveDrawer("account-settings-drawer");
          return;
        }
        // if (newsFeed?.length >= 6 || ) {
        //   (setActiveDrawer("account-settings-drawer"))
        //   return
        // }
      }
    }
    if (colType === "VIDEO_FEED_COMPONENT") {
      if (!isUserLoggedIn && videoFeed?.length >= 1) {
        message.warning("Feed limit reached");
        navigate("?authModal=login&step=1&internalStep=1");
        return;
      }
      if (
        isUserLoggedIn &&
        !hasSubscriptionForAdsRemoval &&
        videoFeed?.length >= 2
      ) {
        message.warning("Feed limit reached. Upgrade to add more");
        setActiveDrawer("account-settings-drawer");
        return;
      }
      if (
        isUserLoggedIn &&
        hasSubscriptionForAdsRemoval &&
        videoFeed?.length >= 4
      ) {
        message.warning("Maximum feed limit reached");
        return;
      }
    }

    // for later if chart limit is to be added

    if (colType === "CHART_FEED_COMPONENT") {
      if (!isUserLoggedIn && chartFeed?.length >= 1) {
        message.warning("Chart limit reached");
        navigate("?authModal=login&step=1&internalStep=1");
        return;
      }
      if (
        isUserLoggedIn &&
        !hasSubscriptionForAdsRemoval &&
        chartFeed?.length >= 5
      ) {
        message.warning("Chart limit reached. Upgrade to add more");
        setActiveDrawer("account-settings-drawer");
        return;
      }
      if (
        isUserLoggedIn &&
        hasSubscriptionForAdsRemoval &&
        chartFeed?.length >= 10
      ) {
        message.warning("Maximum Chart limit reached");
        return;
      }
    }

    let generatedCoordinates = generateCoordinates(
      col,
      colType,
      piqSuiteLayout
    );

    if (
      colType === "NEWS_FEED_COMPONENT" ||
      colType === "TWITTER_FEED_COMPONENT" ||
      colType === "APP_FEED_COMPONENT" ||
      colType === "CUSTOM_FEED_COMPONENT" ||
      colType === "TUTORIAL_COMPONENT"
    ) {
      var colConfigs = defaultColumnLayoutConfigs.find((x: any) => x.i === col);
      if (!colConfigs) {
        colConfigs = defaultColumnLayoutConfigs.find(
          (x: any) => x.i === "NEWS_FEED_DEFAULT"
        );
      }
      generatedCoordinates = {
        ...generatedCoordinates,
        w: colConfigs?.w,
        h: colConfigs?.h,
      };
    } else if (
      colType === "CHART_FEED_COMPONENT" ||
      colType === "PODCAST_COMPONENT"
    ) {
      generatedCoordinates = { ...generatedCoordinates };
    } else {
      generatedCoordinates = { ...generatedCoordinates, w: 2, h: 3 };
    }
    const updateSuiteLayout = [...piqSuiteLayout, generatedCoordinates];

    if (feedData) {
      message.success("Feed already added to the Suite!");
      setDeleteModal(false);
      setIsVideoShare(false);
      // window.history.replaceState(null, "", "/");
      navigate("/");
      return;
    }

    setPiqSuiteLayout(updateSuiteLayout);
    updateLocalStorageSuiteLayout(
      updateSuiteLayout?.filter((item: any) => item?.i !== ""),
      user_data !== null,
      user_data?.email,
      darkMode
    );
    setDeleteModal(false);
    setIsVideoShare(false);
    // window.history.replaceState(null, "", "/");
    navigate("/");
    message.success("Feed added to the Suite successfully!");
  };
  const checkAddDashboard = (
    piqSuiteLayout: any,
    col: string,
    colType: string
  ) => {
    if (col === "") return false;
    const videoFeed = piqSuiteLayout?.filter(
      (item: any) => item?.type === "VIDEO_FEED_COMPONENT"
    );
    const feedData = piqSuiteLayout?.find((item: any) => {
      return item?.i === col;
    });

    if (feedData) {
      if (feedData.type === "VIDEO_FEED_COMPONENT") {
        message.success("TV player already added to the Suite!");
        return false;
      }
    }

    if (!isUserLoggedIn) {
      if (isVideoShare || disabledAppForLoggedOutUser.includes(col)) {
        return false;
      } else if (col === "newsquawk" || col === "hammerstone") {
        return false;
      }
    } else {
      if (col === "newsquawk" && !hasNewsQuawkSubscription) {
        const updatedPremiumData: any = premiumDataDetails.map((data: any) =>
          data.title === "Newsquawk" ? { ...data, checked: true } : data
        );
        dispatch(updatePremiumAppData(updatedPremiumData));
        setActiveDrawer("upgrade-premium-app");
        return false;
      }
      if (col === "hammerstone" && !hasHammerstoneSubscription) {
        const updatedPremiumData: any = premiumDataDetails.map((data: any) =>
          data.title === "Hammerstone" ? { ...data, checked: true } : data
        );
        dispatch(updatePremiumAppData(updatedPremiumData));
        setActiveDrawer("upgrade-premium-app");
        return false;
      }
    }
    const podcastFeed = piqSuiteLayout?.filter(
      (item: any) => item?.type === "PODCAST_COMPONENT"
    );

    const newsFeed = piqSuiteLayout?.filter((item: any) => {
      return (
        item?.type === "NEWS_FEED_COMPONENT" ||
        item?.type === "PREMIUM_FEED_COMPONENT"
      );
    });

    const twitterFeed = piqSuiteLayout?.filter((item: any) => {
      return item?.type === "TWITTER_FEED_COMPONENT";
    });

    const tutorialFeed = piqSuiteLayout?.filter((item: any) => {
      return item?.type === "TUTORIAL_FEED";
    });

    const chartFeed = piqSuiteLayout?.filter((item: any) => {
      return item?.type === "CHART_FEED_COMPONENT";
    });

    if (colType === "TWITTER_FEED_COMPONENT") {
      // if (!isUserLoggedIn) {
      //   message.warning("Feed limit reached");
      //   setActiveDrawer("upgrade-panel-drawer");
      //   return;
      // }
      if (!isUserLoggedIn && twitterFeed?.length >= 1) {
        message.warning("Feed limit reached");
        return false;
      }
      if (
        (hasSubscriptionForAdsRemoval || subscriptionData?.data?.active) &&
        twitterFeed?.length >= 7
      ) {
        message.warning("Maximum feed limit reached");
        return false;
      }
      if (
        (!hasSubscriptionForAdsRemoval || !subscriptionData?.data?.active) &&
        twitterFeed?.length >= 3
      ) {
        message.warning("X Feed limit reached. Upgrade to add more", 5);
        setActiveDrawer("account-settings-drawer");
        return false;
      }
    }

    if (
      colType === "NEWS_FEED_COMPONENT" ||
      colType === "PREMIUM_FEED_COMPONENT"
    ) {
      if (!isUserLoggedIn && newsFeed?.length >= 6) {
        message.warning("Feed limit reached");
        return false;
      } else {
        if (
          (hasSubscriptionForAdsRemoval || subscriptionData?.data?.active) &&
          newsFeed?.length >= 20
        ) {
          message.warning("Maximum feed limit reached");
          return false;
        }
        if (
          (!hasSubscriptionForAdsRemoval || !subscriptionData?.data?.active) &&
          newsFeed?.length >= 10
        ) {
          message.warning("Feed limit reached. Upgrade to add more", 5);
          setActiveDrawer("account-settings-drawer");
          return false;
        }
        // if (newsFeed?.length >= 6 || ) {
        //   (setActiveDrawer("account-settings-drawer"))
        //   return
        // }
      }
    }
    if (colType === "VIDEO_FEED_COMPONENT") {
      if (!isUserLoggedIn && videoFeed?.length >= 1) {
        message.warning("Feed limit reached");
        return false;
      }
      if (
        isUserLoggedIn &&
        !hasSubscriptionForAdsRemoval &&
        videoFeed?.length >= 2
      ) {
        message.warning("Feed limit reached. Upgrade to add more");
        setActiveDrawer("account-settings-drawer");
        return false;
      }
      if (
        isUserLoggedIn &&
        hasSubscriptionForAdsRemoval &&
        videoFeed?.length >= 4
      ) {
        message.warning("Maximum feed limit reached");
        return false;
      }
    }

    // for later if chart limit is to be added

    if (colType === "CHART_FEED_COMPONENT") {
      if (!isUserLoggedIn && chartFeed?.length >= 1) {
        message.warning("Chart limit reached");
        return false;
      }
      if (
        isUserLoggedIn &&
        !hasSubscriptionForAdsRemoval &&
        chartFeed?.length >= 5
      ) {
        message.warning("Chart limit reached. Upgrade to add more");
        setActiveDrawer("account-settings-drawer");
        return false;
      }
      if (
        isUserLoggedIn &&
        hasSubscriptionForAdsRemoval &&
        chartFeed?.length >= 10
      ) {
        message.warning("Maximum Chart limit reached");
        return false;
      }
    }

    if (feedData) {
      message.success("Feed already added to the Suite!");
      setDeleteModal(false);
      setIsVideoShare(false);
      // window.history.replaceState(null, "", "/");
      navigate("/");
      return false;
    }
    message.success("Feed added to the Suite successfully!");
    return true;
  };

  return {
    Columns,
    sharedFeedData,
    deleteModal,
    handleDismiss,
    sharedData,
    handleAddDashboard,
    isVideoShare,
    setDeleteModal,
    checkAddDashboard,
  };
};

export default useFeedSharing;
