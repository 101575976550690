import { zodResolver } from "@hookform/resolvers/zod";
import { Auth } from "aws-amplify";
import axios from "axios";
import { debounce } from "lodash";
import {
  ChangeEvent,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  registerFormSchema,
  registerSteps,
  registerWithCheckoutSteps,
  EMAIL_REGEX,
} from "src/constants/auth";
import { useStepper } from "src/context/StepperProvider";
import { popMessage } from "src/development/Component/message/message";
import plausible from "src/plausible";
import { loginSocketEmit } from "src/Socket";
import { fetchSubscriptionData } from "src/store/Subscriptions/subscriptionSlice";
import { fetch_user_details } from "src/store/userStore";
import {
  axiosInstance,
  remove_auth_headers,
  updateAxiosInstance,
} from "src/store/utility";
import { isValidEmailComprehensive } from "src/utils/utilFunction";
import { useLocation } from "react-router-dom";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";

const emailExistsErrorMsg = "AlreadyExists";
export const useRegister = ({
  emailInputRef,
  withCheckout,
  promoCode,
}: {
  emailInputRef: RefObject<HTMLInputElement>;
  withCheckout: boolean;
  promoCode: string;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [emailExistError, setEmailExistError] = useState<string>("");
  const [disableEmail, setDisableEmail] = useState<boolean>(true);
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false);

  //   const { setSteps } = useStepper();
  const location = useLocation();

  const { sendEvent } = useTrackerContext();

  const {
    handleSubmit,
    register,
    control: registerControl,
    formState: { errors },
    setValue,
    getValues: getRegisterFormValues,
    watch: watchRegisterForm,
    clearErrors,
    trigger,
  } = useForm({
    resolver: zodResolver(registerFormSchema),
  });

  const dispatch = useDispatch();

  const registerFormData = watchRegisterForm();

  // Initialize steps for the stepper
  //   useEffect(() => {
  //     setSteps(withCheckout ? registerWithCheckoutSteps : registerSteps);
  //   }, [setSteps]);

  // Add cleanup effect to reset form when component unmounts
  useEffect(() => {
    return () => {
      setValue("email", "");
      setEmailExistError("");
      setDisableEmail(true);
      setCheckingEmail(false);
    };
  }, []);

  // Add effect to reset email when location changes
  useEffect(() => {
    setValue("email", "");
    setEmailExistError("");
    setDisableEmail(true);
    setCheckingEmail(false);
    localStorage.removeItem("registerEmail");
  }, [location.pathname]);

  const initiateSignUp = async (signupValues: FieldValues) => {
    const { password, tc, piqNu, firstName, lastName } = signupValues;
    const email = signupValues?.email?.toLowerCase();
    setSubmitting(true);
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
        },
      }).then(async (res: any) => {
        window.localStorage.setItem(
          "termsConditionPrivacyPolicy",
          JSON.stringify(tc)
        );
        window.localStorage.setItem(
          "getUpdatesEmailFromPiQ",
          JSON.stringify(piqNu)
        );
        localStorage.setItem("loggedInWithGoogle", "true");
        plausible.trackEvent("Signup");
        localStorage.setItem("piq-user-emailAddress", email);
        // for logging in user in case user closes modal
        localStorage.setItem("tempPassword", password);

        try {
          localStorage.setItem("passwordResetInitiated", "true");
          const user = await Auth.signIn({
            username: email.toLowerCase(),
            password,
          });
          if (!Auth || typeof Auth.signIn !== "function") {
            throw new Error(
              "No Auth module found, please ensure @aws-amplify/auth is imported"
            );
          }

          const authToken = user.signInUserSession.accessToken.jwtToken;
          updateAxiosInstance(authToken);

          const getUpdatesEmailFromPiQ: boolean = piqNu;
          // loginSocketEmit(authToken);
          // plausible.trackEvent("Login");

          const queryParamValueSingin = localStorage.getItem("utm_source");
          if (queryParamValueSingin !== null) {
            plausible.trackEvent(`${queryParamValueSingin}Signin`, {
              utm_source: queryParamValueSingin,
            });
            localStorage.removeItem("utm_source");
          }
          axiosInstance
            .post(
              `/auth/saveUser`,
              { getUpdatesEmailFromPiQ: piqNu, promoCode: promoCode || "" },
              {
                headers: {
                  "x-access-token": authToken,
                },
              }
            )
            .then(async (res: any) => {
              // dispatch(fetch_user_details());
              // dispatch(fetchSubscriptionData());

              if (res?.data?.data?.newUser) {
                window.localStorage.setItem("newUser", "true");
                window.localStorage.setItem("registrationType", "email");
              }
              setSubmitting(false);
              localStorage.setItem("loggedIn", "true");
            })
            .catch((err) => {
              console.log(err);
              setSubmitting(false);
              let errRes = err?.response;
              // handleLogout();
              if (errRes?.data?.msg !== "") {
                popMessage.errorMessage(errRes?.data?.msg);
              }
              remove_auth_headers();
            });
          const queryParamValue = localStorage.getItem("utm_source");
          if (queryParamValue !== null) {
            window.rdt("track", "SignUp");
            plausible.trackEvent(`${queryParamValue}Signup`, {
              utm_source: queryParamValue,
            });
          }
          await Auth.verifyCurrentUserAttribute("email");
          // if (promoCode) {
          //   try {
          //     const response = await axiosInstance.post(
          //       `/enterprise-license/use/${promoCode}`
          //     );
          //     if (!response.data.success) {
          //       popMessage.errorMessage(response.data.message);
          //     } else {
          //       // Remove subscription and checkout params if promo code is valid
          //       const url = new URL(window.location.href);
          //       url.searchParams.delete("subscriptionType");
          //       url.searchParams.delete("with");
          //       window.history.replaceState({}, "", url.toString());
          //     }
          //   } catch (error: any) {
          //     if (error.response) {
          //       popMessage.errorMessage(error.response.data.message);
          //     } else {
          //       popMessage.errorMessage("Error applying promo code");
          //     }
          //   }
          // }
          localStorage.removeItem("companyLicense");
        } catch (error: any) {
          console.log(error);
          if (error.code === "UserNotConfirmedException") {
            Auth.resendSignUp(email);
            localStorage.setItem("piq-user-emailAddress", email);
          } else if (error.code === "PasswordResetRequiredException") {
            Auth.resendSignUp(email);
          } else {
            popMessage.errorMessage(error.message);
          }
          setSubmitting(false);
          throw error;
        }
      });
    } catch (err: any) {
      setSubmitting(false);

      if (err.code === "InvalidPasswordException") {
        popMessage.errorMessage(
          "Password must be at least 8 characters long, must contain an uppercase letter, a lowercase letter, a number and a symbol."
        );
      } else {
        popMessage.errorMessage(
          err.message ? err.message : "Something went wrong"
        );
      }
      throw err;
    } finally {
      setSubmitting(false);
    }
  };

  const registerFormSubmitHandler = async (e: any) => {
    const googleAuthTrack = localStorage.getItem("googleAuthTrack");
    const googleAuth = localStorage.getItem("googleAuth");

    if (googleAuthTrack) {
      localStorage.removeItem("googleAuthTrack");
    }

    if (googleAuth) {
      localStorage.removeItem("googleAuth");
    }

    return new Promise(async (resolve, reject) => {
      try {
        await initiateSignUp(registerFormData);
        // localStorage.removeItem("registerEmail");
        return resolve("success signup");
      } catch (err) {
        return reject("failed signup");
      }
    });
  };

  const checkEmailExists = async (email: string): Promise<boolean> => {
    try {
      await axiosInstance.post("/auth/check-email-verification", {
        email: email,
      });
      sendEvent({
        eventName: trackEvents.EMAIL_VALIDATION_FAILURE,
        payload: {
          email: email.toLowerCase(),
        },
      });
      setEmailExistError(emailExistsErrorMsg);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 404) {
          setEmailExistError("");
          sendEvent({
            eventName: trackEvents.EMAIL_VALIDATION_SUCCESS,
            payload: {
              email: email.toLowerCase(),
            },
          });
          return false;
        } else if (status === 403) {
          setEmailExistError(emailExistsErrorMsg);
          return true;
        }
      }

      setEmailExistError("An unexpected error occurred. Please try again.");
      return true;
    }
  };

  const debouncedEmailCheck = useCallback(
    debounce(async (email: string, callback: Function) => {
      const emailExists = await checkEmailExists(email.toLowerCase());
      callback(emailExists);
    }, 1000),
    []
  );

  const emailChangeHandler = useCallback(async () => {
    const email = emailInputRef?.current?.value;
    setValue("email", email, { shouldValidate: true });

    if (email === "") {
      setEmailExistError("");
      setDisableEmail(true);
      setCheckingEmail(false);
      return;
    }

    if (email && isValidEmailComprehensive(email)) {
      setCheckingEmail(true);
      setDisableEmail(true);
      debouncedEmailCheck(email, (emailExists: boolean) => {
        if (emailExists) {
          setCheckingEmail(false);
          setDisableEmail(true);
        } else {
          setEmailExistError("");
          setCheckingEmail(false);
          setDisableEmail(false);
        }
      });
    } else {
      setCheckingEmail(false);
    }
  }, [debouncedEmailCheck, emailInputRef, setValue]);

  const passwordChangeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    setValue("password", e.target.value);
    await trigger("password");
  };

  return {
    registerFormSubmitHandler,
    setValue,
    register,
    errors,
    registerFormData,
    registerControl,
    trigger,
    submitting,
    emailExistError,
    setEmailExistError,
    clearErrors,
    emailChangeHandler,
    passwordChangeHandler,
    disableEmail,
    checkingEmail,
  };
};
