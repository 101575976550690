import { zodResolver } from "@hookform/resolvers/zod";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { registerEmailVerificationFormSchema } from "src/constants/auth";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { useQueryParams } from "src/development/Component/Auth-Modal/utils";
import { popMessage } from "src/development/Component/message/message";
import { edituser } from "src/store/User/action";
import { axiosInstance } from "src/store/utility";
import useAuthCommon from "./useAuthCommon";

export const useAuthEmailVerification = ({ email, nextStep }: any) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const { getQueryParams } = useQueryParams();
  const { authModal } = getQueryParams();
  const isLoginPage = authModal === "login";

  const userDetail = useSelector((state: any) => state?.user_data?.data);
  const { handleAutoLogin } = useAuthCommon({
    email,
    logging: submitting,
    setLogging: setSubmitting,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const withCheckout = searchParams.get("with") === "checkout";

  const {
    formState: { errors },
  } = useForm({
    resolver: zodResolver(registerEmailVerificationFormSchema),
  });

  const { sendEvent } = useTrackerContext();

  const isRegisterPage = location.pathname === "/register";

  const handleResendEmailVerificationButton = async () => {
    setError(false);
    try {
      if (isLoginPage) {
        await Auth.resendSignUp(email).then(() => {
          popMessage.successMessage("Verification code sent successfully");
        });
      } else {
        await Auth.verifyCurrentUserAttribute("email").then(() => {
          popMessage.successMessage("Code sent successfully.");
        });
      }
    } catch (error: any) {
      popMessage.errorMessage(
        error?.message || "Something went wrong while sending otp"
      );
    }
  };

  const handleCodeSubmitUnverified = async (code: string) => {
    setSubmitting(true);
    sendEvent({
      eventName: isRegisterPage
        ? trackEvents.SIGNUP_EMAIL_VERIFICATION_CODE_SUBMIT
        : trackEvents.SIGNIN_EMAIL_VERIFICATION_CODE_SUBMIT,
      payload: {
        code: code,
      },
    });
    try {
      if (isLoginPage) {
        await axiosInstance.post("/auth/verify-code", {
          username: email,
          code,
        });
        await axiosInstance.post("/enterprise-license/verify-user-promocode");
      } else {
        await Auth.verifyCurrentUserAttributeSubmit("email", code).then(
          async () => {
            dispatch(
              edituser(
                {
                  ...userDetail,
                  isVerified: true,
                },
                async (res: any) => {
                  if (res.status === 200) {
                    await axiosInstance.post(
                      "/enterprise-license/verify-user-promocode"
                    );
                    setSubmitting(false);
                  } else {
                    popMessage.errorMessage("Something Went Wrong");
                    setSubmitting(false);
                  }
                },
                (err: any) => {
                  setSubmitting(false);
                  throw err;
                }
              ) as any
            );
          }
        );
        setSubmitting(false);
      }

      sendEvent({
        eventName: isRegisterPage
          ? trackEvents.SIGNUP_EMAIL_VERIFICATION_SUCCESS
          : trackEvents.SIGNIN_EMAIL_VERIFICATION_SUCCESS,
      });
    } catch (error: any) {
      sendEvent({
        eventName: isRegisterPage
          ? trackEvents.SIGNUP_EMAIL_VERIFICATION_FAILURE
          : trackEvents.SIGNIN_EMAIL_VERIFICATION_FAILURE,
      });
      setSubmitting(false);
      console.log(error);
      throw error;
    }
  };

  const otpSubmitHandler = async (otp: string) => {
    setSubmitting(true);
    console.log(submitting);
    try {
      const response = await handleCodeSubmitUnverified(otp);
      if (!withCheckout) {
        await handleAutoLogin(); // Attempt auto-login after successful verification
      }
      nextStep();
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setError(true);
    }
    setSubmitting(false);
  };

  return {
    handleResendEmailVerificationButton,
    emailOtpSubmitting: submitting,
    otpError: errors?.email || error,
    otpSubmitHandler,
    setOtpError: setError,
    setSubmitting,
  };
};
