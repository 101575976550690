import "./index.scss";
import React, { useEffect } from "react";
import Button from "../../Button/Button";
import TextInput from "../../TextInput/TextInput";
import PasswordValidityInfo from "../PasswordValidityInfo";
import { useChangePassword } from "src/Hooks/auth/useNewForgotPassword";
import { useQueryParams } from "../utils";

const ChangePassword = ({ onBack }: { onBack: () => void }) => {
  const {
    handleSubmit,
    onSubmit,
    watch,
    register,
    errors,
    isSubmitting,
    setValue,
  } = useChangePassword();

  const { setQueryParams } = useQueryParams();

  useEffect(() => {
    const initiation =
      localStorage.getItem("passwordResetInitiated") === "true";
    if (!initiation) {
      setQueryParams({ authModal: "login", step: "1" });
    }
  }, [errors, setQueryParams]);

  return (
    <div className="auth-modal-change-password-wrapper">
      <h1 className="auth-modal-change-password-title">Change Password</h1>
      <form
        className="auth-modal-change-password-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* @ts-ignore */}
        <TextInput
          style={{ height: "45.33px" }}
          placeholder="New Password"
          type="password"
          {...register("password")}
          error={errors.password?.message}
          handleError={() => setValue("password", "")}
          //   onBlur={() => {
          //       sendEvent({
          //           eventName: trackEvents.CHANGE_PASSWORD_NEW_PWD
          //       })
          //   }}
        />
        {/* @ts-ignore */}
        <TextInput
          style={{ height: "45.33px" }}
          placeholder="Confirm Password"
          type="password"
          value={watch("confirmPassword")}
          {...register("confirmPassword")}
          error={errors.confirmPassword?.message}
          handleError={() => setValue("confirmPassword", "")}
          // onBlur={() => {
          //     sendEvent({
          //         eventName: trackEvents.CHANGE_PASSWORD_CONFIRM_PWD
          //     })
          // }}
        />
        <PasswordValidityInfo password={watch("password")} />
        <div className="auth-modal-change-password-button-wrapper">
          <Button
            disabled={isSubmitting}
            text="Back"
            type="outline"
            height="38px"
            width="102px"
            weight="bold"
            radius="md"
            onClick={onBack}
            buttonType="button"
          />
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            text="Continue"
            type="primary"
            height="38px"
            width="102px"
            weight="bold"
            radius="md"
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
