import React from "react";
import { useSelector } from "react-redux";
import { useAuthContext } from "src/context/AuthProvider";
import useSubscription from "src/Hooks/useSubscription";

export const NewsquawkMessageComponent: React.FC<{
    socket: string,
}> = ({ socket }) => {
    const { isSignedIn: isUserLoggedIn } = useAuthContext();
    const { darkMode } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));
    const { has_newsquawk_subscription, handleBuyNow } = useSubscription()

    return (
        <>
            {socket === "newsquawk-delay" && (
                <div
                    className="unpaid-app-container"
                    style={{
                        border: darkMode ? "1px dashed #fff" : "1px dashed #ff644e",
                        background: darkMode ? "#4c5563" : "#fff",
                    }}
                >
                    <div
                        className="unpaid-app-msg"
                        style={{ color: darkMode ? "#fff" : "#000" }}
                    >
                        This version of{" "}
                        <span style={{ color: darkMode ? "#fff" : "#000" }}>
                            {" "}
                            Newsquawk’s Breaking News{" "}
                        </span>
                        feed is{" "}
                        <span style={{ color: darkMode ? "#fff" : "#000" }}>
                            {" "}
                            delayed 30 minutes
                        </span>
                    </div>
                    {(!has_newsquawk_subscription || !isUserLoggedIn) && (
                        <div className="unpaid-botton-contain">
                            <div
                                className="unpaid-buy-now-btn"
                                onClick={handleBuyNow}
                            >
                                Buy Now
                            </div>
                            <div
                                className="unpaid-para"
                                style={{ color: darkMode ? "#9ca1a8" : "#454e5c" }}
                            >
                                {/* If you want the full
                                <span>&nbsp;realtime feed&nbsp;</span> version{" "} */}
                                <span>
                                    Upgrade to access real-time audio & text news headlines.
                                </span>
                            </div>
                        </div>
                    )}



                </div>
            )}

        </>
    )


};
