import React, { useRef, useState } from "react";
import GlobalSearchCard from "../Search/GlobalSearchCard";
import { useSelector } from "react-redux";
import searchIcon from "src/style/images/global_search_icon.svg";
import focusedSearchLight from "src/style/images/fucused_search_light.svg";
import focusedSearch from "src/style/images/focused_search.svg";
import useGlobalSearch from "src/Hooks/useGlobalSearch";
import GlobalArticleNotFound from "../Drawers/Articles/GlobalArticleNotFound";
import GlobalSearchLoading from "./GlobalSearchLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuthContext } from "src/context/AuthProvider";
import { message } from "antd";
import { debounce } from "lodash";
import "./HeaderNav.scss";

import bookmarkFilledDarkDot from "src/style/images/bookmark_filled_dark_dot.svg";
import bookmarkFilledDark from "src/style/images/bookmark_filled_dark.svg";

import bookmarkLightDot from "src/style/images/bookmark_light_dot.svg";
import bookmarkLight from "src/style/images/bookmark_light.svg";

import { useBookmarkContext } from "src/context/BookmarkProvider";
import Bookmark from "../Bookmark/Bookmark";

import closeIcon from "src/style/images/close_icon.svg";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { useLayoutContext } from "src/context/LayoutProvider";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import HeaderNavAd from "./HeaderNavAd";

function HeaderNav({ props }: any) {
  const {
    handleFocus,
    handleBlur,
    handleSearch,
    searchedText,
    isFocused,
    showContainer,
    totalData,
    globalData,
    isLoading,
    setShowContainer,
    fetchMoreData,
    hasMore,
    refreshKey,
    setSearchedText,
  } = useGlobalSearch();

  const searchRef = useRef<HTMLInputElement>(null);
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));
  const { setActiveDrawer } = useLayoutContext();
  const { sendEvent } = useTrackerContext();
  const navigate = useNavigate();

  const handleSearchWithoutUser = () => {
    if (!isUserLoggedIn) {
      navigate("?authModal=login&step=1&internalStep=1");
      message.warning("You need to login to access search", 10);
    }
  };

  const handleBookmarkClick = () => {
    sendEvent({
      eventName: trackEvents.HEADER_BOOKMARK_CLICK,
    });
    if (!isUserLoggedIn) {
      message.warning("Please login to use this functionality.", 10);
      navigate("?authModal=login&step=1&internalStep=1");
      return;
    }
    setShowBookmark((prev) => !prev);
  };
  const onSearch = debounce(
    () => handleSearch(searchRef?.current?.value ?? ""),
    1000
  );

  const { bookmarks, showBookmark, setShowBookmark } = useBookmarkContext();
  return (
    <>
      <div className={`header_nav ${darkMode ? "dark_header_nav" : ""}`}>
        {/* <div className="header_nav_ad">
          <HeaderNavAd />
        </div> */}
        {isUserLoggedIn ? (
          <>
            {/* true ? <> */}
            <div className="search_box">
              <div className="group" onClick={handleSearchWithoutUser}>
                {!isFocused ? (
                  <img
                    className="icon"
                    src={searchIcon}
                    alt="piq logo"
                    width={95.417}
                    height={25}
                  />
                ) : darkMode ? (
                  <img
                    className="icon"
                    src={focusedSearch}
                    alt="piq logo"
                    width={95.417}
                    height={25}
                  />
                ) : (
                  <img
                    className="icon"
                    src={focusedSearchLight}
                    alt="piq logo"
                    width={95.417}
                    height={25}
                  />
                )}
                <input
                  placeholder="Search"
                  type="search"
                  className={`${!isUserLoggedIn ? "input disabled" : "input"} ${
                    searchRef?.current?.value !== "" ? "input_focused" : ""
                  }`}
                  onChange={onSearch}
                  onFocus={() => {
                    handleFocus();
                    sendEvent({
                      eventName: trackEvents.CLICK_GLOBAL_SEARCH_FIELD,
                    });
                    setShowBookmark(false);
                    setShowContainer(true);
                  }}
                  onBlur={() => {
                    handleBlur();
                  }}
                  ref={searchRef}
                  // value={searchedText}
                  // disabled={!isUserLoggedIn}
                ></input>
              </div>
            </div>
            <div
              className="bookmark_container"
              style={{ cursor: "pointer" }}
              onClick={handleBookmarkClick}
            >
              {darkMode ? (
                <img
                  src={
                    bookmarks?.length === 0
                      ? bookmarkFilledDark
                      : bookmarkFilledDarkDot
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    bookmarks?.length === 0 ? bookmarkLight : bookmarkLightDot
                  }
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="buttons-container">
              <Link
                to="?modal=pricing"
                onClick={() =>
                  sendEvent({
                    eventName: trackEvents.HEADER_UPGRADE_FOR_FREE_BTN,
                  })
                }
              >
                <Button
                  text="Upgrade for free"
                  type="secondary"
                  fontSize="12px"
                  padding="10px"
                  height="24px"
                  width="130px"
                  radius="sm"
                  background="#525A7A"
                  color="#FDFDFD"
                  onClick={() =>
                    sendEvent({
                      eventName: trackEvents.HEADER_UPGRADE_FOR_FREE_BTN,
                    })
                  }
                />
              </Link>

              <Link
                to="?authModal=login&step=1&internalStep=1"
                onClick={() =>
                  sendEvent({
                    eventName: trackEvents.HEADER_LOGIN_BTN,
                  })
                }
              >
                <Button
                  text="Login"
                  type="outline"
                  borderColor={darkMode ? "#A3AAC2" : "#525A7A"}
                  padding="10px"
                  fontSize="12px"
                  height="24px"
                  width="63px"
                  radius="sm"
                  background="transparent"
                  color={darkMode ? "#FDFDFD" : "#525A7A"}
                />
              </Link>
            </div>
          </>
        )}
      </div>
      {showBookmark && <Bookmark props={props} />}
      {searchRef?.current?.value !== "" && showContainer && isUserLoggedIn && (
        <>
          <div
            className="bookmark_list_container_overlay"
            onClick={() => {
              setShowContainer(false);
            }}
          ></div>
          <div
            className={`search_list_container
              ${darkMode ? "dark_search_list_container" : ""}`}
            key={refreshKey}
          >
            <InfiniteScroll
              dataLength={globalData?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<GlobalSearchLoading />}
              scrollableTarget={"search_list_container"}
              height={520}
              className="scrolled_data"
              style={{ scrollbarWidth: "none" }}
            >
              <div
                className={`${
                  showContainer ? "show_search_list_container" : ""
                }`}
              >
                {!isLoading && (
                  <>
                    <div className="results">
                      <div className="closeIcon">
                        <img
                          src={closeIcon}
                          alt=""
                          onClick={() => {
                            setShowContainer(false);
                            if (searchRef?.current) {
                              searchRef.current.value = "";
                            }
                          }}
                        />
                      </div>
                      {totalData} Result
                    </div>
                  </>
                )}
                {isLoading ? (
                  <>
                    <GlobalSearchLoading />
                  </>
                ) : (
                  <>
                    {totalData === 0 && (
                      <GlobalArticleNotFound searchedText={searchedText} />
                    )}

                    {globalData.map((data: any, i: number) => {
                      return (
                        <GlobalSearchCard
                          data={data}
                          key={i}
                          props={props}
                          searchedText={searchedText}
                          setShowContainer={setShowContainer}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </InfiniteScroll>
          </div>
        </>
      )}
    </>
  );
}

export default HeaderNav;
