import React, { useRef, useEffect } from "react";
import { message, Radio, Skeleton, Space, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useAuthContext } from "src/context/AuthProvider";
import LoadingSpinner from "../Drawers/LoadingSpinner";

const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const CollapsePanelRedioGroup: React.FC<{
  feeds: any[];
  slugList: string[];
  hasNewsQuawkSubscription: boolean;
  socket: string;
  onEditChange: (socket: string, string: any) => any;
  isUserLoggedIn: boolean;
  loading?: boolean;
  hasMore?: boolean;
  loadMore?: () => void;
}> = ({
  feeds,
  slugList,
  onEditChange,
  hasNewsQuawkSubscription,
  socket,
  isUserLoggedIn,
  loading,
  hasMore,
  loadMore,
}) => {
  const { hasHammerstoneSubscription }: any = useSelector(
    (state: any) => state.premiumapps
  );
  const { isSignedIn } = useAuthContext();
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !loading && hasMore && loadMore) {
          loadMore();
        }
      },
      { rootMargin: "0px 0px 200px 0px" }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loading, hasMore, loadMore]);

  return (
    <>
      <Radio.Group
        size="small"
        className="v2-edit-panel-select"
        style={{ height: "max-content", overflow: "hidden", width: "100%" }}
        value={socket}
      >
        <Space
          direction="vertical"
          style={{
            maxHeight: "350px",
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
          }}
        >
          {feeds?.map((col: any, index: number) => {
            return (
              <Tooltip
                placement="left"
                title={
                  slugList?.includes(col?.slug) ? "Feed already in use" : ""
                }
                color={"#000"}
                key={col?._id}
              >
                <div className={`user-item pointer flex items-start`}>
                  <Radio
                    value={col?.slug}
                    key={col?.slug}
                    style={{ alignItems: "center" }}
                    onChange={() => {
                      onEditChange(socket, col["slug"]);
                    }}
                    checked={true}
                    disabled={
                      slugList?.includes(col?.slug) ||
                      (!hasNewsQuawkSubscription &&
                        col?.slug === "newsquawk") ||
                      (col?.slug === "newsquawk-delay" && !isUserLoggedIn) ||
                      (col?.slug === "newsquawk" && !isUserLoggedIn) ||
                      (!hasHammerstoneSubscription &&
                        col?.slug === "hammerstone") ||
                      (col?.slug === "hammerstone" && !isUserLoggedIn)
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {col?.icon && (
                        <img
                          src={ADMIN_API_URL+col?.icon}
                          alt=""
                          style={{
                            height: "10px",
                            width: "10px",
                            marginRight: "4px",
                          }}
                        />
                      )}
                      <span
                        className={" p-y-10 edit-layout-label-seetings"}
                        style={{
                          fontWeight: "400",
                          lineHeight: "10px",
                          color: slugList?.includes(col?.slug)
                            ? "grey"
                            : "inherit",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "100%",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {col.title}
                      </span>
                    </div>
                  </Radio>
                </div>
              </Tooltip>
            );
          })}
          {hasMore && (
            <div
              ref={loadMoreRef}
              style={{ textAlign: "center", padding: "10px", display: "block" }}
            >
              {loading ? (
                <div>
                  <LoadingSpinner size={16} />
                </div>
              ) : (
                <div style={{ height: "50px" }} />
              )}
            </div>
          )}
        </Space>
      </Radio.Group>
    </>
  );
};

export default CollapsePanelRedioGroup;
