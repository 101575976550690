import React, { useEffect, useState } from "react";

import Modal from "../Modal";
import LoginForm from "../LoginForm";
import EmailVerification from "../EmailVerification";
import PasswordResetVerification from "../PasswordResetVerification";
import ChangePassword from "../ChangePassword";
import { useQueryParams } from "../utils";
import { useAuthContext } from "src/context/AuthProvider";
import { useAuthEmailVerification } from "src/Hooks/auth/useAuthEmailVerificationV2";
import Checkout from "../../Auth/Checkout/Checkout";
import PaymentSuccessful from "../../Auth/SuccessComponent/PaymentSuccessful";
import useAuthCommon from "src/Hooks/auth/useAuthCommon";

// Login Modal Children Component
interface LoginModalChildrenProps {
  resetFormErrors: boolean;
  setResetFormErrors: (value: boolean) => void;
}

const LoginModalChildren: React.FC<LoginModalChildrenProps> = ({
  resetFormErrors,
  setResetFormErrors,
}) => {
  const { getQueryParams, setQueryParams, getParamFromKey, deleteQueryParam } =
    useQueryParams();
  const { step, authModal } = getQueryParams();
  const [logging, setLogging] = useState<boolean>(false);

  const {
    handleResendEmailVerificationButton,
    emailOtpSubmitting,
    otpError,
    otpSubmitHandler,
    setOtpError,
    setSubmitting,
  } = useAuthEmailVerification({
    email: localStorage.getItem("loginEmail"),
    nextStep: () => {},
  });

  const withCheckout = getParamFromKey("with") === "checkout";

  const { handleAutoLogin } = useAuthCommon({
    email: localStorage?.getItem("loginEmail") || "",
    logging,
    setLogging,
  });

  switch (step) {
    case "2":
      if (authModal === "login") {
        return (
          <EmailVerification
            nextStepHandler={otpSubmitHandler}
            resendOtpHandler={handleResendEmailVerificationButton}
            submitting={emailOtpSubmitting}
            error={!!otpError}
            email={localStorage.getItem("loginEmail") || ""}
            setError={setOtpError}
            sendOtpByDefault={true}
            setSubmitting={setSubmitting}
          />
        );
      }
      return null;
    case "3":
      if (authModal === "login") {
        return (
          <PasswordResetVerification
            handleNextStep={() =>
              setQueryParams({ step: "4", authModal: "login" })
            }
          />
        );
      }
      return null;
    case "4":
      if (authModal === "login") {
        return (
          <ChangePassword
            onBack={() => {
              localStorage.removeItem("loginEmail");
              localStorage.removeItem("forgotPasswordEmail");
              setQueryParams({ step: "1", authModal: "login" });
            }}
          />
        );
      }
      return null;

    case "5":
      if (authModal === "login" && withCheckout) {
        return (
          <div
            style={{
              padding: "28px 40px",
              background: "white",
            }}
          >
            <Checkout
              nextStepHandler={() => {
                setQueryParams({ step: "6", authModal: "login" });
              }}
            />
          </div>
        );
      }
      return null;

    case "6":
      if (authModal === "login" && withCheckout) {
        return (
          <div
            className="payment-success"
            style={{
              padding: "60px 40px",
              // display: "flex",
              // alignItems: "center",
              width: "100%",
              background: "white",
            }}
          >
            <PaymentSuccessful
              launchSuiteHandlerBtn={() => {
                handleAutoLogin();
              }}
              loading={logging}
            />
          </div>
        );
      }
      return null;
    default:
      return (
        <LoginForm
          resetErrors={resetFormErrors}
          setResetErrors={setResetFormErrors}
        />
      );
  }
};

// Parent Login Modal Component
const LoginModal = () => {
  const { getQueryParams, deleteQueryParam } = useQueryParams();
  const [resetFormErrors, setResetFormErrors] = useState(false); // Track if we need to reset errors
  const { authModal, step } = getQueryParams();
  const { isSignedIn } = useAuthContext();

  const { handleAutoLogin } = useAuthCommon({
    email: localStorage?.getItem("loginEmail") || "",
  });

  // const [isOpen, setIsOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isSignedIn && step !== "4") {
      deleteQueryParam("authModal");
      deleteQueryParam("step");
      return;
    }
    setIsOpen(authModal === "login");
  }, [authModal, isSignedIn]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={async () => {
        deleteQueryParam("authModal");
        deleteQueryParam("step");
        deleteQueryParam("internalStep")
        setResetFormErrors(true);
        handleAutoLogin();
      }}
    >
      <LoginModalChildren
        resetFormErrors={resetFormErrors}
        setResetFormErrors={setResetFormErrors}
      />
    </Modal>
  );
};

export default LoginModal;
