import { Button, Collapse, message } from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import closeIcon from "src/style/images/close_icon.svg";
import { CloseCircleOutlined, CopyOutlined, SettingFilled } from "@ant-design/icons";
import EditPanel from "./EditPanel";
import { SocketNames } from "src/interface/PiqState";
import { CaretRightOutlined } from "@ant-design/icons";
import KeywordHighlight from "./KeywordHighlight";
import useColumnHeader from "src/Hooks/useColumnHeader";
import FilterPanel from "./FilterPanel";
import CustomFeedNameEdit from "./CustomFeedNameEdit";
import CustomFeedAdd from "./CustomFeedAdd";
import { customFeedsSockets } from "src/SuiteConfig/columnConfiguration";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
const { Panel } = Collapse;
const CustomColumnHeader: React.FC<{
  setShowEditPanel: (params: any) => any;
  socket: SocketNames;
  type?: "custom-feed" | "piq-custom-feed" | null;
  defaultActivePanel?: string;
  handleAddCustomFeed?: any;
  handleChangeTitle?: any;
  title: any;
  setTitle: any;
  keyToRefresh: any;
  setKeyToRefresh: any;
  setOpenByDefault: any;
}> = ({
  setShowEditPanel,
  socket,
  type = "custom-feed",
  defaultActivePanel,
  handleAddCustomFeed,
  handleChangeTitle,
  title,
  setTitle,
  keyToRefresh,
  setKeyToRefresh,
  setOpenByDefault,
}) => {
  const { removeCustomColumn, generateKey, setGenerateKey } = useColumnHeader();
  const [sortFeeds, setSortFeeds] = useState(true);
  const {sendEvent} = useTrackerContext();

  return (
    <div
      className="v2-column-header-option"
      // style={{
      //   height: socket === "harkster" || socket === "forexLive" || socket === "telegram" ? "96%" : socket === "reuters" ? "94%" : socket === "tradingview" ? "91%" : "100%",
      //   width: socket === "harkster" || socket === "forexLive" ? "96%" : socket === "reuters" ? "98.6%" : "99%",

      // }}
    >
      <div style={{ textAlign: "right" }}>
        <Button
          type="text"
          shape="circle"
          size="small"
          className="v2-column-header-option-close-btn"
          icon={
            <img
              src={closeIcon}
              alt=""
              className="v2-column-header-option-close-btn-icon"
            />
          }
          onClick={() => setShowEditPanel(false)}
        />
      </div>

      {socket === "tradingview" ||
      socket === "investing-economic-calender" ||
      socket === "telegram" ? (
        ""
      ) : (
        <>
          <div className="v2-edit-panel-setting-title-div">
            {customFeedsSockets.some((feed) => socket.includes(feed)) ? (
              <div className="v2-edit-panel-setting-title">
                Custom Feed Settings
              </div>
            ) : (
              <div className="v2-edit-panel-setting-title">Feed Settings</div>
            )}

            <SettingFilled className="v2-edit-panel-setting-icon" />
          </div>

          <div className="settings-divider"></div>

          <Collapse
            accordion={Array.isArray(defaultActivePanel) ? false : true}
            className="v2-edit-panel-collapse1"
            bordered={false}
            expandIconPosition={"end"}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            activeKey={defaultActivePanel ?? ""}
            key={keyToRefresh}
            onChange={(e) => {
              if (e) {
                setOpenByDefault(e[e.length - 1]);
                if (e[e.length - 1] !== "2") {
                  setSortFeeds(true);
                }
              } else {
                setOpenByDefault("");
                setSortFeeds(true);
              }
            }}
          >
            {type === "custom-feed" ? (
              <>
                <Panel
                  header="Feed Name"
                  key="1"
                  style={{
                    border: "none",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                >
                  <CustomFeedNameEdit
                    socket={socket}
                    handleChangeTitle={handleChangeTitle}
                    title={title}
                    setTitle={setTitle}
                  />
                </Panel>
                <Panel
                  header="Select Feeds"
                  key="2"
                  style={{
                    border: "none",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                >
                  <CustomFeedAdd
                    socket={socket}
                    handleAddCustomFeed={handleAddCustomFeed}
                    generateKey={generateKey}
                    setGenerateKey={setGenerateKey}
                    sortFeeds={sortFeeds}
                    setSortFeeds={setSortFeeds}
                  />
                </Panel>
              </>
            ) : null}
            <Panel
              header="Keyword Alerts"
              key="3"
              style={{ border: "none", marginBottom: "5px", cursor: "pointer" }}
            >
              <KeywordHighlight socket={socket} />
            </Panel>

            <Panel
              header="Change Feed"
              key="4"
              style={{ border: "none", marginBottom: "5px", cursor: "pointer" }}
            >
              <EditPanel socket={socket} />
            </Panel>
            <Panel
              header="Conditional Filter"
              key="5"
              style={{ border: "none" }}
            >
              <FilterPanel socket={socket} />
            </Panel>
          </Collapse>

          <div className="settings-divider"></div>
        </>
      )}

      <div
        style={{ color: "red", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => removeCustomColumn(socket)}
      >
        <CloseCircleOutlined /> Delete Custom Feed
      </div>
      {
              type === "piq-custom-feed"  ? <div
              style={{ color: "green", cursor: "pointer", marginBottom: "100px" }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window?.location?.origin}/feed/${socket}` || `https://piqsuite.com/feed/${socket}`
                );
                sendEvent({
                  eventName: trackEvents.SHARE_FEED,
                  payload: {
                    feed: socket
                  }
                })
                message.success("Link copied to clipboard", 10);
              }}
            >
              <CopyOutlined /> Share Feed
            </div>  : null
            }
    </div>
  );
};

export default CustomColumnHeader;
