import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import searchIcon from "src/style/images/global_search_icon.svg";
import focusedSearchLight from "src/style/images/fucused_search_light.svg";
import focusedSearch from "src/style/images/focused_search.svg";
import { debounce } from "lodash";

function FeedSearch({ handleSearch, searchedText, showSearch ,setSearchedText}: {
  handleSearch: (searchedText: string) => void;
  searchedText: string;
  showSearch: boolean;
  setSearchedText: (searchedText: string) => void;
}) {
  const [isFocused, setIsFocused] = useState(false);
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setSearchedText(inputRef.current?.value || "");
  };

  const changeHandler = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current?.value) {
      handleSearch(inputRef.current.value);
    }
  }, 500);

  useEffect(() => {

    if(!showSearch || searchedText === "") return;

    handleSearch(searchedText)

  },[showSearch,searchedText])

  return (
    <div
      className="search_feed_container"
      hidden={!showSearch}
    >
      <div
        // className={darkMode ? "dark_searchbox_feed" : "searchbox_feed"}
        className={`searchbox_feed ${darkMode ? "dark_searchbox_feed" : ""}`}

      // style={{ width: "98%" }}
      >
        <div className="search_box">
          <div className="group">
            {!isFocused ? (
              <img
                className="icon"
                src={searchIcon}
                alt="piq logo"
                width={95.417}
                height={25}
              />
            ) : darkMode ? (
              <img
                className="icon"
                src={focusedSearch}
                alt="piq logo"
                width={95.417}
                height={25}
              />
            ) : (
              <img
                className="icon"
                src={focusedSearchLight}
                alt="piq logo"
                width={95.417}
                height={25}
              />
            )}
            <input
              placeholder="Search"
              type="search"
              ref = {inputRef}
              className="input"
              onChange={changeHandler}
              onFocus={handleFocus}
              onBlur={handleBlur}
              defaultValue={searchedText}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedSearch;
