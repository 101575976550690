import React, { forwardRef, useState } from 'react'
import { Collapse } from "antd";
import { useSelector } from 'react-redux';
import CmeGroupLogo from 'src/style/images/cmegroup_logo.svg'
import CmeGroupLogoLight from 'src/style/images/cmegroup_logo_light.svg'
import { Icon } from '@iconify/react';
import { CmeDetail } from './CmeDetail';
import { lists } from 'src/constants/cmeGroupApp';
import { CmeGroupContentProps } from 'src/interface/cmeGroupApp';
const { Panel } = Collapse;

const CmeGroupContent = forwardRef<HTMLIFrameElement, CmeGroupContentProps>(({ link, title, setLink, setTitle, setFetching, fetching, inFeedSharingModal }, ref) => {

    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    return (
        <div className="cmegroup-content" style={{
            paddingTop: `${inFeedSharingModal ? "0px" : ""}`,
            paddingBottom: `${inFeedSharingModal ? "40px" : ""}`,
        }}>

            {
                !link && !title &&
                <>
                    <img src={darkMode && !inFeedSharingModal ? CmeGroupLogo : CmeGroupLogoLight} alt="" className="logo" />
                    <div className="container">
                        <h3 className="sub-heading">
                            CME GROUP COURSES
                        </h3>
                        <span className="sub-heading-para">
                            Courses & Lessons tailored for all levels of experience.
                        </span>
                        <p className="sub-heading-para-2">
                            Learn about the futures and options markets with a variety of courses designed to help you at each stage of your trading journey. Whether you are brand new to the derivatives market, or an experienced trader looking to sharpen specific skills, our courses will help you deepen your knowledge and improve your understanding of our markets.      </p>
                        <div>
                            <Collapse defaultActiveKey={['']} expandIconPosition='right' accordion>
                                {
                                    lists?.map((item: any, index: number) => (
                                        <Panel header={item?.heading} key={index} style={{
                                            height: "40px !important"
                                        }}>
                                            {
                                                item?.text !== "" && <p>
                                                    {item?.text}
                                                </p>
                                            }

                                            <div className="list-container">
                                                {
                                                    item?.subList?.map((it: any) => <div className="list-item"
                                                        onClick={() => {
                                                            setTitle(it?.title);
                                                            setLink(it?.link)
                                                        }}
                                                        key={it?.title}>
                                                        <Icon icon="material-symbols:playlist-play" />
                                                        <span className="list-item-title">
                                                            {it?.title}
                                                        </span>
                                                    </div>)
                                                }
                                            </div>
                                        </Panel>
                                    ))
                                }
                            </Collapse>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3>
                                    ACCREDITED COURSE
                                </h3>
                                <Icon icon="iconamoon:certificate-badge-light" fontSize={24} />
                            </div>
                            <p className="card-para">
                                Did you know that CME Institute classes can fulfill CFA and GARP continuing education requirements? Every CME Institute course can be self-reported in your CFA online <a href="https://cpd.cfainstitute.org/" target='_blank'>CE tracker</a> and select classes can be used for GARP credits. See which of our classes qualify for GARP credits <a href="https://mygarp.force.com/sfdcApp#!/cpe_credits" target='_blank'>here</a> .
                            </p>

                        </div>
                    </div>
                </>
            }
            {
                link !== "" &&
                title !== "" && <CmeDetail
                    resetNavigation={() => {
                        setLink("")
                        setTitle("")
                        setFetching(false)
                    }}
                    link={link}
                    title={title}
                    setFetching={setFetching}
                    fetching={fetching}
                    ref={ref}
                    inFeedSharingModal={inFeedSharingModal}
                />
            }
        </div >
    )
})

export default CmeGroupContent




