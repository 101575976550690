import React, { useState } from "react";
import { Input, Tag, Tooltip } from "antd";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useAuthContext } from "src/context/AuthProvider";
import { Singlekeyword } from "src/interface/keyword";
import { useLayoutContext } from "src/context/LayoutProvider";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "src/constants/routeNames";

const SingleKeywordHighlights: React.FC<{
  tags: Singlekeyword[];
  tag: string;
  setTag: (value: string) => void;
  addKeyword: (params: any) => any;
  deleteKeyword: (params: any) => any;
}> = ({ tags, addKeyword, deleteKeyword, tag, setTag }) => {
  const [showCount, setShowCount] = useState(false);
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const { hasSubscriptionForAdsRemoval } = useSelector((state: any) => ({
    hasSubscriptionForAdsRemoval: state.subscription?.data?.active || false,
  }));
  const { setActiveDrawer } = useLayoutContext()

  const navigate = useNavigate();
  return (
    <div className="v2-edit-panel-single-words-container">
      <div className={isUserLoggedIn ? "" : "div-disabled"}>
        <div className="v2-edit-panel-single-words-div">
          <div className="v2-edit-panel-single-words">
            Single Words & Phrases ({tags?.length}/
            {hasSubscriptionForAdsRemoval ? "25" : "3"})
          </div>
          <Tooltip
            title="Type a word or phrase then [enter]. These words & phrases will be highlighted when they appear in a post in this feed. Click on the “x” to delete them."
            showArrow={false}
            placement="bottom"
          >
            <InfoCircleOutlined className="v2-edit-panel-info-icon" />
          </Tooltip>
        </div>

        <Input
          placeholder="Type word or phrase + [enter]"
          onPressEnter={(e) => addKeyword(e)}
          onChange={(e) => setTag(e.target.value)}
          allowClear
          value={tag}
          showCount={showCount}
          maxLength={25}
          disabled={isUserLoggedIn ? false : true}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          autoComplete="off"
          onFocus={() => setShowCount(true)}
          onBlur={() => setShowCount(false)}
        />

        {tags?.map((item: any) => (
          <div key={item?.name} className="v2-edit-panel-tag-div">
            <Tag className="v2-edit-panel-tag" color={item?.backgroundColor}>
              <span style={{ color: item?.fontColor }}>{item?.name}</span>
            </Tag>
            <div
              className="v2-edit-panel-tag-icon"
              onClick={() => deleteKeyword(item?.name)}
            >
              <CloseOutlined style={{ fontSize: "8px" }} />
            </div>
          </div>
        ))}
      </div>

      <p
        className="v2-edit-panel-upgrade-note"
        hidden={
          isUserLoggedIn
            ? hasSubscriptionForAdsRemoval
              ? true
              : tags?.length < 3
                ? true
                : false
            : false
        }
        onClick={() =>
          isUserLoggedIn
            ? setActiveDrawer("account-settings-drawer")
            : navigate(ROUTE_NAMES.REGISTER_ROUTE)
        }
      >
        {isUserLoggedIn
          ? "Upgrade for more keywords"
          : "Upgrade to Activate Keywords"}
      </p>
    </div>
  );
};

export default SingleKeywordHighlights;
