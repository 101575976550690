import { Auth } from "aws-amplify";
import moment from "moment";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "src/development/Component/Auth-Modal/utils";
import { updateLoading } from "src/store/Loading";
import { updateAxiosInstance } from "src/store/utility";

type Props = {
  email: string;
  logging?: boolean;
  setLogging?: Dispatch<SetStateAction<boolean>>;
};

const useAuthCommon = ({ email, logging = false, setLogging }: Props) => {
  const { setQueryParams, deleteQueryParam } = useQueryParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAutoLogin = async () => {
    if (!email || email === "") return;
    if (localStorage.getItem("passwordResetInitiated"))
      localStorage.removeItem("passwordResetInitiated");
    dispatch(updateLoading({ key: "dashboardloading", value: true }) as any);
    try {
      if (setLogging) setLogging(true);
      const password = localStorage.getItem("tempPassword"); // Store password temporarily during verification
      if (!password) return;

      const user = await Auth.signIn(email?.toLowerCase(), password);
      const authToken = user.signInUserSession.accessToken.jwtToken;

      updateAxiosInstance(authToken);
      localStorage.setItem(
        "tokenExpirationDate",
        moment().add(1, "days").toISOString()
      );
      // Clear temporary data
      localStorage.removeItem("tempPassword");
      localStorage.removeItem("loginEmail");
      localStorage.removeItem("registerEmail");

      // Navigate to home
      localStorage.setItem("loggedIn", "true");
      deleteQueryParam("authModal");
      deleteQueryParam("step");
      deleteQueryParam("internalStep");
      if (setLogging) setLogging(false);
      navigate("/");
    } catch (error) {
      console.error("Auto login failed:", error);
      if (setLogging) setLogging(false);
    } finally {
      localStorage.removeItem("tempPassword");
      localStorage.removeItem("loginEmail");
      localStorage.removeItem("registerEmail");
      dispatch(updateLoading({ key: "dashboardloading", value: false }) as any);
    }
  };
  return {
    handleAutoLogin,
  };
};

export default useAuthCommon;
