import "./ForgotPassword.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PasswordResetVerification from "../PasswordResetVerification/PasswordResetVerification";
import { ChangePassword } from "../ChangePassword/ChangePassword";
import BottomAction from "../BottomAction/BottomAction";

const ForgotPasswordPage = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("forgotPasswordEmail");
    if (!email && step === 1) {
      navigate("?authModal=login&step=1&internalStep=1");
    }
  }, [step, navigate]);

  return (
    <>
      <div className="forgot-password-container">
        {step === 2 ? (
          <ChangePassword
            onBack={() => {
              localStorage.removeItem("forgotPasswordEmail");
              setStep(1);
            }}
          />
        ) : (
          <PasswordResetVerification setStep={setStep} />
        )}
      </div>
      <BottomAction />
    </>
  );
};

export default ForgotPasswordPage;
