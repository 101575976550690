export const DEFAULT_LAOUT_CONFIG = [
    {
        "type": "NEWS_FEED",

        "i": "cmegroup-app",
        "x": 0,
        "y": 0,
        "w": 2,
        "h": 9
    },
    {
        "type": "NEWS_FEED",

        "i": "tickmill",
        "x": 3,
        "y": 3,
        "w": 1,
        "h": 8
    },
    {
        "type": "NEWS_FEED",

        "i": "bloomberg",
        "x": 3,
        "y": 0,
        "w": 2,
        "h": 3
    },
    {
        "type": "NEWS_FEED",

        "i": "cme",
        "x": 4,
        "y": 3,
        "w": 1,
        "h": 7
    },
    {
        "type": "NEWS_FEED",

        "i": "newsquawk-delay",
        "x": 2,
        "y": 0,
        "w": 1,
        "h": 12
    },
    {
        "type": "NEWS_FEED",

        "i": "forexheatmap",
        "x": 5,
        "y": 0,
        "w": 2,
        "h": 3
    },
    {
        "type": "NEWS_FEED",

        "i": "piqfeeds",
        "x": 5,
        "y": 3,
        "w": 2,
        "h": 7
    },
    {
        "type": "NEWS_FEED",

        "i": "all-news",
        "x": 7,
        "y": 0,
        "w": 1,
        "h": 5
    },
    {
        "type": "NEWS_FEED",

        "i": "financialjuiceeconomiccalendar",
        "x": 0,
        "y": 9,
        "w": 2,
        "h": 4
    }
];
